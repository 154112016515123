import React from 'react';
import './SpecialityButtonsBar.css';
import Specialities from '../../../data/Specialities';
import SubFilterButton from './SubFilterButton/SubFilterButton';
import { useMainFilter, useOffersToDisplay, useSearchInputValue } from '../../../data/GlobalStates';
import { useAtom } from 'jotai';

const SpecialityButtonsBar = () => {

    const [ MainFilter ] = useAtom(useMainFilter); 
    const [ OffersToDisplay ] = useAtom(useOffersToDisplay); 
    const [ SearchInputValue, setSearchInputValue ] = useAtom(useSearchInputValue);

      // const countFoundedOffers = () => {
      //     if (OffersToDisplay.length === 0) {
      //       return
      //     } else if (OffersToDisplay.length === 1) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} oferta</p>
      //     } else if (OffersToDisplay.length > 1 && OffersToDisplay.length < 5) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} oferty</p>
      //     } else if (OffersToDisplay.length >= 5 && OffersToDisplay.length < 22) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} ofert</p>
      //     } else if (OffersToDisplay.length >= 22 && OffersToDisplay.length <= 121 && (OffersToDisplay.length % 10 === 2 || OffersToDisplay.length % 10 === 3 || OffersToDisplay.length % 10 === 4)) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} oferty</p>
      //     } else if (OffersToDisplay.length >= 22 && OffersToDisplay.length <= 121 && (OffersToDisplay.length % 10 !== 2 || OffersToDisplay.length % 10 !== 3 || OffersToDisplay.length % 10 !== 4)) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} ofert</p>
      //     } else if (OffersToDisplay.length > 121 && (OffersToDisplay.length % 10 === 2 || OffersToDisplay.length % 10 === 3 || OffersToDisplay.length % 10 === 4)) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} oferty</p>
      //     } else if (OffersToDisplay.length > 121 && (OffersToDisplay.length % 10 !== 2 || OffersToDisplay.length % 10 !== 3 || OffersToDisplay.length % 10 !== 4)) {
      //       return <p id='FoundedOffers'>{OffersToDisplay.length} ofert</p>
      //     }
      // };


    const showSubFilters = () => {
        return (
           <div id='TopBar3MainDiv'>
           {  MainFilter === 'Monter' ?
                <div className='SubFilters'>
                    <p className='subFilterTitle'>Wybierz specjalizację:</p>
                      <div className='subFiltersContainer'>
                        { Specialities.Monter.map(speciality =>
                          <SubFilterButton
                          key={speciality}
                          speciality={speciality}
                          />
                        )
                        }
                      </div>
                </div>
                 : ''
           }
           {
                MainFilter==='Spawacz' ?
                <div className='SubFilters'>
                  <p className='subFilterTitle'>Wybierz specjalizację:</p>
                    <div className='subFiltersContainer'>
                    { Specialities.Spawacz.map(speciality =>
                          <SubFilterButton
                          key={speciality}
                          speciality={speciality}
                          />
                        )
                        }
                    </div>
                </div>
                 : ''
             }
             {
                MainFilter==='CNC' ?
                <div className='SubFilters'>
                    <p className='subFilterTitle'>Wybierz specjalizację:</p>
                      <div className='subFiltersContainer'>
                      { Specialities.OperatorCNC.map(speciality =>
                          <SubFilterButton
                          key={speciality}
                          speciality={speciality}
                          />
                        )
                        }
                      </div>
                </div>
                 : ''
           }
           {
                MainFilter==='Inżynier' ?
                <div className='SubFilters'>
                    <p className='subFilterTitle'>Wybierz specjalizację:</p>
                      <div className='subFiltersContainer'>
                      { Specialities.Inżynier.map(speciality =>
                          <SubFilterButton
                          key={speciality}
                          speciality={speciality}
                          />
                        )
                        }
                      </div>
                </div>
                 : ''
           }
           </div>
        )
       };
    return (
            <div id='TopBar3mainDiv'>
                { !SearchInputValue ? showSubFilters() : ''}
            </div>
    )
}

export default SpecialityButtonsBar;
