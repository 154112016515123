import './OfferDetails.css';
import React, {useState, useEffect} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import dataBase from '../../config';
import { ref as OfferDetailsRef, set, get, child, increment } from "firebase/database";
import OffersDetailsDisplay from '../OfferDetailsDisplay/OffersDetailsDisplay';
import { motion } from "framer-motion";
import { useAtom } from 'jotai';
import { useDownloadedOffers, useDetailedOffer, useDetailed } from '../../data/GlobalStates';

const OfferDetails = ({setRefresh}) => {

  //stany lokalne:
  const [expired, setExpired] = useState('');

  const [offerID, setOfferID] = useState('');
  const [VisitCounted, setVisitCounted] = useState(false);

  //stany globalne:
  const [ DownloadedOffers, setDownloadedOffers ] = useAtom(useDownloadedOffers);
  const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
  const [ detailed, setDetailed ] = useAtom(useDetailed);

  const history = useHistory();
  const { id } = useParams();
  const dbRef = OfferDetailsRef(dataBase);
  const db = dataBase;

  const downloadExpiredOffer = async () => {
    try {
      const offers = await get(child(dbRef, `offers`))
      setExpired(Object.values(offers.val()).filter(offer => offer.id === offerID)[0])
    }
    catch (error) {
      //
    }
  };

useEffect(()=>{
  if (DetailedOffer) {
    setDetailed(DetailedOffer)
  } else if (DownloadedOffers && !DetailedOffer && DownloadedOffers.filter(item => item.id === offerID)[0]) {
     setOfferID(id)
     setDetailed(DownloadedOffers.filter(item => item.id === offerID)[0])
  } else if (DownloadedOffers && !detailed && !DownloadedOffers.filter(offer => offer.id === offerID)[0]) {
    setOfferID(id)
    downloadExpiredOffer()
   }
},[DownloadedOffers, id, DetailedOffer, offerID])

const saveOpenedDetailsStats = async (data) => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  try {
    await get(child(dbRef, `stats/offersDetailsActions/${year}/${month}/detailsClicks`))
    .then((statValue) => {
      if ( statValue.val() === null) {
        set(OfferDetailsRef(db, `stats/offersDetailsActions/${year}/${month}/detailsClicks`), 1)
      } else if (statValue.val()) {
        set(OfferDetailsRef(db, `stats/offersDetailsActions/${year}/${month}/detailsClicks`), increment(1))
      }
    })
   
  } catch (error) {
    console.log(error)
  }
};

useEffect(()=>{

  const visitCounter = async () => {
      if (detailed) {
       await get(child(dbRef, `offers/${detailed.id}/detailsViews`))
       .then((offerInDb) => {
        if (!detailed.detailsViews) {
          set(OfferDetailsRef(db, `offers/${detailed.id}/detailsViews`), 1)
        } else if (detailed.detailsViews) {
          set(OfferDetailsRef(db, `offers/${detailed.id}/detailsViews`), increment(1))
        }
       })
      }
   };

   if (detailed && !VisitCounted) {
    visitCounter()
    saveOpenedDetailsStats()
    setVisitCounted(true) 
   }
   
  },[detailed, DownloadedOffers])

const backToMain = () => {
history.push('/')
};

const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

const showBadUrlInfo = () => {
return(
   <motion.div variants={variants} initial="hidden" animate="visible"  transition={{ duration: 1 }} id='BadUrl'>
      <h2 className='OfferDetailsBadUrlHeaders'>Nie znaleziono oferty:</h2>
      <h2><strong>{offerID}</strong></h2>
      <h2 className='OfferDetailsBadUrlHeaders'>Sprawdź aktualne oferty na:</h2>
       <div>
         <h3 className='OfferDetailsBackLogo' onClick={backToMain}>SteelJobs.pl</h3>
       </div>
   </motion.div>
)
};

const showExpiredInfo = () => {
  if (expired && expired.paid === false && expired.visibility === false) {
    return(
      <div id='BadUrl'>
         <h2 className='OfferDetailsBadUrlHeaders'>Oferta:</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>{expired.title}</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>oczekuje na realizację płatności</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>Sprawdź pozostałe oferty na:</h2>
          <div>
            <h3 className='OfferDetailsBackLogo' onClick={backToMain}>SteelJobs.pl</h3>
          </div>
      </div>
   )
  } else if (expired && (expired.paid === true || expired.paid === '' || expired.paid === undefined) && expired.visibility === false) {
    return(
      <div id='BadUrl'>
         <h2 className='OfferDetailsBadUrlHeaders'>Czas wyświetlania Oferty:</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>{expired.title}</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>został zakończony</h2>
         <h2 className='OfferDetailsBadUrlHeaders'>Sprawdź aktualne oferty na:</h2>
          <div>
            <h3 className='OfferDetailsBackLogo' onClick={backToMain}>SteelJobs.pl</h3>
          </div>
      </div>
   )
  }
};

    return (
        <div id='OfferDetailsMainDiv'>
         { detailed && !expired ? 
         <OffersDetailsDisplay
           get={get}
           db={db}
           child={child}
           dbRef={dbRef}
           set={set}
           OfferDetailsRef={OfferDetailsRef}
           setRefresh={setRefresh}
           /> 
           : 
           <LoadingAnimation/>}
         { !detailed && DownloadedOffers &&  !DownloadedOffers.filter(item => item.id === offerID)[0] && !expired ? showBadUrlInfo() : ''}
         { expired && !detailed ? showExpiredInfo() : ''}
    </div>
    )
}

export default OfferDetails;
