
import './ShowOnLineStatus.css';
import { FiAlertTriangle } from 'react-icons/fi';

const ShowOnlineStatus = ({refresh, setRefresh}) => {

 
    return (
        <div id='ShowOnlineStatusMainContainer'>
          <p>Brak połączenia z internetem</p>
          <FiAlertTriangle className='ConnectionAlertIcon'/>
        </div>
    )
}

export default ShowOnlineStatus;
