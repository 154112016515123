const Prefixes = [
    {country: 'Australia',
      value: '+61'},
      {country: 'Austria',
      value: '+43'},
      {country: 'Belgia',
      value: '+32'},
      {country: 'Brazylia',
      value: '+55'},
      {country: 'Bułgaria',
      value: '+359'},
      {country: 'Kanada',
      value: '+1'},
      {country: 'Chiny',
      value: '+86'},
      {country: 'Chorwacja',
      value: '+385'},
      {country: 'Cypr',
      value: '	+357'},
      {country: 'Czechy',
      value: '	+420'},
      {country: 'Dania',
      value: '+45'},
      {country: 'Estonia',
      value: '+372'},
      {country: 'Finlandia',
      value: '+358'},
      {country: 'Francja',
      value: '+33'},
      {country: 'Niemcy',
      value: '+49'},
      {country: 'Grecja',
      value: '+30'},
      {country: 'Węgry',
      value: '+36'},
      {country: 'Islandia',
      value: '+354'},
      {country: 'Irlandia',
      value: '+353'},
      {country: 'Włochy',
      value: '+39'},
      {country: 'Japonia',
      value: '+81'},
      {country: 'Łotwa',
      value: '+371'},
      {country: 'Litwa',
      value: '+370'},
      {country: 'Holandia',
      value: '+31'},
      {country: 'Norwegia',
      value: '+47'},
      {country: 'Polska',
      value: '+48'},
      {country: 'Portugalia',
      value: '+351'},
      {country: 'Rumunia',
      value: '+40'},
      {country: 'Rosja',
      value: '+7'},
      {country: 'Słowacja',
      value: '+421'},
      {country: 'Hiszpania',
      value: '+34'},
      {country: 'Szwecja',
      value: '+46'},
      {country: 'Szwajcaria',
      value: '+41'},
      {country: 'Turcja',
      value: '+90'},
      {country: 'Ukraina',
      value: '+380'},
      {country: 'Anglia',
      value: '+44'},

]

export default Prefixes;
