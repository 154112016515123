import React from 'react';
import { motion } from "framer-motion";
import { useAtom } from 'jotai';
import { useHistory } from 'react-router-dom';
import { useMainFilter, useSecondFilter } from '../../../../data/GlobalStates';

const SubFilterButton = ({speciality}) => {

   const [ SecondFilter, setSecondFilter ] = useAtom(useSecondFilter);
   const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);

   const history = useHistory();

   const updateSecondFilter = (speciality) => {
      setSecondFilter(`${speciality}`)
      history.push({pathname: `/Filtry/${MainFilter}/${speciality}`}) 
   };
  
        return (
           <motion.div whileTap={{ scale: 1.1 }}>
              {speciality !== 'Wybierz' ?
                 <div className={SecondFilter === speciality ? 'SubFilterIconFilled' : 'SubFilterIcon'} onClick={() => updateSecondFilter(speciality)}>{speciality}</div>
               : ''}
           </motion.div>
          )
}

export default SubFilterButton;