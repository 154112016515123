import React, {useEffect, useState} from 'react';
import './WorkerView.css';
import { getAuth } from "firebase/auth";
import { useAtom } from "jotai";
import { getDownloadURL } from "firebase/storage";
import { ref as sRef } from 'firebase/storage';
import dataBase from '../../../config';
import { storage } from '../../../config';
import { SlUser } from 'react-icons/sl';
import { GiConfirmed } from 'react-icons/gi';
import { BsFillMenuButtonWideFill } from 'react-icons/bs';
import { ImBlocked } from 'react-icons/im';
import WorkerAplication from './WorkerApplication/WorkerAplication';
import { ref as UserPanelRef, child, get} from "firebase/database";
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo} from "../../../data/GlobalStates";
import { User } from '@auth0/auth0-react';


const WorkerView = ({setUserLogo, UserLogo}) => {

  const authedUser = getAuth() //authedUser.currentUser
  const dbRef = UserPanelRef(dataBase);// UCHW DO ODCZYTU
  //stany globalne:
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
  //stany lokalne:
  const [ UserApplications, setUserApplications ] = useState('');


  useEffect(() => { // pobranie Logo

    let isMounted = true;

      if (LoggedUser.UserLogo === true && isMounted) {
        setTimeout(() => {
          getDownloadURL(sRef(storage, `CompaniesLogos/${LoggedUser.name}`))
          .then((url) => {
              setUserLogo(url)
          }).catch(error => {
            if (error.code === 'storage/object-not-found') {
             setUserLogo(null)
              return
            }
          })
        },2000)
    }

    if (!LoggedUser.UserLogo && isMounted) {
      setUserLogo(null)
    }
    return () => {
      isMounted = false;
    };
  }, [LoggedUser.UserLogo, UserLogo])

  const syncUserApplications = async () => {
    await get(child(dbRef, `offers`)).then((offers) => {
      setUserApplications(Object.values(offers.val()).filter(offer => offer.visibility === true ))})
  };
  
 useEffect(() => {
  syncUserApplications()
 },[])

  const OpenChooseDataToEditModal = () => {
    setShowPopUpModal(true)
    setModalVersion(34)
    setModalInfo({title: 'Wybierz dane do edycji:'}) 
  };

  const OpenLogoUploader = () => {
    setShowPopUpModal(true)
    setModalVersion(17)
  };

  const OpenChangeJobSearchStatus = () => {
    setShowPopUpModal(true)
    setModalVersion(32)
  };

  const openNotificationsChangeModal = () => {
    setShowPopUpModal(true)
    setModalVersion(24)
    setModalInfo(
     LoggedUser.Notifications === true ?
     {title: 'Chcesz wyłączyć powiadomienia ?' ,message: 'Portal SteelJobs.pl nie będzie wysyłać informacji o aktualnych promocjach oraz wskazówek dotyczących korzystania z serwisu.'}
     :
     {title: 'Chcesz włączyć powiadomienia ?' ,message: 'Portal SteelJobs.pl będzie wysyłać informację o aktualnych promocjach oraz wskazówkach dotyczących korzystania z serwisu.'}
     )
};

 const displayUserApplications = () => {

  let offersWithActiveApplications = null;
  let applications = []; 
  let userAplications = [];

   if (UserApplications) {
    offersWithActiveApplications = UserApplications.filter(offer => offer.applications)
    for (let i in offersWithActiveApplications) {
      applications.push(Object.values(offersWithActiveApplications[i].applications))
    }
   }
   userAplications = applications.flat().filter(ap => ap.email === authedUser.currentUser.email)
   if (UserApplications && userAplications.length > 0) {
    return (
      userAplications.map(apl => 
        <WorkerAplication
          key={apl.offerTitle}
          apl={apl}
        />
      )
    )
   } else if (UserApplications && userAplications.length == 0) {
    return (
      <div className='NoUsersApplicationsInfoContainer'>
       <p className='NoUsersApplicationText'>Nie aplikujesz na żadne stanowisko</p>
      </div>
    )
   }
 };

 

  return (
    <div id='LoggedUserMainDiv'>
    <div id='UserProfileInfoDiv'>
           <div id='CompanyNameInfo'>
              <p id='companyName'>Nazwa użytkownika:</p>
              <h2 id='displayedCompanyName'>{LoggedUser.name}</h2>
           </div>
               <div id='EmailInfo'>
                  <p id='emailTitle'>e-mail:</p>
                  <p id='emailValue'>{LoggedUser.email}</p>
               </div>
                 {UserLogo ? <img src={UserLogo} className='UserLogoUserPanel'></img> : <SlUser className='WorkerLogoUserPanel' onClick={OpenLogoUploader}/>}
                      <div className='EditBtns' onClick={OpenChooseDataToEditModal}>
                        <p>Edytuj Dane</p>
                        <BsFillMenuButtonWideFill className='EditUserDataIcon'/>
                      </div>
    </div>
    <div id='UserDataDiv'>
      <div className='TitleAndValueContainer'>
        <h3 className='Titles'>Aktualnie szukam pracy:</h3>
          <div className='SearchForJobStatusButtons'>
            {/* LoggedUser.SearchingForJob */}
            <button className={LoggedUser.SearchingForJob === undefined || LoggedUser.SearchingForJob === false ? 'JobStatusButton' : 'JobStatusButtonActive'} onClick={OpenChangeJobSearchStatus}>Tak</button>
            <button className={LoggedUser.SearchingForJob === undefined || LoggedUser.SearchingForJob === false ? 'JobStatusButtonActive' : 'JobStatusButton'} onClick={OpenChangeJobSearchStatus}>Nie</button>
          </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
        <h3 className='Titles'>Nr. kontaktowy: </h3>
        <p id='phoneValue' className='AdressFields'>{LoggedUser.PhoneNumber}</p>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Kraj:</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.Country ? LoggedUser.Country : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Miejscowość: {LoggedUser.Adress}</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.City ? LoggedUser.City : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Zawód:</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.Role ? LoggedUser.Role : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Specjalizacja:</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.WorkerSpeciality ? LoggedUser.WorkerSpeciality : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Doświadczenie w latach:</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.ExperienceInYears ? LoggedUser.ExperienceInYears : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Poziom Doświadczenia:{LoggedUser.Adress}</h3>
         <div id='AdressValues'>
           <p className='AdressFields'>{LoggedUser.Experience ? LoggedUser.Experience : <span className='AdressFieldsPlaceHolder'>...</span>}</p>
         </div>
      </div>
      </div>
       <div id='NotificationsButtonMainContainer'>
         <p className='NotificationsSwitch' onClick={() => openNotificationsChangeModal()}>Powiadomienia</p>
         {LoggedUser.Notifications === true ? <GiConfirmed className='UserNotificationsAcceptLogo'/> : <ImBlocked className='UserNotificationsDeniedLogo'/>}
       </div>
       <div className='WorkerAplicationsContainer'>
         <h3 className='Titles'>Twoje Aplikacje:</h3>
         {displayUserApplications()}
      </div>
  </div>
  )
}

export default WorkerView;