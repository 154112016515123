import React from 'react';
import './LoginBar.css';
import { Link, useHistory } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { ImExit } from 'react-icons/im';
import { useLoggedUser, useSecondFilter, useCountryFilter, useMainFilter, useCityFilter, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../data/GlobalStates';
import { useAtom } from 'jotai';

const TopBar = () => {

   const authedUser = getAuth();
   const history = useHistory();

   //stany globalne:
   const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
   const [ SecondFilter, setSecondFilter ] = useAtom(useSecondFilter);
   const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
   const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);
   const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
   const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
   const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
   const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

   const logOut = () => {
     signOut(authedUser)
       .then(() => {
          setLoggedUser('')
          history.push('/')
          setShowPopUpModal(true)
          setModalVersion(12)
          setModalInfo({title: 'Wylogowano poprawnie'})
       })
   };

   const cleanFilters = () => {
    setCountryFilter('')
    setCityFilter('')
    setMainFilter('')
    setSecondFilter('')
   };

   const addOfferButton = () => {
      if ( !authedUser.currentUser && !LoggedUser ) {
        return (
          <div className='MainButtonsContainer'>
            <Link to='/Cennik' className='MainButtons'>
              <p>Dodaj Ofertę</p>
            </Link>
         </div>
        )
      } else if (authedUser.currentUser && LoggedUser.AccountType !== 'Private') {
        return (
          <div className='MainButtonsContainer'>
            <Link to='/DodajOgłoszenie' className='MainButtons'>
              <p id='loggedUserAddButtonText'>Dodaj Ofertę</p>
              <AiOutlinePlusCircle className='loggedUserAddButtonIcon'/>
            </Link>
          </div>
        )
      } else if (authedUser.currentUser && LoggedUser.AccountType === 'Private') {
        return null
      }
   };

    return (
      <div id='TopBarMainDiv'>
            <div className='LogoAndButtons'>
              <div className='LogoAndSubLogo' onClick={() => cleanFilters()}>
                 <Link to='/' className='MainLogoText'>
                    SteelJobs.pl
                 </Link>
                <p id='SubLogoText'>Oferty pracy z branży metalurgicznej</p>
              </div>
            {addOfferButton()}
            {
                authedUser.currentUser && LoggedUser ?
                <div className='MainButtonsContainer'>
                   <Link to='/userPanel' className='MainButtons'>
                     <p id='loggedUserAddButtonText'>Konto</p>
                     <BsFillPersonFill className='loggedUserAddButtonIcon'/>
                   </Link>
                   </div> : ''
              }
              {
                    !authedUser.currentUser && !LoggedUser ?
                      <Link to='/login' className='MainButtons'>
                       Logowanie
                      </Link>
                    :
                     <div className='MainButtons' onClick={()=>{logOut()}}>
                        <p className='ButtonText'>Wyloguj</p>
                        <ImExit className='loggedUserAddButtonIcon'/>
                     </div>
                 }
               </div>
            </div>
  )
}

export default TopBar;
