import React from 'react';
import { CgClose } from 'react-icons/cg';
import { useAtom } from 'jotai';
import { useCityFilter } from '../../../../data/GlobalStates';

const FilteredCityDisplayCard = () => {

  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);

  const RemoveCityItemFromFilter = () => {
    setCityFilter('')
  };

  return (
    <div className='ChoosedCountryNameContainer' onClick={() => RemoveCityItemFromFilter()}>
        <p className='ChoosedCountryName'>{CityFilter}</p>
        <CgClose className='ChoosedCountryCloseBtn'/>
    </div>
  )
}

export default FilteredCityDisplayCard;