import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { ref as AppRef, child, get } from "firebase/database";
import { getAuth } from "firebase/auth";
import './App.css';
import dataBase from './config';
import CookiesInfo from './Components/CookiesInfo/CookiesInfo';
import Politics from './Components/Politics/Politics';
import Statute from './Components/Statute/Statute';
import LoginBar from './Components/Header/LoginBar/LoginBar';
import RoleButtonsBar from './Components/Header/RoleButtonsBar/RoleButtonsBar';
import CookiesPage from './Components/CookiesPage/CookiesPage';
import CompaniesDisplay from './Components/CompaniesDisplay/CompaniesDisplay'
import CompaniesSearchBar from './Components/CompaniesSearchBar/CompaniesSearchBar'
import LoginPage from './Components/LoginPage/LoginPage';
import OfferDetails from './Components/OfferDetails/OfferDetails';
import OffersDisplay from './Components/OffersDisplay/OffersDisplay';
import UserPanel from './Components/UserPanel/UserPanel';
import AddOfferInfo from './Components/AddOfferInfo/AddOfferInfo';
import AddOffer from './Components/AddOffer/AddOffer';
import PaymentInfo from './Components/PaymentInfo/PaymentInfo';
import Footer from './Components/Footer/Footer';
import UserSyncWithFireBase from './Components/UserSyncWithFireBase/UserSyncWithFireBase';
import PopUpModal from './Components/PopUpModal/PopUpModal';
import Contact from './Components/Contact/Contact';
import OfferTypes from './data/OfferTypes';
import Stats from './Components/Stats/Stats';
import ShowEditFields from './Components/AddOffer/EditFields/ShowEditFields';
import ChooseOfferToEdit from './Components/AddOffer/ChooseOfferToEdit/ChooseOfferToEdit';
import useFilterOffersToDisplay from './customHooks/useFilterOffersToDisplay/useFilterOffersToDisplay';
import ComercialsInfo from './Components/ComercialsInfo/ComercialsInfo';
import LoadingAnimation from './Components/LoadingAnimation/LoadingAnimation';
import ShowOnlineStatus from './Components/ShowOnLineStatus/ShowOnlineStatus';
import RegisterInfo from './Components/RegisterInfo/RegisterInfo';
import ReactGA from 'react-ga4';
import { useAtom } from 'jotai';
import { useMainFilter, useLoggedUser, useSecondFilter, useDownloadedOffers, useOffersToDisplay, useSearchInputValue,
         useCountryFilter, useRefreshUserInfo, useCityFilter, useSalaryFilter, useShowPopUpModal, useModalVersion, useModalInfo,
         useAllCompanies, useExpiredOffers, useExperienceFilter, useCurrencyFilter, useApplyWithSJProfile, useExternalApplyUrl } from './data/GlobalStates';
import PaymentInfo2 from './Components/PaymentInfo/PaymentInfo2';

const App = () => {

const [ refresh, setRefresh] = useState(99)
const [ displayCookiesInfo, setDisplayCookiesInfo] = useState(false)
const [ countriesChoose, setCountriesChoose] = useState(false)
const [ countryFilterCode, setCountryFilterCode] = useState('')
const [ cityLat, setCityLat] = useState('')
const [ cityLng, setCityLng] = useState('')
const [ AddOrderPictureUrl, setAddOrderPictureUrl ] = useState('')
const [ AddOrderPictureFile, setAddOrderPictureFile ] = useState('')
const [ awaitOffer, setAwaitOffer ] = useState('')
const [ Experience, setExperience ] = useState('Wybierz')
const [ SelectedRole, setSelectedRole ] = useState('Wybierz')
const [ SelectedSpeciality, setSelectedSpeciality ] = useState('Wybierz')
const [ Country, setCountry ] = useState('Wybierz')
const [ Title, setTitle ] = useState('')
const [ Description, setDescription ] = useState('')
const [ MinSalary, setMinSalary ] = useState('')
const [ MaxSalary, setMaxSalary ] = useState('')
const [ SalaryType, setSalaryType ] = useState('')
const [ MaxSalaryWarn, setMaxSalaryWarn ] = useState(false)
const [ Currency, setCurrency ] = useState('PLN')
const [ checkUser, setCheckUser ] = useState(1)
const [ UserLogo, setUserLogo] = useState('')
const [ popularCountries, setPopularCountries ] = useState(0)
const [ popularCities, setPopularCities ] = useState(0)
const [ isOnline, setIsOnline] = useState(navigator.onLine)

  //stany globalne:
  const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);
  const [ SecondFilter, setSecondFilter ] = useAtom(useSecondFilter);
  const [ ExperienceFilter, setExperienceFilter ] = useAtom(useExperienceFilter);
  const [ CurrencyFilter, setCurrencyFilter ] = useAtom(useCurrencyFilter);
  const [ SalaryFilter, setSalaryFilter] = useAtom(useSalaryFilter);
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ DownloadedOffers, setDownloadedOffers ] = useAtom(useDownloadedOffers);
  const [ OffersToDisplay, setOffersToDisplay ] = useAtom(useOffersToDisplay);
  const [ SearchInputValue, setSearchInputValue ] = useAtom(useSearchInputValue);
  const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
  const [ RefreshUserInfo, setRefreshUserInfo] = useAtom(useRefreshUserInfo);
  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
  const [ AllCompanies, setAllCompanies] = useAtom(useAllCompanies);
  const [ ExpiredOffers, setExpiredOffers] = useAtom(useExpiredOffers);
  const [ ApplyWithSJProfile, setApplyWithSJProfile] = useAtom(useApplyWithSJProfile);
  const [ ExternalApplyUrl, setExternalApplyUrl] = useAtom(useExternalApplyUrl);

  
  const authedUser = getAuth(); 
  const history = useHistory();
  const filteredOffers = useFilterOffersToDisplay(DownloadedOffers, MainFilter, SecondFilter, CountryFilter, ExperienceFilter, SalaryFilter, SearchInputValue, CityFilter, CurrencyFilter)
  const dbRef = AppRef(dataBase); //odczyt
  const trackingID = process.env.REACT_APP_TRACKINGID;
  ReactGA.initialize(trackingID);

  useEffect(() => {
    ReactGA.initialize(trackingID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: window.location.pathname});
  }, [MainFilter,SecondFilter,ExperienceFilter,refresh, awaitOffer]);


useEffect(() => {
  let isMounted = true
  if (displayCookiesInfo === false) {
    setTimeout(() => {
      if (isMounted) {
        setDisplayCookiesInfo(true)
      }
    }, Math.floor(Math.random() * 30000) + 1000)
  }
  return () => {
    isMounted = false;
  };
}, [])

const DownloadCompanies = async () => {
  try {
      await get(child(dbRef, `users`)).then((companies) => {
        setAllCompanies(Object.values(companies.val()).filter(company => company.AccountType !== 'Private'))
      })
  }
  catch (error) {
    console.log(error)
  }
};

const DownloadPopularCountries = async () => {
  try {
     await get(child(dbRef, `popularCountries`))
       .then((popularCountries) => {
         setPopularCountries(Object.entries(popularCountries.val()).map((el) => {
          return {name: el[0], searches: el[1].searches}
        }))
       })
  }
  catch (error) {
    console.log(error)
  }
};

const DownloadPopularCities = async () => {
  try {
     await get(child(dbRef, `popularCities`))
       .then((popularCities) => {
         setPopularCities(Object.entries(popularCities.val()).map((el) => {
          return {name: el[0], searches: el[1].searches}
        }))
       })
  }
  catch (error) {
    console.log(error)
  }
};

useEffect(() => { //pobranie i aktualizacja ofert

  const ActualDate = Date.now();

  const validationCheck = (offer) => {
    const day =1000*60*60*24;
    const difference_ms = ActualDate - offer.addDate;
    if (offer.option === 'basic' && Math.round(difference_ms/day) < OfferTypes[0].displayTime + 1) { // czas wyświetlania oferty podstawowej = 15 dni
      return true
    } else if (offer.option === 'basic+' && Math.round(difference_ms/day) < OfferTypes[1].displayTime + 1) {
      return true
    } else if (offer.option === 'premium' && Math.round(difference_ms/day) < OfferTypes[2].displayTime + 1) {
      return true
    } else return false
    };

  const UpdateDownloadedOffers = async () => { // update downloadedOffers wywoływany zmianą któregoś z filtrów
    try {
       await get(child(dbRef, `offers`)).then((offers) => {
           setExpiredOffers(Object.values(offers.val()).filter(offer => offer.companyName === LoggedUser.name && offer.visibility === false))
           setDownloadedOffers(Object.values(offers.val()).filter(offer => offer.visibility === true && validationCheck(offer) === true).sort((a, b) => (b.addDate - a.addDate)))
           DownloadCompanies()
           DownloadPopularCountries()
           DownloadPopularCities()
     })
    }
    catch (error) {
      console.log(error, 'offers not downloaded')
      setRefresh(refresh + 1) // trigger dla ponownej próby pobania danych 
    }
  };
  if (!DownloadedOffers.length) {
    UpdateDownloadedOffers()
  }
}, [refresh])

useEffect(() => {
  UserSyncWithFireBase(authedUser, setModalInfo, setModalVersion, setShowPopUpModal, setLoggedUser)
}, [RefreshUserInfo, authedUser.currentUser])

useEffect(() => { // Uzupełnienie stanów do edycji ogłoszenia
if (awaitOffer) {
  setTitle(awaitOffer.title)
  setSelectedRole(awaitOffer.role)
  setSelectedSpeciality(awaitOffer.speciality)
  setExperience(awaitOffer.experience)
  setMinSalary(awaitOffer.minSalary || '')
  setMaxSalary(awaitOffer.maxSalary || '')
  setCurrency(awaitOffer.currency || 'PLN')
  setSalaryType(awaitOffer.salaryType || '')
  setDescription(awaitOffer.description)
  setCountry(awaitOffer.country)
  setCityLat(awaitOffer.cityLatit || null)
  setCityLng(awaitOffer.cityLong || null)
  setCityFilter(awaitOffer.city || '')
  setApplyWithSJProfile(awaitOffer.applyWithSJProfile || '')
  setExternalApplyUrl(awaitOffer.externalApplyLink || '')
}

}, [awaitOffer])

useEffect(() => { //filtrowanie ofert pracy wg. kraju, zawodu, specjalności etc.

  if (DownloadedOffers.length) {
      setOffersToDisplay(filteredOffers)
  }

}, [MainFilter, SecondFilter, CountryFilter, SalaryFilter, ExperienceFilter, CurrencyFilter, SearchInputValue, CityFilter, DownloadedOffers.length]);

useEffect(() => {
  const handleStatusChange = () => {
    setIsOnline(navigator.onLine);
  };
  window.addEventListener('online', handleStatusChange);
  window.addEventListener('offline', handleStatusChange);
  return () => {
    window.removeEventListener('online', handleStatusChange);
    window.removeEventListener('offline', handleStatusChange);
  };
}, [isOnline]);

  return (
  <div id='App'>
     <Helmet>
        <title>{'Oferty Pracy z Branży Metalurgicznej'}</title>
        <meta name="description" content="praca spawacz, praca monter, stoczniowcy, spawalnictwo" />
        <meta name='Keywords' content='monter, spawacz, ślusarz, praca, spawanie, tig, mag'/>
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/chat-app-9fa6b.appspot.com/o/SiteLogos%2FMiniLogo.png?alt=media&token=94564b1d-4339-4a63-86a5-1410ea8adf73"/>
        <meta property="og:url" content='https://SteelJobs.pl'/>
     </Helmet>
     { isOnline ? '' : <ShowOnlineStatus/>}
    <Router history={history}>
      <Route exact path='/'>
        {!OffersToDisplay ? <LoadingAnimation/> : 
         <>
         <CookiesInfo displayCookiesInfo={displayCookiesInfo} setDisplayCookiesInfo={setDisplayCookiesInfo}/>
          <LoginBar/>
             <RoleButtonsBar
               countriesChoose={countriesChoose}
               setCountriesChoose={setCountriesChoose}
               setCountryFilter={setCountryFilter}
               />
             <OffersDisplay
               refresh={refresh}
               setRefresh={setRefresh}
               displayCookiesInfo={displayCookiesInfo}
               />
         <Footer/>
         </>
        }
      </Route>
      <Route exact path='/Filtry/:MainFilterValue'>
         {!OffersToDisplay ? <LoadingAnimation/> : 
        <>
        <CookiesInfo displayCookiesInfo={displayCookiesInfo} setDisplayCookiesInfo={setDisplayCookiesInfo}/>
         <LoginBar/>
         <RoleButtonsBar
          countriesChoose={countriesChoose}
          setCountriesChoose={setCountriesChoose}
          setCountryFilter={setCountryFilter}
          />
         <OffersDisplay
          refresh={refresh}
          setRefresh={setRefresh}
          displayCookiesInfo={displayCookiesInfo}
          />
 <Footer/>
 </>
}
</Route>
<Route exact path='/Filtry/:MainFilterValue/:SecondFilterValue'>
         {!OffersToDisplay ? <LoadingAnimation/> : 
        <>
        <CookiesInfo displayCookiesInfo={displayCookiesInfo} setDisplayCookiesInfo={setDisplayCookiesInfo}/>
         <LoginBar/>
         <RoleButtonsBar
          countriesChoose={countriesChoose}
          setCountriesChoose={setCountriesChoose}
          setCountryFilter={setCountryFilter}
          />
         <OffersDisplay
          refresh={refresh}
          setRefresh={setRefresh}
          displayCookiesInfo={displayCookiesInfo}
          />
 <Footer/>
 </>
}
</Route>
      <Route exact path='/login'>
          <LoginPage authedUser={authedUser}/>
      </Route>
      <Route path='/Oferty/:id/:title'>
          <OfferDetails
            setRefresh={setRefresh}
            />
      </Route>
      <Route exact path='/userPanel'>
        <LoginBar/>
            <UserPanel
              UserLogo={UserLogo}
              setUserLogo={setUserLogo}
              refresh={refresh}
              setRefresh={setRefresh}
              awaitOffer={awaitOffer}
              setAwaitOffer={setAwaitOffer}
              setTitle={setTitle}
              setExperience={setExperience}
              setSelectedRole={setSelectedRole}
              setSelectedSpeciality={setSelectedSpeciality}
              setCountry={setCountry}
              setDescription={setDescription}
              setMinSalary={setMinSalary}
              setMaxSalary={setMaxSalary}
              setCountryFilterCode={setCountryFilterCode}
            />
          <Footer/>
      </Route>
      <Route exact path='/BazaFirm'>
        <CookiesInfo displayCookiesInfo={displayCookiesInfo} setDisplayCookiesInfo={setDisplayCookiesInfo}/>
          <LoginBar/>
               <CompaniesSearchBar/>
                  <CompaniesDisplay
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
         <Footer/>
      </Route>
      <Route exact path='/BazaFirm/:companyName'>
        <CookiesInfo displayCookiesInfo={displayCookiesInfo} setDisplayCookiesInfo={setDisplayCookiesInfo}/>
          <LoginBar/>
               <CompaniesSearchBar/>
                  <CompaniesDisplay
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
         <Footer/>
      </Route>
      <Route exact path='/DodajOgłoszenie'>
        <LoginBar/>
          <AddOffer
            setRefresh={setRefresh}
            refresh={refresh}
            awaitOffer={awaitOffer}
            setAwaitOffer={setAwaitOffer}
            checkUser={checkUser}
            setCheckUser={setCheckUser}
            SelectedRole={SelectedRole}
            setSelectedRole={setSelectedRole}
            Title={Title}
            setSelectedSpeciality={setSelectedSpeciality}
            setExperience={setExperience}
            setMinSalary={setMinSalary}
            setMaxSalary={setMaxSalary}
            setTitle={setTitle}
            Description={Description}
            setDescription={setDescription}
            setCountry={setCountry}
            setCurrency={setCurrency}
            SelectedSpeciality={SelectedSpeciality}
            setSalaryType={setSalaryType}
            Experience={Experience}
            MinSalary={MinSalary}
            MaxSalary={MaxSalary}
            MaxSalaryWarn={MaxSalaryWarn}
            Currency={Currency}
            SalaryType={SalaryType}
            Country={Country}
            countryFilterCode={countryFilterCode}
            setCountryFilterCode={setCountryFilterCode}
            cityLat={cityLat}
            cityLng={cityLng}
            setCityLat={setCityLat}
            setCityLng={setCityLng}
        />
      </Route>
      <Route exact path='/Cennik'>
         <AddOfferInfo/>
      </Route>
      <Route exact path='/Podsumowanie'>
        <PaymentInfo refresh={refresh} setRefresh={setRefresh} />
      </Route>
      <Route exact path='/Podsumowanie2'>
        <PaymentInfo2 refresh={refresh} setRefresh={setRefresh} />
      </Route>
      <Route exact path='/Cookies'>
        <CookiesPage />
      </Route>
      <Route exact path='/Polityka'>
        <Politics />
      </Route>
      <Route exact path='/Regulamin'>
        <Statute />
      </Route>
      <Route exact path='/Kontakt'>
        <Contact/>
      </Route>
      <Route exact path='/EdycjaOgloszenia'>
        {awaitOffer ?
          <>
           <LoginBar/>
           <ShowEditFields
               awaitOffer={awaitOffer}
               SelectedRole={SelectedRole}
               setSelectedRole={setSelectedRole}
               Title={Title}
               refresh={refresh}
               setSelectedSpeciality={setSelectedSpeciality}
               setExperience={setExperience}
               setMinSalary={setMinSalary}
               setMaxSalary={setMaxSalary}
               setTitle={setTitle}
               Description={Description}
               setDescription={setDescription}
               setCountry={setCountry}
               setCurrency={setCurrency}
               SelectedSpeciality={SelectedSpeciality}
               setSalaryType={setSalaryType}
               Experience={Experience}
               MinSalary={MinSalary}
               MaxSalaryWarn={MaxSalaryWarn}
               MaxSalary={MaxSalary}
               Currency={Currency}
               SalaryType={SalaryType}
               Country={Country}
               setAwaitOffer={setAwaitOffer}
               setRefresh={setRefresh}
               countryFilterCode={countryFilterCode}
               setCountryFilter={setCountryFilter}
               setCountryFilterCode={setCountryFilterCode}
               cityLat={cityLat}
               cityLng={cityLng}
               setCityLat={setCityLat}
               setCityLng={setCityLng}
                 />
        </>
        : <ChooseOfferToEdit />}
      </Route>
      <Route exact path='/stats'>
        <Stats/>
      </Route>
      <Route exact path='/Reklama'>
        <LoginBar/>
        <ComercialsInfo />
      </Route>
      <Route exact path='/ZarejestrujSie'>
        <LoginBar/>
        <RegisterInfo />
      </Route>
          { ShowPopUpModal ?
              <PopUpModal
                UserLogo={UserLogo}
                setUserLogo={setUserLogo}
                awaitOffer={awaitOffer}
                authedUser={authedUser}
                refresh={refresh}
                setRefresh={setRefresh}
                setCountryFilter={setCountryFilter}
                setAddOrderPictureUrl={setAddOrderPictureUrl}
                AddOrderPictureFile={AddOrderPictureFile}
                setAddOrderPictureFile={setAddOrderPictureFile}
                setAwaitOffer={setAwaitOffer}
                setTitle={setTitle}
                setExperience={setExperience}
                setSelectedRole={setSelectedRole}
                setSelectedSpeciality={setSelectedSpeciality}
                setCountry={setCountry}
                setDescription={setDescription}
                setMinSalary={setMinSalary}
                setMaxSalary={setMaxSalary}
                countryFilterCode={countryFilterCode}
                setCountryFilterCode={setCountryFilterCode}
                popularCountries={popularCountries}
                popularCities={popularCities}
                AddOrderPictureUrl={AddOrderPictureUrl}
              /> : ''
            }
     </Router>
  </div>
  );
}

export default App;
