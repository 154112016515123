import React from 'react';
import './ConfirmationModal.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const ConfirmationModal = () => { 

    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    return (
     <div id='ConfirmationModalMainContainer'>
         <h2 className='ModalTitle'>{ModalInfo.title}</h2>
           <h3 className='ModalMessage'>{ModalInfo.message}</h3>
         <div id='ConfirmBtn' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>
           <h3 id='ConfirmBtnText'>OK</h3>
         </div>
     </div>
    )
};

export default ConfirmationModal;
