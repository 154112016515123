import React, { useState } from 'react';
import './DisplayOfferAplications.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { IoIosClose } from 'react-icons/io';
import { useAtom } from 'jotai';
import AplicationDetails from './AplicationDetails';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo, useOfferApplications } from '../../../../data/GlobalStates';

const DisplayOfferAplications = ({set, PopUpModalRef, db}) => { 

    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ OfferApplications, setOfferApplications] = useAtom(useOfferApplications);

    return (
     <div id='ApplicationsModalMainContainer'>
           <div className='chooseOrCloseContainer'>
            <IoIosClose className='ClosePhoneOrSMSContainer' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}/>
           </div>
           <h3 className='ModalMessage'>Aplikacje pracowników z portalu</h3>
           <h3 className='ModalMessage'>SteelJobs.pl</h3>
           <div id='ApplicationsModalDisplayContainer'>
            {OfferApplications.map(p => (
            <AplicationDetails key={p.name} application={p} set={set} PopUpModalRef={PopUpModalRef} db={db}/>
             ))}
           </div>
     </div>
    )
}

export default DisplayOfferAplications;
