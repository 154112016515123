
 import React, {useState} from 'react';
 import { Overlay } from "googlemaps-react-primitives";
 import { useHistory } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
 import './MultipleOffersMarker.css';
 import MarkerSingleOffer from './MarkerSingleOffer/MarkerSingleOffer';
 import { useAtom } from 'jotai';
 import { useDetailedOffer } from '../../../data/GlobalStates';

 const MultipleOffersMarker = ({position, offer, offersFromSameCity, setMapCenterLat, setMapCenterLong}) => {

  const history = useHistory();
  const [ displayOffersNumber, setDisplayOffersNumber] = useState(true);

  //stany globalne:
  const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);

  let offersFromSameCityNum = offersFromSameCity.filter(i => i.city === offer.city).length

  const openOfferDetails = (offer) => {
   setDetailedOffer(offer)
   history.push({
    pathname: `/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`,
    from: 'Map',
   // state: company.name
  })
  };

  const switchDisplayType = () => {
    setDisplayOffersNumber(!displayOffersNumber)
    setMapCenterLat(position.lat - 7)
    setMapCenterLong(position.lng + 5)
  };

  const displayCityOffersNumber = () => {
    return (
     <Overlay position={{ lat: position.lat, lng: position.lng }}>
      <div className='MultipleMarkerMainContainer' onClick={() => switchDisplayType()}>
        <span className="MultipleOffersCityTip">
          <p className='MultipleMarkerToolTipHeader'>{offer.city}</p>
           {offersFromSameCityNum + ' ' + 'Oferty'}
        </span>
        <p className='MultipleMarkerMainContainerValue'>{offersFromSameCityNum}</p>
      </div>
     </Overlay>
)
  };

  const displayCityOffers = () => {
    return (
        <Overlay position={{ lat: position.lat, lng: position.lng }}>
         <div className='MultipleMarkerOffersContainer'>
            <div className='MultipleMarkerIconContainer'>
              <IoMdClose className='MultipleMarkerCloseIcon' onClick={() => switchDisplayType()}/>
            </div>
            <div className='MultipleMarkerOffersDisplayContainer'>
              {offersFromSameCity.filter(z => z.city === offer.city).map(offer =>
               <MarkerSingleOffer
                 key={offer.id}
                 offer={offer}
                 openOfferDetails={openOfferDetails}
            />
           )}
            </div>
         </div>
        </Overlay>
   )
  }

  return (
    <div>
        {displayOffersNumber ? displayCityOffersNumber() : displayCityOffers()}
    </div>
  )
 };

 export default MultipleOffersMarker;




