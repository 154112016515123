import React, {useEffect, useRef, useState} from 'react';
import './OffersDisplay.css';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { BsWrench } from 'react-icons/bs';
import SingleOffer from '../SingleOffer/SingleOffer';
import { Wrapper } from "@googlemaps/react-wrapper";
import { GoogleMap } from "googlemaps-react-primitives";
import { SiOpenstreetmap } from 'react-icons/si';
import Marker from '../Map/Marker/Marker';
import MultipleOffersMarker from '../Map/MultipleOffersMarker/MultipleOffersMarker';
import { motion } from "framer-motion";
import { useMainFilter, useSecondFilter, useDownloadedOffers, useOffersToDisplay, useSearchInputValue, useDetailedOffer, useOpenedOfferIndex, useMobileMapView, useExperienceFilter, useCountryFilter } from '../../data/GlobalStates';
import { useAtom } from 'jotai';


const OffersDisplay = ({displayCookiesInfo, setRefresh}) => {

  const scrolledOffer = useRef(null);

  //stany globalne:
  const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);
  const [ SecondFilter, setSecondFilter ] = useAtom(useSecondFilter);
  const [ ExperienceFilter, setExperienceFilter ] = useAtom(useExperienceFilter);
  const [ DownloadedOffers, setDownloadedOffers ] = useAtom(useDownloadedOffers);
  const [ OffersToDisplay, setOffersToDisplay ] = useAtom(useOffersToDisplay);
  const [ SearchInputValue, setSearchInputValue ] = useAtom(useSearchInputValue);
  const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);
  const [ OpenedOfferIndex, setOpenedOfferIndex] = useAtom(useOpenedOfferIndex);
  const [ MobileMapView, setMobileMapView] = useAtom(useMobileMapView);
  const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);


  //stany lokalne:
  const [ mapCenterLong, setMapCenterLong ] = useState(21)
  const [ mapCenterLat, setMapCenterLat] = useState(50)

  const offersFromSameCity = [];

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const render = (status) => {
    if (status !== status.success) {
      return <LoadingAnimation />
    }
  };

    useEffect(() => {
      const handleScroll = () => { 

       const element = document.querySelectorAll('p.SingleOfferTitle')[OpenedOfferIndex];

        if (element && OpenedOfferIndex) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };
      handleScroll()
  }, [DetailedOffer]);

 
  const ShowLoadingOrEmptyDataFilter = () => { 
    if (!DownloadedOffers.length) {
      return (
          <div>
            <LoadingAnimation />
          </div>
      )
    } else if (!OffersToDisplay.length && !MainFilter && SearchInputValue) {
      return (
        <div className='NoOffersInfoMessageContainer'>
         <h1 id='NoOffersInfoHeader'>Nie znaleziono ofert wg. wyszukiwanych słów:</h1>
         {SearchInputValue.length < 33 ? <h1 id='NoOffersInfo'>{SearchInputValue}</h1> : <h1 id='NoOffersInfo'>{`${SearchInputValue.slice(0,32) + '...'}`}</h1>}
         <BsWrench className='NoOffersInfoAnimation'/>
        </div>
      )
    } else if (!OffersToDisplay.length && MainFilter && !SecondFilter) { 
     return (
      <div className='NoOffersInfoMessageContainer'>
           <h1 id='NoOffersInfoHeader'>Brak ofert w kategorii:</h1> 
           <h1 id='NoOffersInfo'>{MainFilter}</h1>
           {ExperienceFilter ? <h1 id='NoOffersInfo'>{ExperienceFilter}</h1> : ''}
           <BsWrench className='NoOffersInfoAnimation'/>
           {CountryFilter? <h1 id='NoOffersInfo'>{CountryFilter}</h1> : ''}
      </div>
      )
    } else if (!OffersToDisplay.length && MainFilter && SecondFilter) {
      return (
        <div className='NoOffersInfoMessageContainer'>
             <h1 id='NoOffersInfoHeader'>Brak ofert w kategorii:</h1>
             <h1 id='NoOffersInfo'>{MainFilter}</h1>
             <h1 id='NoOffersInfo'>{SecondFilter}</h1>
             <BsWrench className='NoOffersInfoAnimation'/>
             {CountryFilter? <h1 id='NoOffersInfo'>{CountryFilter}</h1> : ''}
        </div>
        )
    } else if (!OffersToDisplay.length && !MainFilter && !SecondFilter && ExperienceFilter) {
      return (
        <div className='NoOffersInfoMessageContainer'>
             <h1 id='NoOffersInfoHeader'>Brak ofert w kategorii:</h1>
              {ExperienceFilter ? <h1 id='NoOffersInfo'>{ExperienceFilter}</h1> : ''}
              <BsWrench className='NoOffersInfoAnimation'/>
              {CountryFilter? <h1 id='NoOffersInfo'>{CountryFilter}</h1> : ''}
        </div>
        )
    }
   };

   const displayMarkers = (offer) => {

    const activeCities = []
    const offersWithCity = OffersToDisplay.filter(offer => (Number(offer.cityLatit) !== 0) && (offer.cityLatit !== null) && (offer.cityLatit !== undefined))

    for (let i in offersWithCity) {
      activeCities.push(offersWithCity[i].city) // tworzy tablicę występujacych nazw miast
    }

    if (activeCities.filter( city => city === offer.city).length < 2) { // sprawdza czy dla danej oferty nazwa miasta wystąpiła mniej niż 2 razy
        return (
          <Marker
            position={{lat: Number(offer.cityLatit), lng: Number(offer.cityLong)}}
            key={offer.id}
            offer={offer}
          />
      )
      } else if (activeCities.filter( city => city === offer.city).length > 1) { // oferty których właściwość miasto się powtarza

        offersFromSameCity.push(offer) // tworzy tablicę z ofertami których nazwa miasta się powtórzyła

        return (
          <MultipleOffersMarker
            position={{lat: Number(offer.cityLatit), lng: Number(offer.cityLong)}}
            key={offer.id}
            offersFromSameCity={offersFromSameCity}
            offer={offer}
            setMapCenterLat={setMapCenterLat}
            setMapCenterLong={setMapCenterLong}
          />
      )
      }
   };

   const switchMobileView = () => {
    setMobileMapView(!MobileMapView)
   };

   const mobileViewSwitch = () => {
    if (!MobileMapView) {
      return (
        <div id='OffersAndMapContainer'>
          {!displayCookiesInfo ? // do przeniesienia do osobnego komponentu
            <motion.button whileTap={{ scale: 1.1 }} className='MobileViewSwitcher' variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.5 }} onClick={() => switchMobileView()}>
                <p className='MapSwitcherText'>Mapa</p>
                <SiOpenstreetmap className='MapButtonIcon'/>
            </motion.button>
            : ''
          }
         <div className='OffersDisplayScrollableContainer'>  
         {OffersToDisplay.length ? OffersToDisplay.filter(offer => offer.option === 'premium').map(offer => (
              <SingleOffer
                 key={offer.id}
                 offer={offer}
                 setDetailedOffer={setDetailedOffer}
                 setRefresh={setRefresh}
                 scrolledOffer={scrolledOffer}
                 />))
                : ''}
            {OffersToDisplay.length ? OffersToDisplay.filter(offer => offer.option === 'basic' || offer.option === 'basic+').map(offer => (
              <SingleOffer
                 key={offer.id}
                 offer={offer}
                 setDetailedOffer={setDetailedOffer}
                 setRefresh={setRefresh}
                 />))
                : ShowLoadingOrEmptyDataFilter()}
    </div>
       <div className='MapMainContainer'>
          <Wrapper apiKey={process.env.REACT_APP_MAPSKEY} render={render} options={{streetViewControl:false}}>
            <GoogleMap  className='Map' center={{lat:mapCenterLat, lng:mapCenterLong}} zoom={5} mapTypeControl={false} options={{streetViewControl: false, zoomControl:false}}>
            {
              OffersToDisplay.length ?
              OffersToDisplay.map(offer => displayMarkers(offer, OffersToDisplay))  : ''
            }
           </GoogleMap>
          </Wrapper>
    </div>
</div>
      )
    } else if (MobileMapView) {
       return (
        <Wrapper apiKey={process.env.REACT_APP_MAPSKEY} render={render}>
        <GoogleMap  className='MobileMap' center={{lat:mapCenterLat, lng:mapCenterLong}} zoom={5} options={{streetViewControl: false, zoomControl:false}} mapTypeControl={false}>
          {
            OffersToDisplay.length ?
            OffersToDisplay.map(offer => displayMarkers(offer, OffersToDisplay))  : ''
          }
        </GoogleMap>
       </Wrapper>
       )
    }
   };

    return (
    <div className='OffersDisplayMainContainer'> 
      {mobileViewSwitch()}
    </div>
    )
};
export default OffersDisplay;
