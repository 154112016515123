import React from 'react';
import './AdditionalFiltersModal.css'
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo, useExperienceFilter, useSalaryFilter, useCurrencyFilter } from '../../../../data/GlobalStates';
import CloseModal from '../../CommonFunctions/closeModal';

const AdditionalFiltersModal = () => {


    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ ExperienceFilter, setExperienceFilter ] = useAtom(useExperienceFilter);
    const [ SalaryFilter, setSalaryFilter ] = useAtom(useSalaryFilter);
    const [ CurrencyFilter, setCurrencyFilter ] = useAtom(useCurrencyFilter);
  

    const setExperienceLevel = (val) => {
       setExperienceFilter(val)
    };

    const switchSalaryFilter = () => {
      setSalaryFilter(!SalaryFilter)
    };

    const switchCurrencyFilter = (val) => {
      setCurrencyFilter(val)
    };

    const clearFilters = () => {
      setCurrencyFilter('')
      setExperienceFilter('')
      setSalaryFilter(false)
    };

    return (
      
       <div>
        <div className='AdditionalFilterContainer'>
           <p className='FilterContainerTitle'>Oferty tylko z podanym wynagrodzeniem:</p>
           <div>
            <button className={SalaryFilter ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={switchSalaryFilter}>Tak</button>
            <button className={!SalaryFilter ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={switchSalaryFilter}>Nie</button>
           </div>
         </div>
         <div className='AdditionalFilterContainer'>
           <p className='FilterContainerTitle'>Doświadczenie:</p>
           <div>
            <button className={!ExperienceFilter ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => setExperienceLevel('')}>Wszystkie</button>
            <button className={ExperienceFilter === 'Początkujący' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => setExperienceLevel('Początkujący')}>Początkujący</button>
            <button className={ExperienceFilter === 'Zaawansowany' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => setExperienceLevel('Zaawansowany')}>Zaawansowany</button>
            <button className={ExperienceFilter === 'Expert' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => setExperienceLevel('Expert')}>Expert</button>
           </div>
         </div>
         <div className='AdditionalFilterContainer'>
           <p className='FilterContainerTitle'>Waluta:</p>
           <div>
            <button className={!CurrencyFilter ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('')}>Wszystkie</button>
            <button className={CurrencyFilter === 'PLN' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('PLN')}>PLN</button>
            <button className={CurrencyFilter === 'EUR' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('EUR')}>EUR</button>
            <button className={CurrencyFilter === 'USD' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('USD')}>USD</button>
            <button className={CurrencyFilter === 'CHF' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('CHF')}>CHF</button>
            <button className={CurrencyFilter === 'GBP' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('GBP')}>GBP</button>
            <button className={CurrencyFilter === 'NOK' ? 'AdditionalFiltersButtonActive' : 'AdditionalFiltersButton'} onClick={() => switchCurrencyFilter('NOK')}>NOK</button>
           </div>
         </div>
         <div className='AdditionalFiltersConfirmBtnsContainer'>
            <button className='AdditionalFiltersButton' onClick={clearFilters}>Wyczyść Filtry</button>
            <button className='AdditionalFiltersButton' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Zamknij</button>
         </div>
       </div>
    )
}

export default AdditionalFiltersModal;
