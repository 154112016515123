import React from 'react';
import './RemoveCompanyLogo.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { storage } from '../../../../config';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';


const RemoveCompanyLogo = ({sRef, deleteObject, set, db, PopUpModalRef}) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const RemoveUserLogo = () => {
        const storageRef = sRef(storage, `CompaniesLogos/${LoggedUser.name}`);
        deleteObject(storageRef)
        set(PopUpModalRef(db, `users/${LoggedUser.keyName}/UserLogo`), false)
        setShowPopUpModal(false)
      };
      

    return (
        <div id='EditUserFields'>
            <h1 className='ModalTitle'>{ModalInfo.title}</h1>
            <div className='RemoveLogoBtnsContainer'>
              <button className='ConfirmBtns' onClick={RemoveUserLogo}>Tak</button>
              <button className='ConfirmBtns' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Nie</button>
            </div>
          </div>
    )
}

export default RemoveCompanyLogo;
