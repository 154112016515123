import React, { useState, useEffect } from 'react';
import './WorkerDataEditModal.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { IoIosClose } from 'react-icons/io';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo, useLoggedUser } from '../../../../data/GlobalStates';
import Roles from '../../../../data/Roles';
import Specialities from '../../../../data/Specialities';
import countriesList from '../../../../data/countriesList';
import cities from 'cities.json';
import Prefixes from '../../../../data/PhonePrefix';
import { getAuth } from "firebase/auth";


const WorkerDataEditModal = ({set, PopUpModalRef, db}) => {

    const authedUser = getAuth(); 

    //stany globalne:
    const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ LoggedUser, setLoggedUser] = useAtom(useLoggedUser);

    //stany lokalne:
    const [ Experience, setExperience ] = useState('');
    const [ ExperienceInYears, setExperienceInYears ] = useState('');
    const [ Role, setRole ] = useState('');
    const [ Speciality, setSpeciality ] = useState('');
    const [ Country, setCountry ] = useState('');
    const [ City, setCity ] = useState('');
    const [ CountryFilterCode, setCountryFilterCode ] = useState('');
    const [ PhonePrefix, setPhonePrefix ] = useState('+48');
    const [ PhoneValue, setPhoneValue ] = useState('');


    useEffect(() => { // uzupełnienie istniejących danych
      let isMounted = true
      
      if (LoggedUser) {
        setCountry(LoggedUser.Country ? LoggedUser.Country : '')
        if (LoggedUser.Country) {
          setCountryFilterCode(countriesList.filter(country => country.name_pl === LoggedUser.Country)[0].code)
        }
        setCity(LoggedUser.City ? LoggedUser.City : '')
        setRole(LoggedUser.Role ? LoggedUser.Role : '')
        setSpeciality(LoggedUser.WorkerSpeciality ? LoggedUser.WorkerSpeciality : '')
        setExperience(LoggedUser.Experience ? LoggedUser.Experience : '')
        setExperienceInYears(LoggedUser.ExperienceInYears ? LoggedUser.ExperienceInYears : '')
      } else if (!LoggedUser) {
        setModalVersion(12)
        setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
      }

      return () => {
        isMounted = false;
      };
    }, [])

    const updatedUserData = {
      AccountCreated: LoggedUser.AccountCreated,
      AccountType: LoggedUser.AccountType,
      ActivateJobSearchStatus: LoggedUser.ActivateJobSearchStatus ? LoggedUser.ActivateJobSearchStatus : 1709400606929,
      Country: !Country ? (LoggedUser.Country || null) : Country,
      City: !City ? (LoggedUser.City || null) : City,
      Role: !Role ? (LoggedUser.Role || null) : Role,
      WorkerSpeciality: !Speciality ? (LoggedUser.Speciality || null) : Speciality,
      Experience: !Experience ? (LoggedUser.Experience || null) : Experience,
      ExperienceInYears: !ExperienceInYears ? (LoggedUser.ExperienceInYears || null) : ExperienceInYears,
      BasicOffers: LoggedUser.BasicOffers,
      PremiumOffers: LoggedUser.PremiumOffers,
      Notifications: LoggedUser.Notifications,
      PhoneNumber: PhoneValue ? PhonePrefix + ' ' + PhoneValue : LoggedUser.PhoneNumber,
      SearchingForJob: !LoggedUser.SearchingForJob ? null : LoggedUser.SearchingForJob,
      SearchingForJobDate: !LoggedUser.SearchingForJobDate ? null : LoggedUser.SearchingForJobDate,
      email: LoggedUser.email,
      keyName: LoggedUser.keyName,
      name: LoggedUser.name,
      UserLogo: LoggedUser.UserLogo ? LoggedUser.UserLogo : false
    }
    
    const savePrefix = (e) => {
      setPhonePrefix(e.target.value)
    };

    const savePhoneVal = (e) => {
      setPhoneValue(e.target.value)
    };

    const saveSelectedExperience = (e) => {
      if (e.target.value === 'Wybierz') {
        setExperience('')
      } else
      setExperience(e.target.value)
    };

    const saveSelectedRole = (e) => {
      if (e.target.value === 'Wybierz') {
        setRole('')
      }
      setRole(e.target.value)
    };

    const saveSelectedSpeciality = (e) => {
      if (e.target.value === 'Wybierz') {
        setRole('')
      }
      setSpeciality(e.target.value)
    };

    const saveExperienceInYears = (e) => {
      setExperienceInYears(e.target.value)
    };

    const saveSelectedCountry = (e) => {
      setCountry(e.target.value)
      setCountryFilterCode(countriesList.filter(country => country.name_pl === e.target.value)[0].code)
    };

    const saveSelectedCity = (e) => {
      setCity(e.target.value)
    };

    const updateWorkerData = async () => { 

        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDay();

      if (LoggedUser && authedUser) {
        try {
          await set(PopUpModalRef(db, `users/${LoggedUser.keyName}`), updatedUserData)
          .then(() => {
              setModalVersion(12)
              setModalInfo({title:'Zmiany zapisane'})
          })
        } catch (error) {
            set(PopUpModalRef(db, `errors/${year}/${month}/${day}/updateWorkerData`), error.message) // patern
            setModalVersion(12)
            setModalInfo({title:'Ups coś poszło nie tak... sprawdzamy co się stało, daj nam chwilę'})
            console.log(error) 
        }
      } else if (!LoggedUser) {
          setModalVersion(12)
          setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
      }
    };

    return (
     <div id='ConfirmationModalMainContainer'>
           <div className='chooseOrCloseContainer'>
            <IoIosClose className='ClosePhoneOrSMSContainer' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}/>
           </div>
       <div className='WorkerDataEditContainer'>
         <h3 className='InputHeaders'>Numer Telefonu:</h3>
          <div>
            <div id='FakePrefixSelect'>{PhonePrefix ? <p className='prefixValue'>{PhonePrefix}</p> : <p className='prefixValue'>+48</p>}</div>
                <select id='RealSelect' onChange={(e) => savePrefix(e)}>
                  {Prefixes.sort((a, b) => (a.value - b.value)).map( prefix => 
                  <option className='SelectOption' key={prefix.country} value={prefix.value}>{prefix.value}</option>)}
                </select>
              <input onChange={savePhoneVal} className='EditInputs' placeholder={LoggedUser.PhoneNumber || 'wpisz numer telefonu'}></input>
            </div>
       </div>
       <div className='WorkerDataEditContainer'>
        <h3 className='InputHeaders'>Kraj:</h3>
           <select name="Countries" id='countrySelect' className='WorkerDetailsInputs' onChange={saveSelectedCountry} value={Country}>
             {countriesList.map(country => <option className='WorkerDetailsOptions' key={country.name_pl} value={country.name_pl}>{country.name_pl.slice(0,30)}</option>)}
           </select>
       </div>
       <div className='WorkerDataEditContainer'>
           <h3 className='InputHeaders'>Miasto:</h3>
            <select name="Countries" id='countrySelect' className='WorkerDetailsInputs' onChange={saveSelectedCity} value={City}>
              { CountryFilterCode ?
                cities.filter(city => city.country === CountryFilterCode).sort((a, b) => a.name.localeCompare(b.name)).map(city => <option className='WorkerDetailsOptions' key={city.name + city.lat + city.lng} value={city.name}>{city.name}</option>)
                : <option className='WorkerDetailsOptions'> proszę wybrać państwo</option>
              }
            </select>
       </div>
       <div className='WorkerDataEditContainer'>
       <h3 className='InputHeaders'>Zawód:</h3>
         <select name="Role" id='roleSelect' className='WorkerDetailsInputs' value={Role} onChange={saveSelectedRole}>
          {Roles.map(role => <option className='WorkerDetailsOptions' key={role} value={role}>{role}</option>)}
        </select>
       </div>
       {
        Role === 'Spawacz' ?
        <div className='WorkerDataEditContainer'>
        <h3 className='InputHeaders'>Główna metoda spawania:</h3>
         <select name="Speciality" id='SpecialitySelect' className='WorkerDetailsInputs' value={Speciality} onChange={saveSelectedSpeciality}>
           {Specialities.Spawacz.map(speciality => <option className='WorkerDetailsOptions' key={speciality} value={speciality}>{speciality}</option>)}
         </select>
        </div>
        : ''
       }
       {
        Role === 'Monter' ?
        <div className='WorkerDataEditContainer'>
        <h3 className='InputHeaders'>Specjalizacja:</h3>
         <select name="Speciality" id='SpecialitySelect' className='WorkerDetailsInputs' value={Speciality} onChange={saveSelectedSpeciality}>
           {Specialities.Monter.map(speciality => <option className='WorkerDetailsOptions' key={speciality} value={speciality}>{speciality}</option>)}
         </select>
        </div>
        : ''
       }
       {
        Role === 'CNC' ?
        <div className='WorkerDataEditContainer'>
        <h3 className='InputHeaders'>Specjalizacja:</h3>
         <select name="Speciality" id='SpecialitySelect' className='WorkerDetailsInputs' value={Speciality} onChange={saveSelectedSpeciality}>
           {Specialities.OperatorCNC.map(speciality => <option className='WorkerDetailsOptions' key={speciality} value={speciality}>{speciality}</option>)}
         </select>
        </div>
        : ''
       }
      <div className='WorkerDataEditContainer'>
       <h3 className='InputHeaders'>Doświadczenie w latach:</h3>
         <select name="Speciality" id='SpecialitySelect' className='WorkerDetailsInputs' value={ExperienceInYears} onChange={saveExperienceInYears}>
           {Specialities.Years.map(expInYrs => <option className='WorkerDetailsOptions' key={expInYrs} value={expInYrs}>{expInYrs}</option>)}
         </select>
       </div>
       <div className='WorkerDataEditContainer'>
       <h3 className='InputHeaders'>Poziom doświadczenia:</h3>
        <select name="Experience" id='ExperienceSelect' className='WorkerDetailsInputs' onChange={saveSelectedExperience} value={Experience}>
          <option className='WorkerDetailsOptions' value='Wybierz'>Wybierz</option>
          <option className='WorkerDetailsOptions' value='Początkujący'>Początkujący</option>
          <option className='WorkerDetailsOptions' value='Zaawansowany'>Zaawansowany</option>
          <option className='WorkerDetailsOptions' value='Expert'>Expert</option>
        </select>
       </div>
       <div className='WorkerDataEditConfirmButtonsContainer'>
        <button className='WorkerDataEditButtons' onClick={updateWorkerData}>Zapisz</button>
        <button className='WorkerDataEditButtons' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
       </div>
     </div>
    )
}

export default WorkerDataEditModal;
