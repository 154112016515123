import './CompaniesDisplay.css';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import CompanyDisplayCard from '../CompanyDisplayCard/CompanyDisplayCard';
import { motion } from "framer-motion";
import { useAtom } from 'jotai';
import { useSearchedCompany, useShowDetailedCard, useAllCompanies, useCompanyUrlConstructor } from '../../data/GlobalStates';

const CompaniesDisplay = ({setDetailedOrder}) => {

  const { companyName } = useParams(); // czyta parametr z linka by default = '' 
  //wpisane w url http://localhost:3000/BazaFirm/firma spowoduje że wartość companyName = firma
  //ustawienie url odbywa sie przez history push

  //stany globalne:
  const [ CompanyUrl, setCompanyUrl] = useAtom(useCompanyUrlConstructor);
  const [ SearchedCompany, setSearchedCompany] = useAtom(useSearchedCompany);
  const [ ShowDetailedCard, setShowDetailedCard] = useAtom(useShowDetailedCard);
  const [ AllCompanies, setAllCompanies] = useAtom(useAllCompanies);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }, 
  };

      useEffect(() => {
        if (AllCompanies.length) {
          setSearchedCompany(AllCompanies.sort((a,b) => {
            if (a.name < b.name) {
              return -1;
            } else if (a.name > b.name) {
              return 1;
          }
          return 0;
          }))
        }
      }, [AllCompanies])


      const handleScroll = () => {

        const element = document.getElementById('CompanyCardMainDivDetailed');

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      useEffect(() => {
        handleScroll()
    }, [ShowDetailedCard]);


    useEffect(() => {
      if (companyName !== undefined && !ShowDetailedCard) {
        setShowDetailedCard(companyName.replace(/[-]/g,' '))
      }

      setTimeout(() => {
        handleScroll()
      },2000)

  }, [companyName, ShowDetailedCard]);


      const displayCompaniesCards = () => {
        if (!AllCompanies.length && !SearchedCompany.length) {
           return (
             <LoadingAnimation />
           )
        } else if (SearchedCompany.length) {
            return (
                <div>
                  {SearchedCompany.map(company => 
                    <CompanyDisplayCard
                      key={company.name}
                      company={company}
                      setDetailedOrder={setDetailedOrder}
                    />)}
                </div>
            )
        } else if (SearchedCompany.length === 0) {
            return (
                <motion.p id='NoCompanyInfo' variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.7 }}>Nie znaleziono Firmy</motion.p>
            )
        }
      };

    return (
        <div id='CompaniesDisplayMainDiv'>
            {displayCompaniesCards()}
        </div>
    )
}

export default CompaniesDisplay;
