import React from 'react';
import './ComercialsInfo.css';

const ComercialsInfo = () => {
  return (
    <div className='ComercialsInfoMainContainer'>
        <h1 className='ComercialInfoHeaders'>Jesteś zainteresowany reklamą na portalu SteelJobs.pl ?</h1>
        <h1 className='ComercialInfoHeaders'>Zapraszamy do kontaktu:</h1>
        <h1 className='ComercialInfoHeaders'>508 568 824</h1>
        <h1 className='ComercialInfoHeaders'>lub</h1>
        <h1 className='ComercialInfoHeaders'>info@steeljobs.pl</h1>
    </div>
  )
}

export default ComercialsInfo;