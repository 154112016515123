import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './StripeButton.css';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion } from '../../data/GlobalStates';


  const StripeButton = ({prodPrice, offerId, saveOffer, setModalInfo, User, UserMail, set, AddOfferRef, db}) => {
    
    //stany lokalne:
    const [ paymentInProgress, setPaymentInProgress ] = useState(false);
    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);

    const functions = getFunctions();
    const LoadCheckOutFunction = httpsCallable(functions, 'stripeCheckOutLoad');
    const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHSTRIPE); 


    const saveOfferPaymentSessionUrl = async (param) => {
      try {
        await set(AddOfferRef(db, `offers/${offerId}/LastPaymentSessionUrl`), param)
      } catch (error) {
        console.log(error)
      }
    };


    const goToCheckOut = async () => {

      setPaymentInProgress(true)
      const stripe = await stripePromise;
      saveOffer() 

      LoadCheckOutFunction({ OfferId: offerId, price: prodPrice, User: User, UserMail: UserMail })
      .then( response => {
        if (response) {
          saveOfferPaymentSessionUrl(response.data.url)
          stripe.redirectToCheckout({sessionId: response.data.id})
        }
      }).catch(
        (error) => {
          setShowPopUpModal(true)
           setModalVersion(5)
            setModalInfo({title:'Ups nie można teraz dokonać płatności', message: error.message})
        }
      )
    };

    return (
      <div>
        {
          paymentInProgress ?
           <button id='PaymentButtonHolded' role="link">
            Zaczekaj...
           </button>
           :
           <button id='PaymentButton' role="link" onClick={goToCheckOut}>
            Płatność
           </button>
        }
      </div>
    );
  };


export default StripeButton;