import React from 'react';
import './WorkerAplication.css';

const WorkerAplication = ({apl}) => {

    let AplicationDate = new Date(apl.AplDate)


    return (
       <div className='WorkerApplicationMainContainer'>
         <div className='TitleAndCityContainer'>
           <h3 className='WorkerApplicationsHeaders'>{apl.offerTitle}</h3>
           <h3 className='WorkerApplicationsHeaders'>{apl.City}</h3>
         </div>
         <div className='TitleAndCityContainer'>
            <div className='DateAndStatusContainers'>
                <p className='WorkerApplicationsText'>Data przesłania:</p>
                <p className='WorkerApplicationsText'>{AplicationDate.toLocaleString('PL')}</p>
            </div>
            <div className='DateAndStatusContainers'>
                <p className='WorkerApplicationsText'>status:</p>
                <p className='WorkerApplicationsText'>{apl.Status}</p>
            </div>
         </div>
       </div>
    )
};

export default WorkerAplication;
