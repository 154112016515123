import './ChooseOfferToEdit.css';
import React from 'react';

import { useHistory } from 'react-router-dom';


const ChooseOfferToEdit = () => {

    const history = useHistory();

    return (
        <div id='NotLoggedMainDiv'>
            <div id='RegisterInfo'>
               <h1 id='RegisterTitle'>Wybierz ofertę którą chcesz edytować:</h1>
               <button id='RegisterBtn'  onClick={() => {history.push('/userPanel')}}>Twoje Oferty</button>
            </div>
        </div>
    )
}

export default ChooseOfferToEdit;
