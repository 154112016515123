import React, {useEffect, useState} from 'react';
import './CompanyDisplayCard.css';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { motion } from "framer-motion";
import { FiPhone } from 'react-icons/fi';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { AiOutlineMail } from 'react-icons/ai';
import { ref as sRefCompany, getDownloadURL } from 'firebase/storage';
import SingleOffer from '../SingleOffer/SingleOffer';
import { storage } from '../../config';
import { useAtom } from 'jotai';
import { useDownloadedOffers, useShowDetailedCard, useCompanyUrlConstructor } from '../../data/GlobalStates';

const CompanyDisplayCard = ({company}) => {

  const history = useHistory();
  let { companyName } = useParams();
  
  //stany lokalne:
  const [ CompanyLogo, setCompanyLogo ] = useState('');
  const [ CompanyUrl, setCompanyUrl] = useAtom(useCompanyUrlConstructor);

  //stany globalne:
  const [ DownloadedOffers, setDownloadedOffers ] = useAtom(useDownloadedOffers);
  const [ ShowDetailedCard, setShowDetailedCard] = useAtom(useShowDetailedCard);

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  useEffect(() => {
     if (company.UserLogo === true)  {
        getDownloadURL(sRefCompany(storage, `CompaniesLogos/${company.name}`))
        .then((url) => {
          setCompanyLogo(url)
        }).catch(error => {
          if (error.code === 'storage/object-not-found') {
            setCompanyLogo(null)
          } else {
            return Promise.reject(error);
          }
        })
    }
}, [company])


    const switchDetailedCard = (name) => {
      if (ShowDetailedCard === 0) {
        setShowDetailedCard(name) // stan początkowy showDetailedCard = 0 - ustawienie nazwy firmy poraz pierwszy
        setCompanyUrl(name)
        history.push({
          pathname: `/BazaFirm/${name.replace(/[' ']/g,'-')}`,
          })
      } else if (ShowDetailedCard !== 0 && ShowDetailedCard === name) {
        setShowDetailedCard(0) // firma wybrana kliknięcie ponowne zakmnie kartę detailed
        history.push({
          pathname: `/BazaFirm`,
          })
      } else if (ShowDetailedCard !== 0 && ShowDetailedCard !== name) {// kliknięcie w inną nazwę gdy karta jest otwarta
        setShowDetailedCard(name)
        history.push({
          pathname: `/BazaFirm/${name.replace(/[' ']/g,'-')}`,
          })
      }
  };

  const chooseDetailedOrNormalCard = (ShowDetailedCard, company) => { //DETAILED CARD
    if (ShowDetailedCard === company.name) {
      return (
        <motion.div id='CompanyCardMainDivDetailed' variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.7 }}>
          <button className='CloseDetailedCardButton' onClick={() => switchDetailedCard(company.name)}>
           <p>Zamknij</p>
           <MdKeyboardArrowUp className='DetailedCardCloseIcon'/>
          </button>
        <h3 className='CompanyNameHeaderDetailed'>{company.name}</h3>
        {
          CompanyLogo ? <img src={CompanyLogo} className='CompanyLogoDetailed'/> : ''
        }
        <h3 className='CompanyCity'>{company.City ? company.City : ''}</h3>
         <a href={company.Website ? `http://${company.Website}` : ''} className='CompanyWebsite'>{company.Website ? company.Website : ''}</a>
        {company.Description ? <p className='CompanyDescriptionHeader'>{company.Description}</p> : ''}
      <div className='detailedCompanyContactDiv'>
        <div className='detailedCompanyContactContainer'>
          <FiPhone className='detailedCompanyContactIcon'/>
          <p>{company.PhoneNumber}</p>
        </div>
        <div className='detailedCompanyContactContainer'>
          <AiOutlineMail className='detailedCompanyContactIcon'/>
          <p>{company.email}</p>
        </div>
      </div>
      {
        DownloadedOffers ? <p className='CompanyOffersHeaderDetailed'>Oferty Pracy: {DownloadedOffers.filter(offer => offer.companyName === company.name).length}</p> : ''
      }
      <div className='ChoosedUserOffersDisplay'>
        { DownloadedOffers ? DownloadedOffers.filter(offer => offer.companyName === company.name).map( offer =>
           <SingleOffer
             key={offer.id}
             offer={offer}
             company={company}
             />) : ''}
      </div>
    </motion.div>
    )
    } else if (ShowDetailedCard !== company.name) { /// NOT DETAILED CARD 
      return (
        <motion.div id='CompanyCardMainDiv' variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.7 }} onClick={() => switchDetailedCard(company.name)}>
          <div className='CompanyNameAndLogo'>
            <h3 className='CompanyNameHeaderNotDetailed'>{company.name}</h3>
            { CompanyLogo ? <img src={CompanyLogo} className='CompanyLogoNotDetailed'/> : <div className='imgMockup'/>}
          </div>
      {company.Description ? <p className='CompanyDescriptionHeader'>{company.Description.slice(0,40)}...</p> : ''}
      <div>
        {
          DownloadedOffers ? <p className='CompanyOffersHeader'>Oferty Pracy: {DownloadedOffers.filter(offer => offer.companyName === company.name).length}</p> : ''
        }
      </div>
      </motion.div>
      )
    }
  };

//////////////////////////MAIN RETURN

    return (
        <div>
          {chooseDetailedOrNormalCard(ShowDetailedCard, company)}
        </div>
    )
}

export default CompanyDisplayCard;
