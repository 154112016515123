import React from 'react';
import './CompanyWebSiteEdit.css';
import selectUserDataToEdit from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const CompanyWebSiteEdit = ({Website, setWebsite, set, PopUpModalRef, db}) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const saveWebSite = async () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        if (LoggedUser) {
         try {
           await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/Website`), Website ? Website : (LoggedUser.Website || null))
           .then(() => {
              setModalVersion(12)
               setModalInfo({title:'Zmiany zapisane'})
           })
         } catch (error) {
            setModalVersion(12)
             setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
              set(PopUpModalRef(db, `errors/${year}/${month}/${day}/saveWebSite`), error.message)
         }
        } else if (!LoggedUser) {
            setModalVersion(12)
             setModalInfo({title:'sesja wygasła proszę się zalogować'})
        }
     };

     const setWebsiteVal = (e) => {
        setWebsite(e.target.value)
      };

    return (
        <div id='EditUserFields'>
         <h1 className='ModalTitle'>{ModalInfo.title}</h1>
          <input className='EditInputs' placeholder={LoggedUser.Website ? LoggedUser.Website : 'www.twoja strona.com'} onChange={setWebsiteVal}></input>
           <div className='WebSiteEditBtnsContainer'>
             <button className='ConfirmBtns' onClick={saveWebSite}>OK</button>
             <button className='ConfirmBtns' onClick={() => selectUserDataToEdit(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
           </div>
        </div>
    )
}

export default CompanyWebSiteEdit;


