import React, { useEffect } from 'react';
import './JobFilterButton.css';
import { motion } from "framer-motion";
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import { useMainFilter, useSecondFilter } from '../../../../data/GlobalStates';
import { useHistory, useParams } from 'react-router-dom';
import { useAtom } from 'jotai'; 

function JobFilterButton({role}) {

    const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);
    const [ SecondFilter, setSecondFilter ] = useAtom(useSecondFilter);

    const history = useHistory();
    const { MainFilterValue, SecondFilterValue } = useParams();

    useEffect(() => {// pobieranie wartości filtrów z linków
      if ((MainFilterValue || SecondFilterValue) && (!MainFilter || !SecondFilter)) {
        setMainFilter(MainFilterValue)
        setSecondFilter(SecondFilterValue)
      }
  }, [MainFilterValue, SecondFilterValue]);

    const SetMainFilter = (value, val2) => {
        setMainFilter(value)
         if (value.length === 0) {
           history.push({pathname: `/`}) 
         } else if (value.length > 0) {
           history.push({pathname: `/Filtry/${value}`}) 
         }
        setSecondFilter('') 
      };


    const ChooseBtnLogo = (role) => {
       if (role === 'Monter') {
        return (
            <BiWrench className='RoleButtonIcon' />
          )
       } else if (role === 'Spawacz') {
        return (
            <GiCannonShot className='RoleButtonIcon' />
          )
       } else if (role === 'CNC') {
        return (
            <CgArrowsShrinkH className='RoleButtonIcon' />
          )
       } else if (role === 'Ślusarz') {
        return (
            <GiAncientScrew className='RoleButtonIcon' />
          )
       } else if (role === 'Szlifierz') {
        return (
            <GiStoneWheel className='RoleButtonIcon' />
          )
       } else if (role === 'Kowal') {
        return (
            <GiBlacksmith className='RoleButtonIcon' />
          )
       } else if (role === 'Malarz') {
        return (
            <GiLargePaintBrush className='RoleButtonIcon' />
          )
       } else if (role === 'Brygadzista') {
        return (
            <RiTeamLine className='RoleButtonIcon' />
          )
       } else if (role === 'Inżynier') {
        return (
            <CgArrowsShrinkH className='RoleButtonIcon' />
          )
       }
    };

      if (role === 'Wybierz') {
        return (
            <motion.button whileTap={{ scale: 1.1 }} className={MainFilter === '' ? 'FilledJobFilter' : 'JobFilter'} onClick={() => {SetMainFilter('')}}>
                 Wszystkie
            </motion.button>
        )
      } else {
        return (
              <motion.button whileTap={{ scale: 1.1 }} className={MainFilter === role ? 'FilledJobFilter' : 'JobFilter'} onClick={() => {SetMainFilter(role)}}>
                {ChooseBtnLogo(role)}
                {role}
              </motion.button>
        )
      }
};

export default JobFilterButton;