

const useFilterOffersToDisplay = (DownloadedOffers, MainFilter, SecondFilter, CountryFilter, ExperienceFilter, SalaryFilter, SearchInputValue, CityFilter, CurrencyFilter) => {


      const offerSalaryDisplay = (offer) => {
         if (SalaryFilter === false) {
           return offer
        } else if (SalaryFilter === true) {
           return offer.minSalary !== '' || offer.maxSalary !== ''
        }
        };

          const searchInputValues = (offer) => {

            const searchArray = SearchInputValue.split(' ')

              if (SearchInputValue.length === 0) {
               return offer
              } else if (searchArray.length === 1) {
                return offer.title.toLowerCase().includes(searchArray[0].toLowerCase())
              } else if (searchArray.length === 2) {
                return offer.title.toLowerCase().includes(searchArray[0].toLowerCase()) && offer.title.toLowerCase().includes(searchArray[1].toLowerCase())
              } else if (searchArray.length === 3) {
                return offer.title.toLowerCase().includes(searchArray[0].toLowerCase()) && offer.title.toLowerCase().includes(searchArray[1].toLowerCase()) && offer.title.toLowerCase().includes(searchArray[2].toLowerCase())
              } else if (searchArray.length > 3) {
                return
            }
          };

            const countryFilterValues = (offer) => {
                if (!CountryFilter) {
                   return offer
                } else if (CountryFilter) {
                   return offer.country === CountryFilter
                }
          };


            const cityFilterValues = (offer) => {
                if (!CityFilter) {
                   return offer
                } else if (CityFilter) {
                   return offer.city === CityFilter
                }
          };

          const roleFilterValues = (offer) => {
            if (!MainFilter) {
               return offer
            } else if (MainFilter) {
               return offer.role === MainFilter
            }
          };

          const specialityFilterValues = (offer) => {
            if (!SecondFilter) {
               return offer
            } else if (SecondFilter) {
               return offer.speciality === SecondFilter
            }
          };

          const experienceFilterValues = (offer) => {
            if (!ExperienceFilter) {
               return offer
            } else if (ExperienceFilter) {
               return offer.experience === ExperienceFilter
            }
          };

          const currencyFilterValues = (offer) => {
             if (!CurrencyFilter) {
               return offer
             } else if (CurrencyFilter) {
               return offer.currency === CurrencyFilter
             }
          };


          if (DownloadedOffers) {
            return DownloadedOffers.filter( offer => offerSalaryDisplay(offer) && searchInputValues(offer) && cityFilterValues(offer) && countryFilterValues(offer) && roleFilterValues(offer) && specialityFilterValues(offer) && experienceFilterValues(offer) && currencyFilterValues(offer))
        }
        };



export default useFilterOffersToDisplay;