import React, {useEffect} from 'react';
import './AddOffer.css';
import '../SingleOffer/SingleOffer.css';
import '../AddOfferInfo/AddOfferInfo.css'
import FakeOffer from '../../data/FakeOffer';
import Roles from '../../data/Roles';
import Specialities from '../../data/Specialities';
import Currencies from '../../data/Currencies';
import SingleOffer from '../SingleOffer/SingleOffer'
import StepsDisplayer from '../StepsDisplayer/StepsDisplayer';
import { getAuth } from "firebase/auth";
import { useHistory } from 'react-router-dom';
import OfferTypes from '../../data/OfferTypes';
import ShowChoosingOption from './ChoosingOption/showChosingOption';
import ShowEditFields from './EditFields/ShowEditFields';
import ShowPayment from './ShowPayment/ShowPayment';
import { useAtom } from 'jotai';
import { useLoggedUser, useStepper, useChoosedOption, useShowPopUpModal } from '../../data/GlobalStates';

const AddOffer = ({setCityLat, setCityLng, cityLat, cityLng, checkUser, SelectedRole, setSelectedRole, Title, setSelectedSpeciality, setExperience, setMinSalary, setMaxSalary, Description, setTitle,setCheckUser, awaitOffer, setAwaitOffer, setRefresh, refresh, setDescription, setCountry, setCurrency, SelectedSpeciality, setSalaryType, Experience, MinSalary, MaxSalaryWarn, MaxSalary, Currency, SalaryType, Country, countryFilterCode, setCountryFilterCode}) => {

  const authedUser = getAuth();
  const history = useHistory();

  //stany globalne:
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ Stepper, setStepper ] = useAtom(useStepper);
  const [ ChoosedOption, setChoosedOption ] = useAtom(useChoosedOption);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);

  useEffect(()=>{
    if ((!authedUser.currentUser && !LoggedUser) || LoggedUser.AccountType === 'Private') {
      history.push('/userPanel')
      };
  },[LoggedUser, authedUser.currentUser]);


  useEffect(()=>{
    if (Stepper === 0) {
      setAwaitOffer({})
    }
  },[Stepper]);



const CloseModalByKey = (e) => {
  if (e.key === 'Enter') {
    setShowPopUpModal(false)
  }
};


const stepBack = (step, option) => {
  setCheckUser(checkUser - 1)
  setStepper(step)
  setChoosedOption(option)
  window.scrollTo(0, 0)
};

    return (
        <div id='AddOfferMainDiv' onKeyDown={(e) => CloseModalByKey(e)} tabIndex={1} >
            { Stepper === 0 ?
            <>
            <StepsDisplayer awaitOffer={awaitOffer}/>
            <ShowChoosingOption
              OfferTypes={OfferTypes}
              awaitOffer={awaitOffer}
              SingleOffer={SingleOffer}
              FakeOffer={FakeOffer}
              authedUser={authedUser} 
              checkUser={checkUser}
              setCheckUser={setCheckUser}
             />
             </>
             : '' }
            { Stepper === 1 ?
            <>
            <StepsDisplayer awaitOffer={awaitOffer}/>
             <ShowEditFields
               awaitOffer={awaitOffer}
               SelectedRole={SelectedRole}
               setSelectedRole={setSelectedRole}
               Roles={Roles}
               Specialities={Specialities}
               Title={Title}
               refresh={refresh}
               setSelectedSpeciality={setSelectedSpeciality}
               setExperience={setExperience}
               setMinSalary={setMinSalary}
               setMaxSalary={setMaxSalary}
               setTitle={setTitle}
               Description={Description}
               setDescription={setDescription}
               setCountry={setCountry}
               setCurrency={setCurrency}
               SelectedSpeciality={SelectedSpeciality}
               setSalaryType={setSalaryType}
               Experience={Experience}
               MinSalary={MinSalary}
               MaxSalaryWarn={MaxSalaryWarn}
               MaxSalary={MaxSalary}
               Currency={Currency}
               Currencies={Currencies}
               SalaryType={SalaryType}
               Country={Country}
               stepBack={stepBack}
               setAwaitOffer={setAwaitOffer}
               setRefresh={setRefresh}
               countryFilterCode={countryFilterCode}
               setCountryFilterCode={setCountryFilterCode}
               cityLat={cityLat}
               cityLng={cityLng}
               setCityLat={setCityLat}
               setCityLng={setCityLng}
             />
             </> : '' }
            { Stepper === 2 ?
            <>
            <StepsDisplayer awaitOffer={awaitOffer}/>
            <ShowPayment
              awaitOffer={awaitOffer}
              refresh={refresh}
              setRefresh={setRefresh}
              stepBack={stepBack}
              setTitle={setTitle}
              setExperience={setExperience}
              setSelectedRole={setSelectedRole}
              setSelectedSpeciality={setSelectedSpeciality}
              setCountry={setCountry}
              setDescription={setDescription}
              setMinSalary={setMinSalary}
              setMaxSalary={setMaxSalary}
              setAwaitOffer={setAwaitOffer}
            />
            </>
            : '' }
    </div>
    )
}

export default AddOffer;
