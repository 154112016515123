import React from 'react';
import './NotificationsSwitch.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const NotificationsSwitch = ({set, PopUpModalRef, db, newTimeStamp, authedUser}) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const switchNotifications = async () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        try {
          await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/Notifications`), !LoggedUser.Notifications)
          .then(() => {
           setModalVersion(12)
           setModalInfo({title:'Zmiany zapisane'})
          })
       } catch (error) {
        setModalVersion(12)
         setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
          set(PopUpModalRef(db, `errors/${year}/${month}/${day}/switchNotifications`), error.message)
      }
      };
      

    return (
        <div id='EditUserFields'>
           <h1 className='ModalTitle'>{ModalInfo.title}</h1>
           <h3 className='ModalMessage'>{ModalInfo.message}</h3>
              <div id='DeleteConfirmDiv'>
                <button className='ConfirmBtns' onClick={switchNotifications}>Tak</button>
                <button className='ConfirmBtns' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Nie</button>
              </div>
        </div>
    )
}

export default NotificationsSwitch;




