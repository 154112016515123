import React, {useState, useEffect} from 'react';
import Prefixes from '../../../../data/PhonePrefix';
import { useAtom } from 'jotai';
import { useShowPopUpModal } from '../../../../data/GlobalStates';

const UserRegister = ({db, newTimeStamp, set, authedUser, get, dbRef, child, PopUpModalRef, CompanyPhonePrefix, setCompanyPhoneInput, CompanyPhoneInput, CompanyPhoneInputAlert, setCompanyPhoneInputAlert, setCompanyPhonePrefix}) => {


    //stany lokalne:
    const [ LoginOccupied, setLoginOccupied ] = useState(false);
    const [ CompanyNameInput, setCompanyNameInput ] = useState('');
    const [ AccountType, setAccountType ] = useState('');
    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);

    useEffect(() => {
        const checkUsers = async () => {
            await get(child(dbRef, `users`)).then((users) => {
              if (Object.values(users.val()).filter(user => user.name === CompanyNameInput)[0]) {
                setLoginOccupied(true)
              } else setLoginOccupied(false)
            })
      };
        checkUsers()
      },[CompanyNameInput])

    const setInput = (e) => {
        if (e.target.value.length < 26) {
          setCompanyNameInput(e.target.value)
        }
    };
      
    const savePhoneNum = (e) => { // zapis w zmiennej przy onChange
        if (!isNaN(e.target.value.replace(/\s/g, ''))) {
          setCompanyPhoneInput(e.target.value)
          setCompanyPhoneInputAlert('')
        } else if (isNaN(e.target.value)) {
          setCompanyPhoneInputAlert(e.target.value)
        }
      };

    const createAccount = () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        const UserData = {
          AccountCreated: newTimeStamp,
          email:authedUser.currentUser.email,
          keyName:authedUser.currentUser.email.replace(/[.,#$[\]]/g,''),
          name:CompanyNameInput,
          PhoneNumber:(CompanyPhonePrefix + ' ' + CompanyPhoneInput),
          BasicOffers:0,
          PremiumOffers:0,
          Notifications: true,
          AccountType:AccountType === 'Prywatne' ? 'Private' : ''
        }
    
        try {
          set(PopUpModalRef(db, `users/${UserData.keyName}`), UserData)
        } catch (error) {
           set(PopUpModalRef(db, `errors/${year}/${month}/${day}/createAccount`), error.message)
        }
    };

    const ChooseAccountType = (type) => {
        setAccountType(type)
       };

    const savePrefix = (e) => {
        setCompanyPhonePrefix(e.target.value)
    };

    const showNameOccupancy = () => {
        if (LoginOccupied && AccountType === '') {
          return (
            <div id='Hint'>
              <p>Nazwa Zajęta</p>
            </div>
          )
        } else if (!LoginOccupied && AccountType === 'Prywatne') {
          return (
            <div id='Hint'>
              <p>Login Dostępny</p>
            </div>
          )
        }
    };

    const saveData = () => { // SAVE DLA PIERWSZEGO LOGINU PODANIE NAZWY FIRMY I TEL
            if (LoginOccupied === false && CompanyNameInput.length >= 3 && CompanyPhoneInput.length >= 3) {
                createAccount()
                setShowPopUpModal(false)
            }
    };

    return (
        <div id='EditUserFields'>
        <h1 className='ModalTitle'>Witamy w serwisie:</h1>
          <h1 id='ModalLogo'>SteelJobs.pl</h1>
            <h3 className='ModalMessage'>Wybierz rodzaj konta:</h3>
             <div id='ChooseAccountTypeContainer'>
               <p className={AccountType === '' ? 'ChooseAccountTypeBtnFilled' : 'ChooseAccountTypeBtn'} onClick={() => ChooseAccountType('')}>Pracodawca</p>
               <p className={AccountType === 'Prywatne' ? 'ChooseAccountTypeBtnFilled' : 'ChooseAccountTypeBtn'} onClick={() => ChooseAccountType('Prywatne')}>Pracownik</p>
             </div>
              <div>
                 <div id='CompanyInputAndTitle'>
                  {AccountType !== 'Prywatne' ? <p className='RegisterCompanyTitle'>Nazwa Firmy: (max. 25 znaków)</p> :
                  <p className='RegisterCompanyTitle'>Nazwa Użytkownika: (max. 25 znaków)</p>
                  }
                   <input className='EditInputs' placeholder='...' onChange={setInput} value={CompanyNameInput}></input>
                 </div>
                {// jeśli checkusers zwróci true (nazwa będzie zajęta) wyświetl info o zajętości loginu
                  CompanyNameInput.length ?
                  showNameOccupancy() : ''
                }
                { // info o zbyt krótkiej nazwie firmy i numerze tel + logika brak możliwości zapisu
                    CompanyNameInput.length && CompanyNameInput.length < 3 && AccountType !== 'Prywatne' ?
                     <div id='Hint'>
                      <p>Nazwa Firmy jest za krótka</p>
                    </div>
                    : ''
                }
            </div>
              <div id='PhoneInputAndPrefix'>
                <p id='PhoneNumberHeader'>Numer telefonu:</p>
                <div id='FakePrefixSelect'>{CompanyPhonePrefix ? <p className='prefixValue'>{CompanyPhonePrefix}</p> : <p className='prefixValue'>+48</p>}</div>
                    <select id='RealSelect' onChange={(e) => savePrefix(e)}>
                        {Prefixes.sort((a, b) => (a.value - b.value)).map( prefix => <option className='SelectOption' key={prefix.country} value={prefix.value}>
                        {prefix.value}
                        </option>)}
                    </select>
             <input onChange={savePhoneNum} className='EditInputs'></input>
           </div>
             { // Value nie jest liczbą
                CompanyPhoneInputAlert ?
                <div id='Hint'>
                  <p>Proszę wpisać cyfry</p>
                </div> : ''
             }
          <button className='ConfirmBtns' onClick={saveData}>OK</button>
       </div>
    )
}

export default UserRegister;
