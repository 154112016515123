import React from 'react';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const DisplayApplicationConfirmation = () => { 

    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    // const ApplyToOffer = async () => {

    //     let WorkerData = {
    //       Country: LoggedUser.Country,
    //       City: LoggedUser.City,
    //       Role: LoggedUser.Role,
    //       Experience: LoggedUser.Experience,
    //       PhoneNumber: LoggedUser.PhoneNumber,
    //       email: LoggedUser.email,
    //       name: LoggedUser.name,
    //       offerMail: detailed.email,
    //       offerTitle: detailed.title,
    //       offerId: detailed.id
    //    }
      
    //     if (authedUser && LoggedUser && detailed) {
      
    //      try {
    //       await get(child(dbRef, `offers`))
    //       .then((offers) => {
    //         let offersArray = Object.values(offers.val()) 
    //         let currentOffer = offersArray.filter(i => i.id === detailed.id)
    //         if (currentOffer) {
    //            set(OfferDetailsRef(db, `offers/${detailed.id}/applications/${LoggedUser.keyName}`), WorkerData)
    //             .then(() => {
    //                setShowPopUpModal(true)
    //                setModalVersion(12)
    //                setModalInfo({title:'Aplikacja przesłana'})
    //                saveRegisterApplyStats()
    //             })
    //         } else if (!currentOffer) {
    //           setShowPopUpModal(true)
    //           setModalVersion(12)
    //           setModalInfo({title:'Nie znaleziono oferty... sprawdź pozostałe na stronie głównej'})
    //         }
    //       })
      
    //      } catch (error) {
    //         setShowPopUpModal(true)
    //         setModalVersion(12)
    //         setModalInfo({title:'Ups nie udało się sprawdzić aktualności oferty, spróbuj odświeżyć stronę'})
    //      }
    //     }
    //   };

    return (
     <div id='ConfirmationModalMainContainer'>
         <h2 className='ModalTitle'>{ModalInfo.title}</h2>
           <h3 className='ModalMessage'>{ModalInfo.message}</h3>
         <div id='ConfirmBtn' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>
           <h3 id='ConfirmBtnText'>OK</h3>
         </div>
     </div>
    )
};

export default DisplayApplicationConfirmation;
