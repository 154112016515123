import React from 'react';
import './RoleButtonsBar.css';
import Roles from '../../../data/Roles';
import JobFilterButton from './JobFilterButton/JobFilterButton';
import SpecialityButtonsBar from '../SpecialityButtonsBar/SpecialityButtonsBar';
import { CgClose } from 'react-icons/cg';
import SearchBarNavButtons from '../SearchBarNavButtons/SearchBarNavButtons';
import FilteredCountryDisplayCard from './FilteredCountryDisplayCard/FilteredCountryDisplayCard';
import FilteredCityDisplayCard from './FilteredCityDisplayCard/FilteredCityDisplayCard'
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiBlacksmith } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import { GoLocation } from 'react-icons/go';
import MapCloseButton from '../../Map/MapCloseButton/MapCloseButton';
import { useAtom } from 'jotai';
import { useSearchInputValue, useCountryFilter, useCityFilter, useModalVersion, useModalInfo, useShowPopUpModal, useMobileMapView } from '../../../data/GlobalStates';


const RoleButtonsBar = () => {


       const toDisplay = [];
       //stany globalne:
       const [ SearchInputValue, setSearchInputValue ] = useAtom(useSearchInputValue);
       const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
       const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
       const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
       const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
       const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
       const [ MobileMapView, setMobileMapView] = useAtom(useMobileMapView);
  
       const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      };

      const setInputValue = (e) => {

        const searchArray = SearchInputValue.split(' ')

        if (searchArray.length <= 4) {
          setSearchInputValue(e.target.value)
        } else if (searchArray.length > 4) {
          setSearchInputValue('')
        }
      };

    const showLocalizationOptions = () => {
      if (CountryFilter.length < 1 && CityFilter.length < 1) {
        setShowPopUpModal(true)
        setModalVersion(14)
        setModalInfo({title: 'Wybierz Lokalizację'})
      } else {
        return
      }
      };


    const displayChoosedLocationValues = () => {
      if (CountryFilter.length === 0 && CityFilter.length === 0) {
        return (
        <div id='locationSearchPlaceholderContainer'>
         <p className='MainInputsPlaceholders'>Lokalizacja</p>
         <GoLocation className='filteredCountryTipIcon'/>
        </div>
        )
      } else if (CountryFilter.length > 0 && CityFilter.length === 0) {
        return (
        <FilteredCountryDisplayCard/>)
      } else if (CountryFilter.length > 0 && CityFilter.length > 0) {
        return (
         <div id='CountryAndCityDisplayContainer'>
            <FilteredCountryDisplayCard/>
            <FilteredCityDisplayCard/>
         </div>
        )
      } else if (!CountryFilter.length && CityFilter.length > 0) {
        return (
          <FilteredCityDisplayCard/>
        )
      }
    }


    const showFilterButtons = () => {

        return (
              <div id='MainFilterButtons'>
                {
                  !MobileMapView ?
                  <div>
                    <div id='MainFiltersDiv'>
                        {Roles.map( role =>
                           <JobFilterButton
                             key={role}
                             role={role}
                      />)}
                  </div>
               </div>
               :
               <div className='MapCloseButtonContainer'>
                 <MapCloseButton/>
               </div>
                }
                </div>
        )
       };

       const deleteKeyWord = (word) => {
         setSearchInputValue(toDisplay.filter(item => item.id !== word.id).map(w => w.value).toLocaleString().replace(/[,]/g, ' '))
        };

       const showSearchValues = () => {

        let splitted = SearchInputValue.split(' ')
        for (let i in splitted) {
          if (splitted[i].length > 2) {
            toDisplay.push({id: toDisplay.length, value: splitted[i]})
          }
        };


        return (
          <div id='SearchValuesMainDiv'>
            {toDisplay.map(word =>
              <div variants={variants} key={word.id} initial="hidden" animate="visible"  transition={{ duration: 0.8 }} className='SingleWordDiv'>
                <p className='SingleWord'>{word.value.length > 10 ? word.value.slice(0,10) + '...' : word.value}</p>
               <CgClose className='KeyWordCloseBtn' onClick={() => deleteKeyWord(word)}/>
             </div>)}
          </div>
        )
      };

       const displaySubFilterButtons = () => {
        if (!MobileMapView) {
          return (
            <SpecialityButtonsBar/>
          )
        }
       };

    return (
        <div id='TopBar2MainDiv'>
               <div id='SearchInputsAndLogosContainer'> 
                 <div className='SearchInputsAndMainButtonsContainer'>
                   <div id='SearchInputsContainer'> 
                      <input className='SearchInputs' placeholder='Znajdź ofertę' onChange={setInputValue} value={SearchInputValue}></input>
                          <div id='LabelAndSelect' onClick={() => showLocalizationOptions()}>
                             <div id={CountryFilter.length === 0 ? 'LocalizationContainerSmall' : 'LocalizationContainerSmallFilled'}>
                               { displayChoosedLocationValues ()}
                             </div>
                          </div>
                     </div>
                     <SearchBarNavButtons/>
                 </div>
                          <div className='SearchInputIconsContainer'>
                            <BiWrench className='searchInputIcons'/>
                            <GiCannonShot className='searchInputIcons'/>
                            <GiBlacksmith className='searchInputIcons'/>
                            <RiTeamLine className='searchInputIcons'/>
                          </div>
               </div>

               {SearchInputValue ? showSearchValues() : showFilterButtons()}
               {displaySubFilterButtons()}
        </div>
    )
}

export default RoleButtonsBar;