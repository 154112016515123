import React, {useState} from 'react';
import './SendEmailModal.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { IoIosClose } from 'react-icons/io';
import { MdContentCopy } from 'react-icons/md';
import { BiMailSend } from 'react-icons/bi';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';


const SendEmailModal = () => {

  const [ EmailCopied, setEmailCopied ] = useState(false);
  //stany globalne:
  const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

  const copyEmailValue = () => {
    navigator.clipboard.writeText(DetailedOffer.email)
    setEmailCopied(true)
    // setTimeout(() => {
    //   setEmailCopied(false)
    // },4000)
  };

    return (
     <div id='ConfirmationModalMainContainer'>
        <div className='chooseOrCloseContainer'>
          <IoIosClose className='ClosePhoneOrSMSContainer' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}/>
        </div>
        { EmailCopied ? <p className='CopiedEmailTip'>e-mail skopiowany</p> : ''}
        <h2 className='PhoneOrSMSContactNumber'>{DetailedOffer.email}</h2>
        <div className='PhoneOrSMSButtonsContainer'>
              <a className='PhoneAndSMSbtn' onClick={copyEmailValue}>
               <MdContentCopy />
               <p>Skopiuj e-mail</p>
              </a>
              <a className='PhoneAndSMSbtn' href={`mailto:${DetailedOffer.email}?subject=W odpowiedzi na ofertę pracy na portalu SteelJobs.pl`}>
                <BiMailSend />
                <p>Wyślij e-mail</p>
              </a>
        </div>
     </div>
    )
}

export default SendEmailModal;

//
