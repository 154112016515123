import './PopUpModal.css';
import React, {useState, useEffect} from 'react';
import { storage } from '../../config';
import dataBase from '../../config';
import { ref as PopUpModalRef, set, get, child} from "firebase/database";
import Prefixes from '../../data/PhonePrefix';
import ShowAddButtons from '../AddOffer/AddButtons/AddButtons';
import { ref as sRef,  deleteObject  } from "firebase/storage";
import { useHistory } from 'react-router-dom';
import LogoUploader from './LogoUploader/LogoUploader';
import { AiOutlinePhone } from 'react-icons/ai';
import { CgWebsite } from 'react-icons/cg';
import { BsTextIndentLeft } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsFillFileEarmarkBinaryFill } from 'react-icons/bs';
import StatuteDisplay from './ModalVersions/StatuteDisplay/StatuteDisplay';
import ConfirmationModal from './ModalVersions/ConfirmationModal/ConfirmationModal';
import AddCompanyLogo from './ModalVersions/AddCompanyLogo/AddCompanyLogo';
import RemoveCompanyLogo from './ModalVersions/RemoveCompanyLogo/RemoveCompanyLogo';
import DeleteOfferPermanently from './ModalVersions/DeleteOfferPermanently/DeleteOfferPermanently';
import NotificationsSwitch from './ModalVersions/NotificationsSwitch/NotificationsSwitch';
import SMSorCall from './ModalVersions/SMSorCall/SMSorCall';
import CompanyDescriptionEdit from './ModalVersions/CompanyDescriptionEdit/CompanyDescriptionEdit';
import CompanyAddressEdit from './ModalVersions/CompanyAddressEdit/CompanyAdressEdit';
import CompanyNipNumberEdit from './ModalVersions/CompanyNipNumberEdit/CompanyNipNumberEdit';
import CompanyWebSiteEdit from './ModalVersions/CompanyWebSiteEdit/CompanyWebSiteEdit';
import CompanyPhoneNumberEdit from './ModalVersions/CompanyPhoneNumberEdit/CompanyPhoneNumberEdit';
import ChooseOffersLocalization from './ModalVersions/ChooseOffersLocalization/ChooseOffersLocalization';
import SendEmailModal from './ModalVersions/SendEmailModal/SendEmailModal';
import UserRegister from './ModalVersions/UserRegister/UserRegister';
import WorkerJobSearchStatus from './ModalVersions/WorkerJobSearchStatus/WorkerJobSearchStatus';
import AdditionalFiltersModal from './ModalVersions/AdditionalFiltersModal/AdditionalFiltersModal';
import WorkerDataEditModal from './ModalVersions/WorkerDataEditModal/WorkerDataEditModal';
import DisplayOfferAplications from './ModalVersions/DisplayOfferAplications/DisplayOfferAplications';
import DisplayApplicationConfirmation from './ModalVersions/DisplayApplicationConfirmation/DisplayApplicationConfirmation';
import WorkerOffersModal from './ModalVersions/WorkerOffersModal/WorkerOffersModal';
import WorkerAplicationConfirmation from './ModalVersions/WorkerAplicationConfirmation/WorkerAplicationConfirmation';
import { useAtom } from 'jotai';
import { useDetailedOffer, useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../data/GlobalStates';


const PopUpModal = ({popularCountries, countryFilterCode, setCountryFilterCode, setTitle, setExperience, setSelectedRole, setSelectedSpeciality, setCountry, setMinSalary, setMaxSalary, awaitOffer, refresh, setRefresh, setAwaitOffer, authedUser, setCountryFilter, detailedOrder, UserLogo, popularCities}) => {

  const db = dataBase; // Zapis
  const dbRef = PopUpModalRef(dataBase);// Odczyt
  const newTimeStamp = Date.now();
  const history = useHistory();
  

  // stany lokalne
  const [ CompanyPhonePrefix, setCompanyPhonePrefix ] = useState('+48')
  const [ CompanyPhoneInput, setCompanyPhoneInput ] = useState('')
  const [ CompanyPhoneInputAlert, setCompanyPhoneInputAlert ] = useState('')
  const [ Description, setDescription ] = useState('')
  const [ City, setCity ] = useState('')
  const [ Street, setStreet ] = useState('')
  const [ BuildingNr, setBuildingNr ] = useState('')
  const [ LocalNr, setLocalNr ] = useState('')
  const [ ZipCode, setZipCode ] = useState('')
  const [ NIP, setNIP ] = useState('')
  const [ NIPinfo, setNIPinfo ] = useState('')
  const [ Website, setWebsite ] = useState('')
  const [imgUrl, setImgUrl] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0)
  const [displayUploadProgressValue, setDisplayUploadProgressValue] = useState(0)
  const [fileSizeAlert, setFileSizeAlert] = useState(false)
  const [offerToActivateOption, setofferToActivateOption] = useState(awaitOffer ? awaitOffer.option : '')
  const [ filteredCountryName, setFilteredCountryName ] = useState('')
  const [ filteredCityName, setFilteredCityName ] = useState('')
  //stany globalne
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);


    useEffect(() => {
      setDisplayUploadProgressValue(progresspercent)
    },[progresspercent])

    useEffect(() => {
      
      const scrollPopUpModal = () => {
        const PopUpModalContainer = document.getElementById('SmallMainDiv');
           if (ShowPopUpModal === true) {
            PopUpModalContainer.scrollIntoView({behavior: 'smooth', block: "center"})
           } else return
       };
      
       scrollPopUpModal() 
       
    },[ShowPopUpModal])

const dontSave = () => {
  setShowPopUpModal(false)
};

const addConfirm = () => {
    setShowPopUpModal(false)
    setRefresh(refresh + 1.3)
};


const saveOfferToActivateOption = (e) => {
  //setofferToActivateOption
  setAwaitOffer({...awaitOffer, option: e.target.value})
};

const EndOfferDisplayTime = async () => { 
  if (authedUser && LoggedUser && DetailedOffer) {
    try {
       await set(PopUpModalRef(db, `offers/${DetailedOffer.id}/visibility`), false)
        .then(() => {
          setModalVersion(12)
           setModalInfo({title:'Ogłoszenie zakończone'})
            setRefresh(Math.floor(Math.random() * 10))
      })
   } catch (error) {
     setModalVersion(12)
      setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
       const errMessage = {
         occurDate: newTimeStamp,
         user: authedUser.currentUser.email,
         Actiontype:'deleteOffer',
         fbErrMsg:error.toLocaleString(),
     }
       set(PopUpModalRef(db, `errors/${new Date(errMessage.occurDate).toLocaleString('pl').slice(0,10).replace(/[.,#$[\]]/g,'')}/${errMessage.occurDate}`), errMessage)
   }
  } else if (!LoggedUser && DetailedOffer && authedUser) {
    setModalVersion(12)
     setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
  }
};


const deleteOrder = async () => {
  try {
     await set(PopUpModalRef(db, `orders/${detailedOrder.id}/visibility`), false)
     .then(() => {
      setModalVersion(12)
      setModalInfo({title:'Oferta zakończona'})
      setRefresh(refresh + 32)
     })
  } catch (error) {
    setModalVersion(12)
     setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
      const errMessage = {
        occurDate: newTimeStamp,
        user: authedUser.currentUser.email,
        Actiontype:'deleteOrder',
        fbErrMsg:error.toLocaleString(),
    }
      set(PopUpModalRef(db, `errors/${new Date(errMessage.occurDate).toLocaleString('pl').slice(0,10).replace(/[.,#$[\]]/g,'')}/${errMessage.occurDate}`), errMessage)
  }
};

const activateOrder = async () => {
  try {
     await set(PopUpModalRef(db, `orders/${detailedOrder.id}/visibility`), true)
     .then(() => {
      set(PopUpModalRef(db, `orders/${detailedOrder.id}/addDate`), newTimeStamp)
      setModalVersion(12)
      setModalInfo({title:'Oferta aktywowana'})
     })
  } catch (error) {
    setModalVersion(12)
     setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
      const errMessage = {
        occurDate: newTimeStamp,
        user: authedUser.currentUser.email,
        Actiontype:'deleteOrder',
        fbErrMsg:error.toLocaleString(),
    }
      set(PopUpModalRef(db, `errors/${new Date(errMessage.occurDate).toLocaleString('pl').slice(0,10).replace(/[.,#$[\]]/g,'')}/${errMessage.occurDate}`), errMessage)
  }
};

const deleteOrderPermanently = async () => {
    const storageRef = sRef(storage, `OrdersPictures/${detailedOrder.id}`);
     try {
     await set(PopUpModalRef(db, `orders/${detailedOrder.id}`), null)
     .then(() => {
      deleteObject(storageRef)
      setModalVersion(12)
      setModalInfo({title:'Oferta usunięta'})
      setRefresh(refresh + 2)
     })
     } catch (error) {
       setModalVersion(12)
        setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
         const errMessage = {
          occurDate: newTimeStamp,
          user: authedUser.currentUser.email,
          Actiontype:'deleteOrderPerm',
          fbErrMsg:error.toLocaleString(),
    }
      set(PopUpModalRef(db, `errors/${new Date(errMessage.occurDate).toLocaleString('pl').slice(0,10).replace(/[.,#$[\]]/g,'')}/${errMessage.occurDate}`), errMessage)
  }
};


const RefreshOffer = async () => {
  try {
    if (DetailedOffer.refreshes === 3 ) {
      await set(PopUpModalRef(db, `offers/${DetailedOffer.id}/refreshes`), 2)
      .then(() => {
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/addDate`), newTimeStamp)
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/visibility`), true)
        setModalVersion(13)
        setModalInfo({title: 'Odświeżenie wykorzystane'})
      })
    } else if (DetailedOffer.refreshes === 2) {
      await set(PopUpModalRef(db, `offers/${DetailedOffer.id}/refreshes`), 1)
      .then(() => {
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/addDate`), newTimeStamp)
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/visibility`), true)
        setModalVersion(13)
        setModalInfo({title: 'Odświeżenie wykorzystane'})
      })
    } else if (DetailedOffer.refreshes === 1) {
      await set(PopUpModalRef(db, `offers/${DetailedOffer.id}/refreshes`), 0)
      .then(() => {
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/addDate`), newTimeStamp)
        set(PopUpModalRef(db, `offers/${DetailedOffer.id}/visibility`), true)
        setModalVersion(13)
        setModalInfo({title: 'Odświeżenie wykorzystane'})
      })
    }
  } catch (error) {
    console.log(error)
  }
};


const EditOfferProceed = () => {
  history.push({
    pathname: '/EdycjaOgloszenia',
    state: {
        from: '/userPanel'
    }
  })
  setShowPopUpModal(false)
};

const OpenPhoneEditField = () => {
  setShowPopUpModal(true)
  setModalVersion(3)
  setModalInfo({title:'Uzupełnij numer telefonu'})
};

const OpenHomePageField = () => {
  setShowPopUpModal(true)
  setModalVersion(16) // dopasuj odpowieni modal
  setModalInfo({title:'Uzupełnij WWW Firmy'})
};

const OpenEditDescriptionField = () => {
  setShowPopUpModal(true)
  setModalVersion(4)
  setModalInfo({title: 'Uzupełnij Opis Firmy'})
};

const OpenAdressEditField = () => {
  setShowPopUpModal(true)
  setModalVersion(6)
  setModalInfo({title: 'Uzupełnij Adres'})
}

const OpenEditNipField = () => {
  setShowPopUpModal(true)
  setModalVersion(7)
  setModalInfo({title: 'Uzupełnij Nip'})
};

    return (
        <div id='SmallMainDiv'>
           { ModalVersion === 2 ? <ConfirmationModal /> : ''}
           { ModalVersion === 3 ? // EDYCJA NUMERU TELEFONU
                <CompanyPhoneNumberEdit 
                   setCompanyPhoneInputAlert={setCompanyPhoneInputAlert}
                   CompanyPhoneInput={CompanyPhoneInput}
                   CompanyPhonePrefix={CompanyPhonePrefix}
                   authedUser={authedUser}
                   PopUpModalRef={PopUpModalRef}
                   set={set}
                   db={db}
                   newTimeStamp={newTimeStamp}
                   setCompanyPhoneInput={setCompanyPhoneInput}
                   setCompanyPhonePrefix={setCompanyPhonePrefix}
                   Prefixes={Prefixes}
                   CompanyPhoneInputAlert={CompanyPhoneInputAlert}
                />
             : ''
           }
           { ModalVersion === 4 ? // EDYCJA OPISU
                <CompanyDescriptionEdit
                  db={db}
                  set={set}
                  PopUpModalRef={PopUpModalRef}
                  Description={Description}
                  setDescription={setDescription}
                />
               : ''
           }
           { ModalVersion === 5 ? <ConfirmationModal/> : ''}
           { ModalVersion === 6 ? // EDYCJA ADRESU
                 <CompanyAddressEdit
                   authedUser={authedUser}
                   db={db}
                   set={set}
                   PopUpModalRef={PopUpModalRef}
                   newTimeStamp={newTimeStamp}
                   setLocalNr={setLocalNr}
                   City={City}
                   setCity={setCity}
                   setStreet={setStreet}
                   setBuildingNr={setBuildingNr}
                   setZipCode={setZipCode}
                   Street={Street}
                   BuildingNr={BuildingNr}
                   LocalNr={LocalNr}
                   ZipCode={ZipCode}
                 />
              : ''
           }
           { ModalVersion === 7 ? // EDYCJA NIPU
              <CompanyNipNumberEdit 
                setNIPinfo={setNIPinfo}
                setNIP={setNIP}
                NIPinfo={NIPinfo}
                NIP={NIP}
                set={set}
                PopUpModalRef={PopUpModalRef}
                db={db}
                />
              : ''
           }
           { ModalVersion === 8 ? // ZAŁOŻENIE KONTA
              <UserRegister
                db={db}
                newTimeStamp={newTimeStamp}
                set={set}
                get={get}
                authedUser={authedUser}
                dbRef={dbRef}
                child={child}
                PopUpModalRef={PopUpModalRef}
                CompanyPhonePrefix={CompanyPhonePrefix}
                setCompanyPhoneInput={setCompanyPhoneInput}
                CompanyPhoneInput={CompanyPhoneInput}
                CompanyPhoneInputAlert={CompanyPhoneInputAlert}
                setCompanyPhoneInputAlert={setCompanyPhoneInputAlert}
                setCompanyPhonePrefix={setCompanyPhonePrefix}
                />
              : ''
           }
           { ModalVersion === 9 ? // ZAKOŃCZENIE OGŁOSZENIA (zmiana visibility na false)
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                       <h3 className='ModalMessage'>{DetailedOffer.title}</h3>
                         <h4 className='ModalMessage'>id: {DetailedOffer.id}</h4>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={EndOfferDisplayTime}>Tak</button>
                             <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 10 ? // ODŚWIEŻENIE OGŁOSZENIA
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                     <h3 className='ModalMessage'>{DetailedOffer.title}</h3>
                     <h5 className='ModalMessage'>id: {DetailedOffer.id}</h5>
                     <p className='ModalMessage'>pozostało odświeżeń: <strong>{DetailedOffer.refreshes}</strong></p>
                     <div id='DeleteConfirmDiv'>
                       <button className='ConfirmBtns' onClick={RefreshOffer}>Tak</button>
                       <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                     </div>
                 </div>
             : ''
           }
           {  ModalVersion === 12 ? <ConfirmationModal/> : ''}
           {  ModalVersion === 13 ? // Walidacja stawki // potwierdzenie odświeżenia
               <div id='EditUserFields'>
               <h4 className='PopUpModalTitle'>{ModalInfo.title}</h4>
                 <button className='ConfirmBtns' onClick={addConfirm}>OK</button>
             </div>
             : ''
           }
           { ModalVersion === 14 ? // WYBÓR LOKALIZACJI OFERT
               <ChooseOffersLocalization
                 setFilteredCountryName={setFilteredCountryName}
                 setCountryFilterCode={setCountryFilterCode}
                 filteredCountryName={filteredCountryName}
                 setCountryFilter={setCountryFilter}
                 setFilteredCityName={setFilteredCityName}
                 countryFilterCode={countryFilterCode}
                 filteredCityName={filteredCityName}
                 popularCountries={popularCountries}
                 popularCities={popularCities}
               />
             : ''
           }
           { ModalVersion === 15 ? <StatuteDisplay/> : ''}
           { ModalVersion === 16 ? // EDYCJA STRONY KLIENTA
               <CompanyWebSiteEdit
                 Website={Website}
                 setWebsite={setWebsite}
                 set={set}
                 PopUpModalRef={PopUpModalRef}
                 db={db}
                 />
              : ''
           }
           { ModalVersion === 17 ? // DODANIE LOGA FIRMY
               <AddCompanyLogo 
                   PopUpModalRef={PopUpModalRef}
                   setFileSizeAlert={setFileSizeAlert}
                   displayUploadProgressValue={displayUploadProgressValue}
                   fileSizeAlert={fileSizeAlert}
                   progresspercent={progresspercent}
                   setProgresspercent={setProgresspercent}
                   setImgUrl={setImgUrl}
                   db={db}
                   storage={storage}
                   set={set}
               />
              : '' 
           }
           { ModalVersion === 18 ? // USUNIĘCIE LOGA FIRMY
                <RemoveCompanyLogo 
                   sRef={sRef}
                   deleteObject={deleteObject}
                   set={set}
                   PopUpModalRef={PopUpModalRef}
                   db={db}
                />
              : ''
           }
           { ModalVersion === 20 ? // USUNIĘCIE OGŁOSZENIA
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                       <h3 className='ModalMessage'>{detailedOrder.orderTitle}</h3>
                         <h4 className='PopUpModalSubTitle'>id: {detailedOrder.id}</h4>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={deleteOrder}>Tak</button>
                             <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 21 ? // EDYCJA OGŁOSZENIA
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                       <h3 className='ModalMessage'>{DetailedOffer.title}</h3>
                         <h4 className='PopUpModalSubTitle'>id: {DetailedOffer.id}</h4>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={() => EditOfferProceed(DetailedOffer)}>Tak</button>
                             <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 22 ? // USUNIĘCIE OGŁOSZENIA - usunięcie trwałe
                 <DeleteOfferPermanently
                    db={db}
                    PopUpModalRef={PopUpModalRef}
                    set={set}
                    refresh={refresh}
                    setRefresh={setRefresh}
                 />
             : ''
           }
           { ModalVersion === 23 ? // Aktywacja OGŁOSZENIA
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                     <h3 className='ModalMessage'><strong>{DetailedOffer.title}</strong></h3>
                     <h5 className='ModalMessage'>Aktualna opcja ogłoszenia:</h5>
                     <h3><strong>{offerToActivateOption === 'basic' ? 'Podstawowe' : 'Premium'}</strong></h3>
                     <h5 className='ModalMessage'>Jeśli chcesz zmienić opcję oferty</h5>
                     <h5 className='ModalMessage'>Wybierz z poniższych:</h5>
                       <select name="Role" id='roleSelect' className='OfferDetailsInputs' value={awaitOffer.option} onChange={saveOfferToActivateOption}>
                                   {/* {OfferTypes.map(type => <option key={type.title} value={type.title}>{type.title}</option>)} */}
                                   <option key={'basic'} value={'basic'}>{'Podstawowe'}</option>
                                   <option key={'premium'} value={'premium'}>{'Premium'}</option>
                       </select>
                     { awaitOffer.option === 'premium' ? 
                        <h5 className='ModalMessage'>Posiadasz <strong>{LoggedUser.PremiumOffers}</strong> ofert Premium</h5>
                      :
                      ''
                     }
                     <div id='DeleteConfirmDiv'>
                      {/* dodaj wybór opcji który zmieni awaitOffer.option*/}
                        <ShowAddButtons
                          awaitOffer={awaitOffer}
                          setRefresh={setRefresh}
                          setDetailedOffer={setDetailedOffer}
                          setTitle={setTitle}
                          setExperience={setExperience}
                          setSelectedRole={setSelectedRole}
                          setAwaitOffer={setAwaitOffer}
                          setSelectedSpeciality={setSelectedSpeciality}
                          setCountry={setCountry}
                          setDescription={setDescription}
                          setMinSalary={setMinSalary}
                          setMaxSalary={setMaxSalary}
                        />
                       <button className='ConfirmBtns' onClick={dontSave}>Anuluj</button>
                     </div>
                 </div>
             : ''
           }
           { ModalVersion === 24 ? // Przełącznik powiadomień
               <NotificationsSwitch
                  set={set}
                  PopUpModalRef={PopUpModalRef}
                  db={db}
                  newTimeStamp={newTimeStamp}
                  authedUser={authedUser}
                 />
             : ''
           }
           { ModalVersion === 25 ? <ConfirmationModal/> : ''}
           { ModalVersion === 26 ? // wybór rodzaju danych do edycji w user Panel
                   <div id='EditUserFields'>
                     <h1 className='ModalMessage'>{ModalInfo.title}</h1>
                     <LogoUploader UserLogo={UserLogo} />
                      <div className='EditBtns' onClick={OpenPhoneEditField}>
                        <p>Numer Kontaktowy</p>
                        <AiOutlinePhone />
                      </div>
                      <div className='EditBtns' onClick={OpenHomePageField}>
                       <p>Adres WWW</p>
                       <CgWebsite />
                      </div>
                      <div className='EditBtns' onClick={OpenEditDescriptionField}>
                        <p>Opis Firmy</p>
                        <BsTextIndentLeft />
                      </div>
                      <div className='EditBtns' id='AdressEditButton' onClick={OpenAdressEditField}>
                        <p>Adres</p>
                        <FaMapMarkerAlt />
                      </div>
                      <div className='EditBtns' id='DescriptionEditButton' onClick={OpenEditNipField}>
                         <p>Numer NIP</p>
                         <BsFillFileEarmarkBinaryFill />
                      </div>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={dontSave}>Anuluj</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 27 ? // STAŁE USUNIĘCIE ZLECENIA
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                       <h3 className='ModalMessage'>{detailedOrder.orderTitle}</h3>
                         <h4 className='PopUpModalSubTitle'>id: {detailedOrder.id}</h4>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={deleteOrderPermanently}>Tak</button>
                             <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 28 ? // AKTYWACJA OFERTY
                   <div id='EditUserFields'>
                     <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                       <h3 className='ModalMessage'>{detailedOrder.orderTitle}</h3>
                         <h4 className='PopUpModalSubTitle'>id: {detailedOrder.id}</h4>
                           <div id='DeleteConfirmDiv'>
                             <button className='ConfirmBtns' onClick={activateOrder}>Tak</button>
                             <button className='ConfirmBtns' onClick={dontSave}>Nie</button>
                           </div>
                 </div>
             : ''
           }
           { ModalVersion === 29 ? // SMS lub TEL
                <SMSorCall/>
             : ''
           }
           { ModalVersion === 30 ? // Company Info view from offer details
                <SMSorCall/>
             : ''
           }
           { ModalVersion === 31 ? <SendEmailModal/> : ''}
           { ModalVersion === 32 ? 
            <WorkerJobSearchStatus
              set={set}
              PopUpModalRef={PopUpModalRef}
              db={db}
              newTimeStamp={newTimeStamp}
              authedUser={authedUser}
            /> : ''
            }
            {
              ModalVersion === 33 ? <AdditionalFiltersModal /> : ''
            }
            {
              ModalVersion === 34 ? <WorkerDataEditModal set={set} PopUpModalRef={PopUpModalRef} db={db}/> : ''
            }
            { ModalVersion === 35 ? <DisplayOfferAplications set={set} PopUpModalRef={PopUpModalRef} db={db}/> : ''}
            { ModalVersion === 36 ? <DisplayApplicationConfirmation /> : ''}
            { ModalVersion === 37 ? <WorkerOffersModal db={db} get={get} child={child} dbRef={dbRef} set={set} PopUpModalRef={PopUpModalRef}/> : ''}
            { ModalVersion === 38 ? <WorkerAplicationConfirmation dbRef={dbRef} authedUser={authedUser} db={db} set={set} get={get} child={child} setRefresh={setRefresh}/> : ''}
        </div>
    )
}

export default PopUpModal;
