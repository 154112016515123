import React from 'react';
import './PopularCountryButton.css';
import dataBase from '../../../config';
import { ref as PopUpModalRef, set, get, child} from "firebase/database";
import { useAtom } from 'jotai';
import { useShowPopUpModal } from '../../../data/GlobalStates';

const PopularCountryButton = ({country, setCountryFilter}) => {

    const dbRef = PopUpModalRef(dataBase);// Odczyt
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);

    const saveSearchedCountryStats = async (name) => { // do refactora zdublowana
        try {
          await get(child(dbRef, `popularCountries`))
          .then((countries) => {
            if (Object.keys(countries.val()).filter(country => country === name).length === 0) {
              set(PopUpModalRef(dataBase, `popularCountries/${name}/searches`), 1)
            } else if (Object.keys(countries.val()).filter(country => country === name).length === 1) {
              get(child(dbRef, `popularCountries/${name}/searches`))
              .then((value) => {
                set(PopUpModalRef(dataBase, `popularCountries/${name}/searches`), value.val() + 1)
              })
            }
          })
        } catch (error) {
          console.log(error)
        }
      };

      const handlePopularButtonClick = (name) => {
        setCountryFilter(name)
        saveSearchedCountryStats(name)
        setShowPopUpModal(false)
      };


  return (
    <button className='PopularCountryButton' onClick={() => handlePopularButtonClick(country.name)}>{country.name}</button>
  )
}

export default PopularCountryButton;