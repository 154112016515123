import React from 'react';
import './displayChoosedCity.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useAtom } from 'jotai';
import { useCityFilter } from '../../../data/GlobalStates';

const DisplayChoosedCity = () => {

  //stany globalne:
  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);

  const cleanCityFilter = () => {
   setCityFilter('')
  };

  return (
    <div className='choosedCityContainer' onClick={() => {cleanCityFilter()}}>
     <p>{CityFilter}</p>
     <AiOutlineCloseCircle className='filteredCountryTipIcon'/>
    </div>
  )
}

export default DisplayChoosedCity;