import React from 'react';
import './StatuteDisplay.css';
import Statute from '../../../Statute/Statute';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const StatuteDisplay = () => {

    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    return (
        <div id='StatuteDisplayMainContaner'>
           <Statute />
           <div id='ConfirmBtn' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>
             <h3 id='ConfirmBtnText'>Zamknij</h3>
           </div>
        </div>
    )
}

export default StatuteDisplay;
