
const OfferTypes = [
    {
        title: 'basic',
        price: 'brak opłat',
        displayTime: 15,
        refreshes: 0
    },
    {
        title: 'basic+',
        price: 39,
        displayTime: 15,
        refreshes: 1
    },
    {
        title: 'premium',
        price: 159,
        displayTime: 30,
        refreshes: 1
    },
    {
        title: 'PakietPremium3',
        price: 417
    },
    {
        title: 'PakietPremium5',
        price: 595
    },
    {
        title: 'PakietPremium10',
        price: 990
    }
]

export default OfferTypes;
