import React from 'react';
import './Stepper.css'
import { BsArrowRight } from 'react-icons/bs';
import { GiConfirmed } from 'react-icons/gi';
import { useAtom } from 'jotai';
import { useStepper, useChoosedOption } from '../../data/GlobalStates';

const StepsDisplayer = ({awaitOffer}) => {

    //stany globalne:
    const [ Stepper, setStepper ] = useAtom(useStepper);
    const [ ChoosedOption, setChoosedOption ] = useAtom(useChoosedOption);

    return (
          <div id='Stepper0Div'>
              <div className='allSteps'>
                <h1 className={Stepper === 0 ? 'activeStep' : 'stepNumber' }>1</h1>
                {ChoosedOption ? <GiConfirmed className='confirmedStep'/> : <div className='nextStepPlaceholder'></div>}
                {!ChoosedOption ? <p className={Stepper === 0 ? 'activeStepInfo' : 'stepInfo'}>Opcje</p> : <p className='stepInfo'>opcja: {ChoosedOption}</p>}
              </div>
                 <BsArrowRight className='stepsArrows'/>
              <div className='allSteps'>
                <h1 className={Stepper === 1 ? 'activeStep' : 'stepNumber' }>2</h1>
                { awaitOffer && awaitOffer.hasOwnProperty('title') ? <GiConfirmed className='confirmedStep1'/> : <div className='nextStepPlaceholder'></div>}
                <p className={Stepper === 1 ? 'activeStepInfo' : 'stepInfo'}>Szczegóły</p>
              </div>
                  <BsArrowRight className='stepsArrows'/>
              <div className='allSteps'>
                 <h1 className={Stepper === 2 ? 'activeStep' : 'stepNumber' }>3</h1>
                 <div className='nextStepPlaceholder'></div>
                 <p className={Stepper === 2 ? 'activeStepInfo' : 'stepInfo'}>Podsumowanie</p>
              </div>
        </div>

    )
}

export default StepsDisplayer;
