
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from '@stripe/stripe-js';
import './showChosingOption.css';
import { useAtom } from 'jotai';
import { useLoggedUser, useStepper, useChoosedOption, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../data/GlobalStates';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';


const ShowChosingOption = ({authedUser, OfferTypes, checkUser, setCheckUser}) => { // Wybór rodzaju ogłoszenia


    const history = useHistory(); 
    const functions = getFunctions();
    const LoadCheckOutFunction = httpsCallable(functions, 'stripeCheckOutLoad');
    const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHSTRIPE);

    const prodPriceTest = process.env.REACT_APP_PRODPRICELOWTEST;
    
    //stany globalne:
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ Stepper, setStepper ] = useAtom(useStepper);
    const [ ChoosedOption, setChoosedOption] = useAtom(useChoosedOption);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    //stany lokalne:
    const [ showAnimation, setShowAnimation] = useState(0);

    const Premium1 = process.env.REACT_APP_PREMIUMPACKAGE1; 
    const Premium2 = process.env.REACT_APP_PREMIUMPACKAGE2;
    const Premium3 = process.env.REACT_APP_PREMIUMPACKAGE3;

    const PremiumPackage1Price = process.env.REACT_APP_PREMIUMPACKAGE1PRICE;
    const PremiumPackage2Price = process.env.REACT_APP_PREMIUMPACKAGE2PRICE;
    const PremiumPackage3Price = process.env.REACT_APP_PREMIUMPACKAGE3PRICE;

    const chooseOption = (option) => {//wybiera rodzaj ogłoszenia z 1 kroku
        if (!LoggedUser && !authedUser.currentUser) {
            history.push('/userPanel')
        } else if ((option === 'basic+' || option === 'premium') && (!LoggedUser.NIP || !LoggedUser.City || !LoggedUser.Street || !LoggedUser.ZipCode )) {
            window.scrollTo(0, 0)
            setShowPopUpModal(true)
            setModalVersion(2)
            setModalInfo({title: 'Uzupełnij Dane', message: 'Przed dodaniem płatnego ogłoszenia uzupełnij NIP oraz Adres Firmy'})
            history.push('/userPanel')
        } else if (LoggedUser && authedUser.currentUser) {
            setChoosedOption(option)
            setStepper(1)
            setCheckUser(checkUser + 1)
            window.scrollTo(0, 0)
        }
      };

    const CalcutePremiumOffers = () => {
        if (LoggedUser.PremiumOffers === 1) {
         return 'ofertę'
        } else if (LoggedUser.PremiumOffers > 1 && LoggedUser.PremiumOffers < 5 ) {
         return 'oferty'
        } else if ( LoggedUser.PremiumOffers >= 5 ) {
         return 'ofert'
        }
    };

    const goToCheckOut = async (PackageName) => {

      const stripe = await stripePromise;

      const chooseProperPrice = () => {
        if (PackageName === Premium1) {
           return PremiumPackage1Price
        } else if (PackageName === Premium2) {
          return PremiumPackage2Price
        } else if (PackageName === Premium3) {
          return PremiumPackage3Price
        }
      };


      LoadCheckOutFunction({ ChoosedPackage: PackageName, price: chooseProperPrice(), User: LoggedUser.name, UserMail: LoggedUser.email, successURL: 'https://steeljobs.pl/Podsumowanie2' }) 
      .then( response => {
        if (response) {
          stripe.redirectToCheckout({sessionId: response.data.id})
        }
      }).catch(
        (error) => {
          setShowPopUpModal(true)
           setModalVersion(5)
            setModalInfo({title:'Ups nie można teraz dokonać płatności', message: error.message})
        }
      )
    };

    const buyOffersPackage = (PackageName, AnimNum) => {
      if (AnimNum === 'Anim1') {
        setShowAnimation(1)
      } else if (AnimNum === 'Anim2') {
        setShowAnimation(2)
      } else if (AnimNum === 'Anim3') {
        setShowAnimation(3)
      }
      goToCheckOut(PackageName)
    };

    return(
      <div id='AddOfferChooseDiv'>
        <div id='SamplesChooseDiv'>
        <h1 className='OffersTypeSectionHeader'>Pojedyńcze ogłoszenia:</h1>
         <div className='SampleContainer' onClick={() => chooseOption('basic')}>
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Ogłoszenie Podstawowe</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong id='GratisInfo'> Brak opłat</strong></p>
                  <p>- Czas wyświetlania <strong>{OfferTypes[0].displayTime}</strong> dni</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
          {LoggedUser.PremiumOffers > 0 ?
            <div className='SampleContainer' onClick={() => chooseOption('premium')}>
                  <div className='AddOfferInfoHeaderContainer'>
                    <h2 className='DisplayTypeHeader'>Ogłoszenie Premium</h2>
                      </div>
                       <div className='OfferTypeInfo'>
                          <p>W ramach wykupionego pakietu,</p> 
                          <p>Posiadasz <strong>{LoggedUser.PremiumOffers}</strong> {CalcutePremiumOffers()} Premium do wykorzystania</p> 
                          <p>- Czas wyświetlania <strong>{OfferTypes[2].displayTime}</strong> dni </p>
                          <p>- wyświetlanie przed ofertami podstawowymi</p>
                          <p>- <strong>wyróżnienie na liście ogłoszeń</strong></p>
                          <p>- <strong>wyróżnienie ikony oferty na mapie</strong></p>
                       </div>
                       <div className='IconsContainer'>
                           <BiWrench className='AddOfferIcons'/>
                           <GiAncientScrew className='AddOfferIcons'/>
                           <GiStoneWheel className='AddOfferIcons'/>
                           <GiBlacksmith className='AddOfferIcons'/>
                      </div>
            </div>
             :
          <div className='SampleContainer' onClick={() => chooseOption('premium')}>
            <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Ogłoszenie Premium</h2>
              </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[2].price}</strong> PLN + 23% Vat</p>
                  <p>- Czas wyświetlania <strong>{OfferTypes[2].displayTime}</strong> dni </p>
                  <p>- wyświetlanie przed ofertami podstawowymi</p>
                  <p>- <strong>wyróżnienie na liście ogłoszeń</strong></p>
                  <p>- <strong>wyróżnienie ikony oferty na mapie</strong></p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
              </div>
          </div>
          }
         </div>
         <div id='SamplesChooseDiv'>
           <h1 className='OffersTypeSectionHeader'>Pakiety ogłoszeń:</h1>
            <p className='ChoosingOptionInfo'>Oferty do wykorzystania w ramach pakietu dodawane są do konta automatycznie po zaksięgowaniu płatności.</p>
             <p className='ChoosingOptionInfo'>Czas na ich wykorzystanie jest nieograniczony.</p>
               <div className='SampleContainer' onClick={() => buyOffersPackage(Premium1, 'Anim1')}>
                { showAnimation === 1 ? <LoadingAnimation classVariant={'choosingOption'}/> : ''}
                 <div className='AddOfferInfoHeaderContainer'>
                  <h2 className='DisplayTypeHeader'>Pakiet 3 ofert premium</h2>
                 </div>
                 <div className='OfferTypeInfo'>
                   <p>- <strong>{OfferTypes[3].price}</strong> PLN + 23% Vat</p>
                   <p>- cena jednostkowa <strong>{OfferTypes[3].price/3}</strong> PLN + 23% Vat</p>
                 </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
          <div className='SampleContainer' onClick={() => buyOffersPackage(Premium2, 'Anim2')}>
            { showAnimation === 2 ? <LoadingAnimation classVariant={'choosingOption'}/> : ''}
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Pakiet 5 ofert premium</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[4].price}</strong> PLN + 23% Vat</p>
                  <p>- cena jednostkowa <strong>{OfferTypes[4].price/5}</strong> PLN + 23% Vat</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
          <div className='SampleContainer' onClick={() => buyOffersPackage(Premium3, 'Anim3')}>
            { showAnimation === 3 ? <LoadingAnimation classVariant={'choosingOption'}/> : ''}
             <div className='AddOfferInfoHeaderContainer'>
                <h2 className='DisplayTypeHeader'>Pakiet 10 ofert premium</h2>
             </div>
               <div className='OfferTypeInfo'>
                  <p>- <strong>{OfferTypes[5].price}</strong> PLN + 23% Vat</p>
                  <p>- cena jednostkowa <strong>{OfferTypes[5].price/10}</strong> PLN + 23% Vat</p>
               </div>
               <div className='IconsContainer'>
                   <BiWrench className='AddOfferIcons'/>
                   <GiAncientScrew className='AddOfferIcons'/>
                   <GiStoneWheel className='AddOfferIcons'/>
                   <GiBlacksmith className='AddOfferIcons'/>
               </div>
          </div>
         </div>
      </div>
    )
   };


   export default ShowChosingOption;