import { atom } from 'jotai';

export const useMainFilter = atom('');
export const useSecondFilter = atom('');
export const useSalaryFilter = atom(false);
export const useCurrencyFilter = atom('');
export const useExperienceFilter = atom('');
export const useLoggedUser = atom(false);
export const useDownloadedOffers = atom('');
export const useStepper = atom(0);
export const useOffersToDisplay = atom('');
export const useSearchInputValue = atom('');
export const useCountryFilter = atom('');
export const useRefreshUserInfo = atom('');
export const useCityFilter = atom('');
export const useSearchedCompany = atom('');
export const useDetailedOffer = atom('');
export const useStatuteAcceptation = atom(false);
export const useOpenedOfferIndex = atom(0);
export const useChoosedOption = atom('');
export const useShowPopUpModal = atom(false);
export const useModalVersion = atom(false);
export const useModalInfo = atom(false);
export const useShowDetailedCard = atom(0);
export const useMobileMapView = atom(false);
export const useAllCompanies = atom('');
export const useExpiredOffers = atom('');
export const useOfferApplications = atom('');
export const useCompaniesLogosUrls = atom('');
export const useApplyWithSJProfile = atom('');
export const useExternalApplyUrl = atom('');
export const useDetailed = atom('');
export const useCompanyUrlConstructor = atom('');









