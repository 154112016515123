import React from 'react';
import CloseModal from '../../CommonFunctions/closeModal';
import './WorkerJobSearchStatus.css';
import { IoIosClose } from 'react-icons/io';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo, useLoggedUser } from '../../../../data/GlobalStates';

const WorkerJobSearchStatus = ({set, PopUpModalRef, db, newTimeStamp, authedUser}) => {
 
    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ LoggedUser, setLoggedUser] = useAtom(useLoggedUser);

    const SwitchJobSearchStatus = async () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        if (LoggedUser.SearchingForJob === undefined || LoggedUser.SearchingForJob === false ) {
            try {
                await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/SearchingForJob`), true)
                await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/SearchingForJobDate`), newTimeStamp)
                .then(() => {
                 setModalVersion(12)
                 setModalInfo({title:'Zmiany zapisane'})
                })
             } catch (error) {
               setModalVersion(12)
               setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
               set(PopUpModalRef(db, `errors/${year}/${month}/${day}/SwitchJobSearchStatus`), error.message)
            }
        } else if (LoggedUser.SearchingForJob === true) {
            try {
                await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/SearchingForJob`), false)
                await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/SearchingForJobDate`), null)
                .then(() => {
                 setModalVersion(12)
                 setModalInfo({title:'Zmiany zapisane'})
                })
             } catch (error) {
               setModalVersion(12)
               setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
               set(PopUpModalRef(db, `errors/${year}/${month}/${day}/SwitchJobSearchStatus`), error.message)
            }
        }
    };


    return (
     <div id='ConfirmationModalMainContainer'>
        <div className='chooseOrCloseContainer'>
          <IoIosClose className='ClosePhoneOrSMSContainer' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}/>
        </div>
        <h2 className='PhoneOrSMSContactNumber'>Zmienić status ?</h2>
        {!LoggedUser.SearchingForJob ?
          <div className='JobSearchMessageBox'>
            <p className='JobStatusMessage'>Pracodawcy zarejestrowani na portalu:</p>
            <p>SteelJobs</p>
            <p className='JobStatusMessage'>Bedą mogli odnaleźć Twój profil</p>
            <p className='JobStatusMessage'>aby zaproponować Ci pracę.</p>
            <p className='JobStatusMessage'>Status po 10 dniach automatycznie</p>
            <p className='JobStatusMessage'>zmieni się na 'nie szukam'</p>
          </div>
          :
          <div className='JobSearchMessageBox'>
            <p className='JobStatusMessage'>Pracodawcy nie będą mogli odnaleźć</p>
            <p className='JobStatusMessage'>Twojego profilu</p>
          </div>
        }
     
        <div className='JobStatusConfirmationButtons'>
            <button className='ConfirmBtns' onClick={SwitchJobSearchStatus}>OK</button>
            <button className='ConfirmBtns' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
        </div>
     </div>
    )
}

export default WorkerJobSearchStatus;
