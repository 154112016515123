

const CloseModal = (setShowPopUpModal, setModalVersion, setModalInfo) => {
    setShowPopUpModal(false)
    setModalVersion(0)
    setModalInfo({})
  };
  
  export default CloseModal;
  
  