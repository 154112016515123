
 import { Overlay } from "googlemaps-react-primitives";
 import { useHistory } from 'react-router-dom';
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import './Marker.css';
import { useAtom } from "jotai";
import { useDetailedOffer } from "../../../data/GlobalStates";

 const Marker = ({position, offer}) => {

  const history = useHistory();
  const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);

  const openOfferDetails = (offer) => {
   setDetailedOffer(offer)
   history.push({
    pathname: `/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`,
    from: 'Map',
   // state: company.name
  })
  };

  const showCompanyLogo = (offer) => {
    if (offer.role === 'Monter') {
      return(<BiWrench className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Spawacz') {
      return (<GiCannonShot className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'CNC') {
      return(<CgArrowsShrinkH className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Ślusarz') {
      return (<GiAncientScrew className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Szlifierz') {
      return(<GiStoneWheel className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Kowal') {
      return(<GiBlacksmith className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Malarz') {
      return(<GiLargePaintBrush className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Brygadzista') {
      return(<RiTeamLine className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    } else if (offer.role === 'Inżynier') {
      return(<CgArrowsShrinkH className={offer.option === 'basic' ? 'MapIcon' : 'MapIconPremium'}/>)
    }
  };

  const chooseMarkerClass = (offer) => {
     if (offer.option === 'basic') {
      return 'MarkerMainContainer'
     } else if (offer.option === 'premium') {
      return 'PremiumMarkerMainContainer'
     }
  };

  return (
    <div>
       <Overlay position={{ lat: position.lat, lng: position.lng }}>
            <div className={chooseMarkerClass(offer)} onClick={() => openOfferDetails(offer)}> 
             <span className="cityTip">{offer.city}</span>
               <div className='LogoAndTextContainer'>
                 {showCompanyLogo(offer)}
                 <div className='TextContainer'>
                   <p className='MarkerTitle'>{offer.role || offer.orderCategory}</p>
                   <p className='MarkerDescription'>{offer.speciality}</p>
                 </div>
               </div>
            </div>
          </Overlay>
    </div>
  )
 };

 export default Marker;