import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './UserOfferDisplayCard.css';
import { motion } from "framer-motion";
import { GiLaserSparks } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import { BsPencilSquare } from 'react-icons/bs';
import OfferTypes from '../../../data/OfferTypes';
import countriesList from '../../../data/countriesList';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalInfo, useModalVersion, useOfferApplications } from '../../../data/GlobalStates';



const UserOfferDisplayCard = ({setCountryFilterCode, UserOffersDisplayType, setAwaitOffer, offer, setUserOffersDisplayType}) => {

  const history = useHistory();
  //stany globalne:
  const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ OfferApplications, setOfferApplications] = useAtom(useOfferApplications);


    const showCompanyLogo = (offer) => {
        if (offer.role === 'Monter') {
          return(<BiWrench className='UserOfferRoleLogo'/>)
        } else if (offer.role === 'Spawacz') {
          return (<GiLaserSparks className='UserOfferRoleLogo'/>)
        } else if (offer.role === 'CNC') {
          return(<CgArrowsShrinkH className='UserOfferRoleLogo'/>)
        } else if (offer.role === 'Ślusarz') {
          return (<GiAncientScrew className='UserOfferRoleLogo'/>)
        } else if (offer.role === 'Szlifierz') {
          return(<GiStoneWheel className='UserOfferRoleLogo'/>)
        } else if (offer.role === 'Kowal') {
          return(<GiBlacksmith className='UserOfferRoleLogo'/>)
        } else if(offer.role === 'Malarz') {
          return(<GiLargePaintBrush className='UserOfferRoleLogo'/>)
        } else if(offer.role === 'Brygadzista') {
          return(<RiTeamLine className='UserOfferRoleLogo'/>)
        }
      };

      const DeleteOffer = (offer) => {
        setDetailedOffer(offer)
        setShowPopUpModal(true)
        setModalVersion(9)
        setModalInfo({title: 'Zakończyć wyświetlanie oferty ?'})
        window.scrollTo(0,0)
        setUserOffersDisplayType(4)
      };

      const DeleteOfferPermanently = (offer) => { 
        setDetailedOffer(offer)
        setShowPopUpModal(true)
        setModalVersion(22)
        setModalInfo({title: 'Usunąć Ogłoszenie ?'})
        window.scrollTo(0,0)
      };

      const EditOffer = (offer) => {
        setAwaitOffer(offer)
        setCountryFilterCode(countriesList.filter(country => country.name_pl === offer.country)[0].code)
        setDetailedOffer(offer)
        setShowPopUpModal(true)
        setModalVersion(21)
        setModalInfo({title: 'Chcesz zmienić ogłoszenie ?'})
        window.scrollTo(0,0)
      };

      const RefreshOffer = (offer) => {
        setDetailedOffer(offer)
        setShowPopUpModal(true)
        setModalVersion(10)
        setModalInfo({title: 'Odświeżyć Ogłoszenie ?'})
        window.scrollTo(0,0)
      };

      const ActivateOffer = (offer) => {
        setAwaitOffer(offer)
        setShowPopUpModal(true)
        setModalVersion(23)
        setModalInfo({title: 'Aktywować Ogłoszenie ?'})
        window.scrollTo(0,0)
      };

        const switchOfferToDisplay = (offer) => {
          if (history.location.pathname === '/addOffer') {
            setDetailedOffer(offer) 
          } else if (history.location.pathname === '/addOfferInfo') {
            history.push('/login')
          } else {
            setDetailedOffer(offer)
           history.push({
            pathname:  `/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`,
            from: 'userPanel'
           }
           )
          }
        };

        const displayPaymentStatus = (offer) => {
           if (offer.paymentStatus === 'created') {
            return 'Rozpoczęta'
           } else if (offer.paymentStatus === 'failed') {
            return 'Odrzucona'
           } else if (offer.paymentStatus === 'canceled') {
            return 'Anulowana'
           } else if (offer.paymentStatus === 'processing') {
            return 'W trakcie przetwarzania'
           }
        };

        const displayPaymentDetails = (offer) => {
          setShowPopUpModal(true)
          setModalVersion(5)
          if (!offer.paymentFailInfo) {
            setModalInfo({title: 'Brak informacji o szczegółach płatności'})
          } else if (offer.paymentFailInfo === 'The code passed wasn’t a valid BLIK code.') {
            setModalInfo({title: 'Podano nieprawidłowy kod Blik'})
          }
        };

        const displayOfferAplications = () => {
          setShowPopUpModal(true)
          setModalVersion(35)
          setOfferApplications(Object.values(offer.applications))
        };

          const ActualDate = Date.now()// aktualna data w ms

          const DaysBeetwen  = ( date1, offer) => {
            const day =1000*60*60*24;
            const difference_ms = date1 - offer.addDate;
            const today = 'Dzisiaj'
            const yesterday = ' dzień temu'
            if (Math.round(difference_ms/day) === 0) {
              return today
            } else if (Math.round(difference_ms/day) === 1) {
              return Math.abs(Math.round(difference_ms/day)) + yesterday;
            }
            return Math.abs(Math.round(difference_ms/day)) + ' dni temu';
          };

          const DaysLeft  = ( date1, date2, option, paid) => {
            const day =1000*60*60*24;
            const difference_ms = date2 - date1;
            if (paid === false) {
              return 'status: oczekujące'
            } else if (option === 'basic') {
              return `aktywne jeszcze: ${Math.round(OfferTypes[0].displayTime + difference_ms/day)} dni`
            } else if (option === 'basic+') {
              return `aktywne jeszcze: ${Math.round(OfferTypes[1].displayTime + difference_ms/day)} dni`
            } else if (option === 'premium') {
              return `aktywne jeszcze: ${Math.round(OfferTypes[2].displayTime + difference_ms/day)} dni`
            }
          };

          const variants = {
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }

  const cuttedTitle = (title) => {
    if (title.length > 25) {
      return title.slice(0,25) + '...'
    } else if (title.length < 25) {
      return title
    }
  };

  const RedirectToPayment = (offer) => {
    window.location.href = offer.LastPaymentSessionUrl
  };

  const displayProperActionButtons = (offer) => {

   if (UserOffersDisplayType === 1) {
      return(
        <div id='BtnsDiv'>
        <div className='DeleteButton2' onClick={() => DeleteOffer(offer)}>
            <p>Zakończ</p>
            <AiOutlineDelete className='actionBtn'/>
        </div>
        <div className='RefreshButton' onClick={() => EditOffer(offer)}>
            <p>Edytuj</p>
            <BsPencilSquare className='actionBtn'/>
        </div>
      </div>
      )
    } else if (UserOffersDisplayType === 2) { // oferty oczekujące
      return (
        <div className='awaitingOffersButtons'>
          <div>
            {offer.paymentStatus ?
               <p className='paymentStatusInfo' onClick={() => displayPaymentDetails(offer)}>Status płatności: {displayPaymentStatus(offer)}</p>
               : ''
            }
          </div>
          {
            offer.LastPaymentSessionUrl ? 
            <div className='FinishPaymentDiv'>
              <p className='userOfferStats'>Jeśl nie udało ci się dokończyć płatności</p>
              <p className='userOfferStats'>Możesz to zrobić tutaj:</p>
              <div className='RetrieveButton' onClick={() => RedirectToPayment(offer)}>
                <p>Dokończ Płatność</p>
              </div>
            </div>  
             : ''
          }
          <div className='DeleteButton' onClick={() => DeleteOfferPermanently(offer)}>
            <p>Usuń</p>
            <AiOutlineDelete className='actionBtn'/>
          </div>
        </div>
      )
    } else if (UserOffersDisplayType === 4) {
      return (
        <div id='BtnsDiv'>
             <div className='RefreshButton' onClick={() => ActivateOffer(offer)}>
                  <p>Aktywuj</p>
                  <BiRefresh className='actionBtn'/>
              </div>
              <div className='DeleteButton' onClick={() => DeleteOfferPermanently(offer)}>
                 <p>Usuń</p>
                 <AiOutlineDelete className='actionBtn'/>
              </div>
        </div>
      )
    }
  };

  const displayApplications = () => {
    if (offer.applications) {
      return Object.values(offer.applications).length
    } else if (!offer.applications) return
  };



    return (
        <motion.div variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.4 }} className='UserOfferCardMainDiv' key ={offer.addDate}>
             <div className='userOfferCardLogo'>
               {showCompanyLogo(offer)}
             </div>
                <div className='DetailsMainDiv'>
                  <div className='userOfferTitlePriceId'>
                    <div className='userOfferTitleAndPrice' onClick={() => (switchOfferToDisplay(offer))}>
                       <p className='userOfferTitle'>{cuttedTitle(offer.title)}</p>
                         <div>
                            { offer.minSalary ? <p className='Salary'>{offer.minSalary} do {offer.maxSalary} {offer.currency}</p> : ''}
                            <p className='userOfferCountry'>{offer.country}</p>
                         </div>
                    </div>
                  <p className='userOfferId' onClick={() => (switchOfferToDisplay(offer))}>id: {offer.id}</p>
                  <p className='userOfferId' onClick={() => (switchOfferToDisplay(offer))}>kategoria: <strong>{offer.role}</strong></p>
                  <p className='userOfferId' onClick={() => (switchOfferToDisplay(offer))}>opcja: <strong>{offer.option === 'basic' ? 'Podstawowe' : 'Premium'}</strong></p>
                </div>
                        <div className='userOfferDatesDiv'>
                               <p className='userOfferDates'>dodano: {DaysBeetwen(ActualDate, offer)}</p>
                               {UserOffersDisplayType === 1 ?
                               <p className='userOfferDates'><strong>{DaysLeft(ActualDate, offer.addDate, offer.option, offer.paid)}</strong></p>
                               : ''
                               }
                        </div>
                               <div className='statsAndActions'>
                                   <div className='userOfferStatsDiv'>
                                     <p className='userOfferStats'>wyświetlenia kategorii: {offer.listViews}</p>
                                     <p className='userOfferStats'>wyświetlenia szczegółów: {offer.detailsViews}</p>
                                   </div>
                                   {offer.applications ? <p className='RefreshButton' onClick={() => displayOfferAplications()}>{`Aplikacje: ${displayApplications()}`}</p> : ''}
                                   <div className='Actions'>
                                        {displayProperActionButtons(offer)}
                                   </div>
                         </div>
                </div>
</motion.div>
    )
}

export default UserOfferDisplayCard;
