import React, {useEffect, useState} from 'react';
import './OfferDetailsDisplay.css'
import { useHistory } from 'react-router-dom';
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import { FiPhone } from 'react-icons/fi';
import { BsClockHistory } from 'react-icons/bs';
import { FaBuromobelexperte } from 'react-icons/fa';
import { GiFactory } from 'react-icons/gi';
import { IoIosCash } from 'react-icons/io';
import { BiMailSend } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { MdKeyboardArrowUp } from 'react-icons/md';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdArrowLeft } from 'react-icons/md';
import { ref as OffersDisplayStorageRef, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config';
import { increment } from 'firebase/database';
import { useAtom } from 'jotai';
import { getAuth } from "firebase/auth";
import { useDetailedOffer, useModalVersion, useShowPopUpModal, useShowDetailedCard, useAllCompanies, useLoggedUser, useModalInfo, useCompaniesLogosUrls, useDetailed } from '../../data/GlobalStates';


const OffersDetailsDisplay = ({get, db, child, dbRef, set, OfferDetailsRef, setRefresh}) => {

    const history = useHistory();
    const authedUser = getAuth();

    //stany lokalne:
    const [ DetailedOfferUserLogo, setDetailedOfferUserLogo ] = useState('');
    const [ ShowCompanyDetails, setShowCompanyDetails ] = useState(false);
    const [ CompanyDetails, setCompanyDetails ] = useState('');

    //stany globalne:
    const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
    const [ ModalVersion, setModalVersion ] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ ShowPopUpModal, setShowPopUpModal ] = useAtom(useShowPopUpModal);
    const [ ShowDetailedCard, setShowDetailedCard ] = useAtom(useShowDetailedCard);
    const [ AllCompanies, setAllCompanies ] = useAtom(useAllCompanies);
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ CompaniesLogosUrls, setCompaniesLogosUrls] = useAtom(useCompaniesLogosUrls);
    const [ detailed, setDetailed ] = useAtom(useDetailed);



    useEffect(() => {
      if (AllCompanies.length) {
         setCompanyDetails(AllCompanies.filter(company => company.name === detailed.companyName)[0])
      }
    },[AllCompanies])

    useEffect(() => {

      const scrollCompanyDescription = () => {
         const companyDescriptionContainer = document.getElementById('CompanyInfoDescriptionContainer')
         const title = document.getElementById('FirstDetails')
         
           if (ShowCompanyDetails) {
            companyDescriptionContainer.scrollIntoView({ behavior: 'smooth' })
         } else if (!ShowCompanyDetails) {
            title.scrollIntoView({ behavior: 'smooth' })
         }
       };
      
       scrollCompanyDescription()
       
    },[ShowCompanyDetails])

    useEffect(() => {
         if (detailed.CompanyLogo) {
           setDetailedOfferUserLogo(detailed.CompanyLogo)
           } else if (!detailed.CompanyLogo) {
            getDownloadURL(OffersDisplayStorageRef(storage, `CompaniesLogos/${detailed.companyName}`))
            .then((url) => {
              setDetailedOfferUserLogo(url)
            })
          }
          setRefresh(Math.floor(Math.random() * 98))
        },[CompaniesLogosUrls, detailed])


const closeModal = () => { 
    if (history.location.pathname === '/addOffer') {
      return
    } else if (history.location.from === 'userPanel') {
        history.push('/userPanel')
        setDetailedOffer('')
        setShowCompanyDetails(false)
    } else if (history.location.from === 'BazaFirm') {
        history.push({
           pathname: '/BazaFirm',
           state: history.location.state
        })
        setShowDetailedCard(detailed.companyName)
        setDetailedOffer('')
        setShowCompanyDetails(false)
    } else {
        history.push('/')
        setDetailedOffer('')
        setShowCompanyDetails(false)
    }
 };

 const showRoleIcon = () => {
   if (detailed.CompanyLogo) {
      return (
        <img src={detailed.CompanyLogo} className='DetailedUserRoleLogo'/>
      )
   } else if (DetailedOfferUserLogo) {
      return (
         <img src={DetailedOfferUserLogo} className='DetailedUserRoleLogo'/>
       )
   } else if (detailed.role === 'Spawacz') {
     return (
        <GiCannonShot className='DetailedOfferRoleLogo'/>
     )
    } else if (detailed.role === 'Monter') {
     return (
        <BiWrench className='DetailedOfferRoleLogo'/>
     )
    } else if (detailed.role === 'Ślusarz') {
       return (
        <GiAncientScrew className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'Szlifierz') {
     return (
        <GiStoneWheel className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'Kowal') {
     return (
        <GiBlacksmith className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'CNC') {
     return (
        <CgArrowsShrinkH className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'Malarz') {
     return (
        <GiLargePaintBrush className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'Brygadzista') {
     return (
        <RiTeamLine className='DetailedOfferRoleLogo'/>
       )
    } else if (detailed.role === 'Inżynier') {
      return (
         <CgArrowsShrinkH className='DetailedOfferRoleLogo'/>
        )
    }
 };

 const displaySalaryType = (offer) => {
   if (!detailed.salaryType) {
     return
   } else if (detailed.salaryType === 'Godzinowa') {
     return <p className='SalaryTypeShortCut'>/h</p>
   } else if (detailed.salaryType === 'Tygodniowa') {
     return <p className='SalaryTypeShortCut'>/tyg.</p>
   } else if (detailed.salaryType === 'Miesięczna') {
     return <p className='SalaryTypeShortCut'>/mies.</p>
   }
 };

 const openSMSorCallModal = () => {
     setShowPopUpModal(true)
     setModalVersion(29)
     setDetailedOffer(detailed)
     phoneDiscoverCounter()
 };

 const openSendEmailModal = () => {
   setShowPopUpModal(true)
   setModalVersion(31)
   setDetailedOffer(detailed)
   countMailClientsOpens()
};

const countMailClientsOpens = async () => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;

  try {
    await get(child(dbRef, `stats/mailReveals/${year}/${month}/mailReveals`))
    .then((statValue) => {
      if ( statValue.val() === null) {
        set(OfferDetailsRef(db, `stats/mailReveals/${year}/${month}/mailReveals`), 1)
      } else if (statValue.val()) {
        set(OfferDetailsRef(db, `stats/mailReveals/${year}/${month}/mailReveals`), increment(1))
      }
    })
   
  } catch (error) {
    console.log(error)
  }
   
};

const saveUnregisterApplyStats = async (data) => {
   const date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth() + 1;
   try {
     await get(child(dbRef, `stats/unregisterUserApplyClick/${year}/${month}/clicks`))
     .then((statValue) => {
       if ( statValue.val() === null) {
         set(OfferDetailsRef(db, `stats/unregisterUserApplyClick/${year}/${month}/clicks`), 1)
       } else if (statValue.val()) {
         set(OfferDetailsRef(db, `stats/unregisterUserApplyClick/${year}/${month}/clicks`), increment(1))
       }
     })
    
   } catch (error) {
     console.log(error)
   }
 };

 const OpenAplicationConfirmation = () => {
  setShowPopUpModal(true)
   setModalVersion(38)
    setModalInfo({title:'Aplikacja profilem pracownika'})
 };


 const showApplyButton = () => {
  if (authedUser.currentUser && LoggedUser.AccountType === 'Private' && detailed.applyWithSJProfile === 'Tak' && !detailed.externalApplyLink) {
    return (
       <div id='CompanyInfoMiniLogoContainer'>
         <p className='ApplyRegisterButtonText' onClick={() => OpenAplicationConfirmation()}>Aplikuj</p>
       </div>
    )
  } else if (authedUser.currentUser && LoggedUser.AccountType === 'Private' && !detailed.applyWithSJProfile && detailed.externalApplyLink) {
     return (
      <div id='CompanyInfoMiniLogoContainer'>
       <a href={detailed.externalApplyLink} className='ApplyRegisterButtonText'>Aplikuj</a>
      </div>
     )
  } else if (authedUser.currentUser && LoggedUser.AccountType === 'Private' && detailed.applyWithSJProfile === 'Tak' && detailed.externalApplyLink) {
    return (
      <div id='CompanyInfoMiniLogoContainer'>
       <p className='ApplyRegisterButtonText' onClick={() => OpenAplicationConfirmation()}>Aplikuj Profilem SteelJobs</p>
       <a href={detailed.externalApplyLink} className='ApplyRegisterButtonText'>Formularz zewnętrzny</a>
     </div>
    )
  } else if (authedUser.currentUser && LoggedUser.AccountType === 'Private' && !detailed.applyWithSJProfile && !detailed.externalApplyLink) {
    return (
      <></>
    )
  } else if (!authedUser.currentUser && detailed.applyWithSJProfile === 'Tak' || detailed.externalApplyLink) {
    return (
      <Link to={'/ZarejestrujSie'} onClick={() => saveUnregisterApplyStats()} id='CompanyInfoMiniLogoContainer'>
        <p className='ApplyRegisterButtonText'>Aplikuj</p>
      </Link>
)
  } else {
    return (
      <></>
    )
  }
 };


 const openCompanyInfoModal = () => {
     setShowCompanyDetails(!ShowCompanyDetails)
 };

 const phoneDiscoverCounter = async () => {

   const date = new Date();
   let year = date.getFullYear();
   let month = date.getMonth() + 1;

   try {
    await get(child(dbRef, `stats/phoneReveals/${year}/${month}/phoneReveals`))
    .then((statValue) => {
      if ( statValue.val() === null) {
        set(OfferDetailsRef(db, `stats/phoneReveals/${year}/${month}/phoneReveals`), 1)
      } else if (statValue.val()) {
        set(OfferDetailsRef(db, `stats/phoneReveals/${year}/${month}/phoneReveals`), increment(1))
      }
    })
   
  } catch (error) {
    console.log(error)
  }
};

const displayCompanyDescription = () =>  {
return (
    <div id='CompanyInfoDescriptionContainer'>
      <p>{CompanyDetails.Description}</p>
    </div>
)
};

const displayCompanyInfoButtonSwitch = () => {
   if (CompanyDetails.Description && !ShowCompanyDetails) {
      return (
         <div id='CompanyInfoContainer'>
            <button className='OfferDetailsShowCompanyDetailsBtn' onClick={() => openCompanyInfoModal()}>
              <p>Informacje o firmie</p>
              <MdKeyboardArrowUp />
            </button>    
         </div>
      )
   } else if (CompanyDetails.Description && ShowCompanyDetails) {
      return (
         <div id='CompanyInfoContainer'>
           <button className='OfferDetailsShowCompanyDetailsBtn' onClick={() => openCompanyInfoModal()}>
             <p>Informacje o firmie</p>
             <MdKeyboardArrowDown />
           </button>
         </div>
      )
   } else if (!CompanyDetails.Description) {
      return
   }
};

        return(
            <div id='OfferDetailsDiv'>
                    <Helmet>
                         <meta name="description" content={detailed.role.toString()}/>
                         <title>{detailed.title.slice(0,60).toString()}</title>
                         <meta name="description" content="Oferty pracy dla spawaczy, monterów, operatorów CNC" />
                         <meta name="description" content="stoczniowcy, spawalnictwo" />
                         <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/chat-app-9fa6b.appspot.com/o/SiteLogos%2FMiniLogo.png?alt=media&token=94564b1d-4339-4a63-86a5-1410ea8adf73"/>
                         <meta property="og:url" content='https://SteelJobs.pl'/>
                    </Helmet>
               <div id='FirstDetails'>
                  <div id='TitleDiv'>
                    { detailed.title ?  <h2 id='DetailedOfferTitle'>{detailed.title}</h2> : ''}
                  </div>
                  {showRoleIcon()}
                  <div className='BackBtnLogoAndCountryContainer'>
                     <MdArrowLeft className='BackToMainBtn' onClick={() => closeModal()}/>
                      <div className='offerDetailsCountryAndTitleContainer'>
                        <h3 id='CountryTitle'>{detailed.country}</h3>
                        <h3 id='CityName'>{detailed.city}</h3>
                      </div>
                  </div>
               </div>
               <div id='DetailsDiv1'>
                     <div className='DetailContainer'>
                        <GiFactory id='detailedOfferFactoryLogo' className='DetailedContanerLogos'/>
                        <h4 className='DetailContainerText'>Firma: </h4>
                        <h3 className='DetailContainerText'>{detailed.companyName}</h3>
                     </div>
                     <div className='DetailContainer'>
                        <FaBuromobelexperte id='detailedOfferExperienceLogo' className='DetailedContanerLogos'/>
                        <p className='DetailContainerText'>doświadczenie: </p>
                        <p className='DetailContainerText'>{detailed.experience}</p>
                     </div>
                     <div className='DetailContainer'>
                        <BsClockHistory id='detailedOfferAddDateLogo' className='DetailedContanerLogos'/>
                        <p className='DetailContainerText'>dodane: {new Date(detailed.addDate).toLocaleDateString()}</p>
                     </div>
                        <div className='DetailContainer'>
                           <IoIosCash id='detailedOfferSalaryLogo' className='DetailedContanerLogos'/>
                           {detailed.minSalary && !detailed.maxSalary ? <h3 className='DetailContainerText'>od: {detailed.minSalary} {detailed.currency} {displaySalaryType()}</h3> : ''}
                           {detailed.minSalary && detailed.maxSalary ? <h3 className='DetailContainerText'>od: {detailed.minSalary} do: {detailed.maxSalary} {detailed.currency} {displaySalaryType()}</h3> : ''}
                           {!detailed.minSalary && detailed.maxSalary ? <h3 className='DetailContainerText'>do: {detailed.maxSalary} {detailed.currency} {displaySalaryType()}</h3> : ''}
                           {!detailed.minSalary && !detailed.maxSalary ? <h3 className='DetailContainerText'>nie podano</h3> : ''}
                        </div>
                     </div>
               <div id='DescriptionContainer'>
                 <h2 id='DescriptionTitle'>Szczegóły oferty:</h2>
                 <h3 id='Description'><pre id='FormattedText'>{detailed.description}</pre></h3>
               </div>
               <div id='ContactDiv'>
                  <div className='OfferDetailsContactsContainers' onClick={() => openSMSorCallModal()}>
                     <FiPhone className='OfferDetailsContactIcons'/>
                     <button id='OfferDetailPhoneNumber'>Pokaż numer</button>
                  </div>
                  <a className='OfferDetailsContactsContainers' onClick={() => openSendEmailModal()}>
                    <BiMailSend className='OfferDetailsContactIcons'/>
                    <button id='OfferDetailPhoneNumber'>e-mail</button> 
                  </a>
               </div>
                 {displayCompanyInfoButtonSwitch()}
                 {ShowCompanyDetails ? displayCompanyDescription() : <span></span>}
                 {showApplyButton()}
               <div id='CompanyInfoMiniLogoContainer' onClick={() => closeModal()}>
                 <p className='OfferDetailsDisplayMiniLogo'>SteelJobs.pl</p>
               </div>
           </div>
       )
}

export default OffersDetailsDisplay;
