import {getDatabase, onValue, ref as userSyncRef} from "firebase/database";


const UserSyncWithFireBase = (authedUser, setModalInfo, setModalVersion, setShowPopUpModal, setLoggedUser) => {

  const db = getDatabase();
  const response = userSyncRef(db, 'users');

  return onValue(response, (snap) => {
    if (snap.exists()) {
      const data = snap.val()

      if (!authedUser.currentUser) { // niezalogowany
        setLoggedUser('')
        return
    } else if (authedUser.currentUser.emailVerified === false && Object.values(data).filter(i => i.email === authedUser.currentUser.email).length === 0) {
         setModalInfo({title: 'Proszę uzupełnić podstawowe dane'}) // założenie konta
          setModalVersion(8)
           setShowPopUpModal(true)
           return
    } else if (authedUser.currentUser.emailVerified === true && Object.values(data).filter(i => i.email === authedUser.currentUser.email).length) {
        setLoggedUser(Object.values(data).filter(i => i.email === authedUser.currentUser.email)[0]) // zalogowany zweryfikowany - pobranie danych z FB (opis etc.)
        return
    } else if (authedUser.currentUser.emailVerified === true && Object.values(data).filter(i => i.email === authedUser.currentUser.email).length === 0) {
        setModalInfo({title: 'Proszę uzupełnić podstawowe dane'}) // założenie konta
          setModalVersion(8)
           setShowPopUpModal(true)
           return
    }
    }
  })
};

export default UserSyncWithFireBase;


//Stany usera:

//3. Wylogowany - authedUser === null - wyczyszczenie stanu loggedUser

//1. Zalogowany niezweryfikowany - authedUser.currentUser.emailVerified === false - konto powstaje w FB ze statusem niezweryfikowany

//2. Zalogowany zweryfikowany - authedUser.currentUser.emailVerified === true - synchronizacja danych z FB ze zmianą stanu veryfied na true

//4. Zalogowany, mail zweryfikowany ale nie uzupełnił podstawowego info - brak loggedUser - dokończenie rejestracji

//5.wejście w userPanel bez logowania,  authedUser === null

