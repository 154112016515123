import React from 'react';
import './displayChoosedCountry.css'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useAtom } from 'jotai';
import { useCountryFilter, useCityFilter } from '../../../data/GlobalStates';


const DisplayChoosedCountry = ({setCountryFilterCode}) => {

    //stany globalne:
    const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
    const [ CityFilter, setCityFilter] = useAtom(useCityFilter);

    const cleanCountryFilter = () => {
        setCountryFilter('')
        setCountryFilterCode('')
        setCityFilter('')
    };


  return (
    <div className='choosedCountryContainer' onClick={() => cleanCountryFilter()}>
     <p>{CountryFilter}</p>
     <AiOutlineCloseCircle className='filteredCountryTipIcon'/>
    </div>
  )
}

export default DisplayChoosedCountry;