import React from 'react';
import './LogoUploader.css';
import { ImProfile } from 'react-icons/im';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalInfo, useModalVersion } from '../../../data/GlobalStates';

const LogoUploader = ({ UserLogo }) => {
 
      const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
      const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
      const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);

      const removeLogo = () => {
         setShowPopUpModal(true)
         setModalInfo({title: 'Usunąć Logo ?'})
         setModalVersion(18)
      };

      const addLogo = () => {
        setShowPopUpModal(true)
        setModalInfo({title: 'Dodaj Logo Firmy'})
        setModalVersion(17)
      };

  return (
    <div>
        {UserLogo ?
        <div className='EditBtns' onClick={removeLogo}>
         <p>Usuń Logo</p>
         <ImProfile />
        </div>
        :
        <div className='EditBtns' onClick={addLogo}>
          <p>Dodaj Logo</p>
          <ImProfile />
        </div>
        }
    </div>
  )
}

export default LogoUploader;
