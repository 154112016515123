import React from 'react';
import './CompanyNipNumberEdit.css';
import selectUserDataToEdit from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';


const CompanyNipNumberEdit = ({setNIPinfo, setNIP, NIPinfo, NIP, set, PopUpModalRef, db}) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const setNIPval = (e) => {

        if (e.target.value.length <= 8) {
          setNIPinfo('Nip powinien mieć co najmniej 9 znaków')
        } else if (e.target.value.length > 8) {
          setNIPinfo('OK')
          setNIP(e.target.value)
        }
     };

     const saveNIP = async () => {
      
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        try {
          if (NIP.length >= 9 && LoggedUser) {
            await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/NIP`), NIP)
              .then(() => {
                 setModalVersion(12)
                   setModalInfo({title:'Zmiany zapisane'})
              })
          }
        } catch (error) {
             setModalVersion(12)
              setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
                set(PopUpModalRef(db, `errors/${year}/${month}/${day}/saveNIP`), error.message)
        }
      };



    return (
       <div id='EditUserFields'>
           <h1 className='ModalTitle'>{ModalInfo.title}</h1>
               { NIPinfo ? <p id='Hint'>{NIPinfo}</p> : ''}
                 <input className='EditInputs' placeholder={LoggedUser.NIP || 'wpisz numer NIP'} onChange={setNIPval}></input>
           <div className='CompanyNipEditBtnsContainer'>
                <button className='ConfirmBtns' onClick={saveNIP}>OK</button>
                <button className='ConfirmBtns' onClick={() => selectUserDataToEdit(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
           </div>
       </div>
    )
}

export default CompanyNipNumberEdit;
