
const Specialities = {
    Monter: ['Wybierz', 'Rurociągi', 'Konstrukcje', 'Wentylacje', 'Ermeto', 'Kadłuby', 'Laminaty', 'Stolarka'],
    Spawacz: ['Wybierz', '111','114','121','131', '141', '135', '136', '137', '138', '311', 'Robot Spawalniczy', 'Spawanie Orbitalne'],
    OperatorCNC : ['Wybierz', 'Prasa', 'Laser', 'Frezarka', 'Tokarka', 'Programista'],
    Inżynier: ['Wybierz', 'Procesu', 'Spawalnik'],
    Years: ['Wybierz', '0-1', '2-3', '3-5', '5-10', 'powyżej 10']
}

export default Specialities;
