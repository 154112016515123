import React, {useEffect, useState} from 'react';
import './Stats.css';
import { ref as CompaniesRef, child, get } from "firebase/database";
import dataBase from '../../config'; 
import { useAtom } from 'jotai';
import { useOffersToDisplay } from '../../data/GlobalStates';
import { getFunctions, httpsCallable } from "firebase/functions";

const Stats = () => {

   //stany lokalne:

    const [companiesNum, setCompaniesNum ] = useState(0)
    const [WorkersNum, setWorkersNum ] = useState(0)
    const [lastRegisteredCompany, setLastRegisteredCompany ] = useState(0)
    const [prelastRegisteredCompany, setPrelastRegisteredCompany ] = useState(0)
    const [pre2RegisteredCompany, setPre2RegisteredCompany ] = useState(0)
    const [phoneClicksThisMonth, setPhoneClicksThisMonth ] = useState(0)
    const [phoneClicksLastMonth, setPhoneClicksLastMonth ] = useState(0)
    const [emailClicksThisMonth, setEmailClicksThisMonth ] = useState(0)
    const [emailClicksLastMonth, setEmailClicksLastMonth ] = useState(0)
    const [detailsClicksThisMonth, setDetailsClicksThisMonth ] = useState(0)
    const [detailsClicksLastMonth, setDetailsClicksLastMonth ] = useState(0)
    const [offersAddedLastMonth, setOffersAddedLastMonth ] = useState(0)
    const [OffersAddedThisMonth, setOffersAddedThisMonth ] = useState(0)
    const [WorkerOffersModalThisMonth, setWorkerOffersModalThisMonth ] = useState(0)
    const [pass, setPass ] = useState('?')

    // stany globalne
    const [ OffersToDisplay, setOffersToDisplay ] = useAtom(useOffersToDisplay);

    const dbRef = CompaniesRef(dataBase);
    const masterPass = process.env.REACT_APP_STATSPASSW;
    const functions = getFunctions();
    const SendMailFunction = httpsCallable(functions, 'SendMassMail');

    const SendMassMailF = async () => {

      try {
        const companies = await get(child(dbRef, `users`))
        const companiesArray = Object.values(companies.val())
        SendMailFunction({companies: companiesArray})
      }
      catch (error) {
        console.log(error)
      } 
    };


    const DownloadCompanies = async () => {

      const sortArrayOfObjects = (arr, propertyName, order = 'descending') => {
        const sortedArr = arr.sort((a, b) => {
          if (a[propertyName] < b[propertyName]) {
            return -1;
          }
          if (a[propertyName] > b[propertyName]) {
            return 1;
          }
          return 0;
        });

        if (order === 'descending') {
          return sortedArr.reverse();
        }
      
        return sortedArr;
      };

        try {
          const companies = await get(child(dbRef, `users`))
          const companiesWithDate = []
          setCompaniesNum(Object.values(companies.val()).filter(company => company.AccountType !== 'Private'))
          setWorkersNum(Object.values(companies.val()).filter(company => company.AccountType == 'Private'))

          for (let i in companiesNum) {
            if (companiesNum[i].AccountCreated) {
              companiesWithDate.push(companiesNum[i])
            }
          }    

          setLastRegisteredCompany(sortArrayOfObjects(companiesWithDate, 'AccountCreated')[0])
          setPrelastRegisteredCompany(sortArrayOfObjects(companiesWithDate, 'AccountCreated')[1])
          setPre2RegisteredCompany(sortArrayOfObjects(companiesWithDate, 'AccountCreated')[2])
  
        }
        catch (error) {
          console.log(error)
        } 
      };



      const updateOffersClicksThisMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        try {
          await get(child(dbRef, `stats/offersDetailsActions/${year}/${month}`))
          .then((val) => {
            setDetailsClicksThisMonth(val.val().detailsClicks)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updateWorkerOffersClicksThisMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        try {
          await get(child(dbRef, `stats/workerOffersModal/${year}/${month}`))
          .then((val) => {
            setWorkerOffersModalThisMonth(val.val().clicks)
          })
        } catch (error) {
          console.log(error)
        }
      };


      const updateOffersClicksLastMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() ;
        try {
          await get(child(dbRef, `stats/offersDetailsActions/${year}/${month}`))
          .then((val) => {
            setDetailsClicksLastMonth(val.val().detailsClicks)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updateAddedOffersLastMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() ;
        let offers = 0
        try {
          await get(child(dbRef, `stats/offersMonthlyCounter/${year}/${month}`))
          .then((val) => {
             let offersFromLastMonth = Object.values(val.val())
            for (let i in offersFromLastMonth) {
              offers = offers + offersFromLastMonth[i].offers
            }
            setOffersAddedLastMonth(offers)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updateAddedOffersThisMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let offers = 0
        try {
          await get(child(dbRef, `stats/offersMonthlyCounter/${year}/${month}`))
          .then((val) => {
             let offersFromLastMonth = Object.values(val.val())
            for (let i in offersFromLastMonth) {
              offers = offers + offersFromLastMonth[i].offers
            }
            setOffersAddedThisMonth(offers)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updatePhoneClicksThisMonth = async () => {

        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;

        try {
          await get(child(dbRef, `stats/phoneReveals/${year}/${month}`))
          .then((val) => {
            setPhoneClicksThisMonth(val.val().phoneReveals)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updatePhoneClicksLastMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        try {
          await get(child(dbRef, `stats/phoneReveals/${year}/${month}`))
          .then((val) => {
            setPhoneClicksLastMonth(val.val().phoneReveals)
          })
        } catch (error) {
          console.log(error)
        }
      };


      const updateMailClicksThisMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        try {
          await get(child(dbRef, `stats/mailReveals/${year}/${month}`))
          .then((val) => {
            setEmailClicksThisMonth(val.val().mailReveals)
          })
        } catch (error) {
          console.log(error)
        }
      };

      const updateMailClicksLastMonth = async () => {
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        try {
          await get(child(dbRef, `stats/mailReveals/${year}/${month}`))
          .then((val) => {
            setEmailClicksLastMonth(val.val().mailReveals)
          })
        } catch (error) {
          console.log(error)
        }
      };


      useEffect(() => {
         DownloadCompanies()
         updateOffersClicksThisMonth()
         updateOffersClicksLastMonth()
         updateAddedOffersLastMonth()
         updateAddedOffersThisMonth()
         updateWorkerOffersClicksThisMonth()
         updatePhoneClicksThisMonth()
         updatePhoneClicksLastMonth()
         updateMailClicksThisMonth()
         updateMailClicksLastMonth()
      }, [pass])

      const updatePass = (e) => {
         setPass(e.target.value)
      };

    return (
      <div id='statsMainContainer'>
        {pass === masterPass ?
         <div>
            <div className='sectionContainer'>
              <p className='statsItems'>{`Companies : ${companiesNum.length}`}</p>
               <p className='statsItems'>{`Workers : ${WorkersNum.length}`}</p>
                <p className='statsItems'>{`Lately registered:`}</p>
                 {lastRegisteredCompany ? <p className='statsItemsHighLighted'>{`${lastRegisteredCompany.name}`}</p> : ''}
                  {lastRegisteredCompany ? <p className='statsItemsSmall'>{`${new Date(lastRegisteredCompany.AccountCreated).toString().slice(0,21)}`}</p> : ''}
                   {prelastRegisteredCompany ? <p className='statsItemsHighLighted'>{`${prelastRegisteredCompany.name}`}</p> : ''}
                    {prelastRegisteredCompany ? <p className='statsItemsSmall'>{`${new Date(prelastRegisteredCompany.AccountCreated).toString().slice(0,21)}`}</p> : ''}
                     {pre2RegisteredCompany ? <p className='statsItemsHighLighted'>{`${pre2RegisteredCompany.name}`}</p> : ''}
                      {pre2RegisteredCompany ? <p className='statsItemsSmall'>{`${new Date(pre2RegisteredCompany.AccountCreated).toString().slice(0,21)}`}</p> : ''}
            </div>
            <div className='sectionContainer'>
             <p className='statsItems'>{`Offers:`}</p>
              <p className='statsItems'>{`Active: ${OffersToDisplay.length}`}</p>
               <p className='statsItems'>{`Current month: ${OffersAddedThisMonth}`}</p>
                <p className='statsItems'>{`Last month: ${offersAddedLastMonth}`}</p>
            </div>
            <div className='sectionContainer'>
             <p className='statsItems'>{`Actions:`}</p>
              <p className='statsItems'>{`Details opens this month : ${detailsClicksThisMonth}`}</p>
               <p className='statsItems'>{`Details opens last month  : ${detailsClicksLastMonth}`}</p>
                <p className='statsItems'>{`Workers modal clicks: ${WorkerOffersModalThisMonth}`}</p>
                 <p className='statsItems'>{`Phone clicks this month: ${phoneClicksThisMonth}`}</p>
                  <p className='statsItems'>{`Phone clicks last month : ${phoneClicksLastMonth}`}</p>
                   <p className='statsItems'>{`Mail clicks this month : ${emailClicksThisMonth}`}</p>
                    <p className='statsItems'>{`Mail clicks last month  : ${emailClicksLastMonth}`}</p>
            </div>
            <button onClick={() => {SendMassMailF()}}>test</button>
         </div>
      :
         <input className='SalaryInputs' placeholder='0' onChange={updatePass} value={pass}></input>
      }
     </div>
    )
}

export default Stats;
