import OfferTypes from '../../../data/OfferTypes';
import PopUpModal from '../../PopUpModal/PopUpModal';
import ShowAddButtons from '../AddButtons/AddButtons';
import './ShowPayment.css';
import { useAtom } from 'jotai';
import { useStatuteAcceptation, useChoosedOption, useShowPopUpModal, useModalVersion, useLoggedUser } from '../../../data/GlobalStates';

const ShowPayment = ({awaitOffer, refresh, setRefresh, stepBack, setTitle, setExperience, setSelectedRole, setSelectedSpeciality, setCountry, setDescription, setMinSalary, setMaxSalary, setAwaitOffer}) => { // Krok 3 płatność


    const taxRate = process.env.REACT_APP_TAXRATE;
    //stany globalne:
    const [ StatuteAcceptation, setStatuteAcceptation] = useAtom(useStatuteAcceptation);
    const [ ChoosedOption, setChoosedOption] = useAtom(useChoosedOption);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);

  const switchStatuteAccept = () => {
    setStatuteAcceptation(!StatuteAcceptation)
  };

  const CalcutePremiumOffers = () => {
    if (LoggedUser.PremiumOffers === 1) {
     return 'ofertę'
    } else if (LoggedUser.PremiumOffers > 1 && LoggedUser.PremiumOffers < 5 ) {
     return 'oferty'
    } else if ( LoggedUser.PremiumOffers >= 5 ) {
     return 'ofert'
    }
};

  const checkIsItChecked = (country) => {
    if (StatuteAcceptation) {
      return true
    } else return false
  };

  const showStatute = () => {
    setShowPopUpModal(true)
    setModalVersion(15)
  };

    const showChoosedOption = () => {
     if (ChoosedOption === 'basic') {
      return 'Podstawowe'
     } else if (ChoosedOption === 'premium') {
      return 'Premium'
     }
    };

    const showChoosedOfferDetails = () => {
        if (ChoosedOption === 'basic') {
          return (
            <div id='ChoosedOfferDetails'>
              <h4 id='priceInfoParagraph' className='choosedOfferDescription'>- {OfferTypes[0].price}</h4>
              <h4 className='choosedOfferDescription'>Czas wyświetlania: <strong>{OfferTypes[0].displayTime}</strong> dni</h4>
            </div>
          )
        } else if (ChoosedOption === 'basic+') {
          return (
            <div id='ChoosedOfferDetails'>
              <h3 className='choosedOfferDescription'>Cena: {OfferTypes[1].price} + {taxRate}% vat</h3>
              <h3 className='choosedOfferDescription'>Łącznie: <strong>{OfferTypes[1].price + (OfferTypes[1].price * taxRate)} zł</strong></h3>
              <h3 className='choosedOfferDescription'>Czas wyświetlania: {OfferTypes[1].displayTime}</h3>
              <h3 className='choosedOfferDescription'>Ilość odświeżeń: {OfferTypes[1].refreshes}</h3>
            </div>
          )
        } else if (ChoosedOption === 'premium') {
          return (
            <div id='ChoosedOfferDetails'>
              <h3 className='choosedOfferDescription'>Cena: {OfferTypes[2].price} + {taxRate * 100}% vat</h3>
              <h3 className='choosedOfferDescription'>Łącznie: <strong>{OfferTypes[2].price + (OfferTypes[2].price * taxRate)} zł</strong></h3>
              <h3 className='choosedOfferDescription'>Czas wyświetlania: <strong>{OfferTypes[2].displayTime}</strong> dni</h3>
            </div>
          )
        }
      };

    return(
      <div id='PaymentsMainDiv'>
            <h1 className='StepperHeader'>Podsumowanie:</h1>
             <div id='SummaryAndPayment'>
             <h2 id='choosedOptionTitle'>Wybrany rodzaj ogłoszenia:</h2>
             <h2>{showChoosedOption()}</h2>
             {LoggedUser.PremiumOffers > 0 && ChoosedOption === 'premium' ?
              <p>Pozostało <strong>{LoggedUser.PremiumOffers}</strong> {CalcutePremiumOffers()} premium do wykorzystania</p>
              :
              <>{showChoosedOfferDetails()}</>
             }
             <div id='NavButtonsStep3'>
               <div id='statuteAcceptDiv'>
                 <h3 className='choosedOfferDescription'>Akceptuje <span id='statutePopUpLink' onClick={showStatute}>regulamin</span></h3>
                 <input type='checkbox' onChange={switchStatuteAccept} defaultChecked={checkIsItChecked()}/>
               </div>
                 <div id='PaymentNavButtons'>
                       <div id='step3BackBtn' onClick={() => stepBack(1, ChoosedOption)}>
                         <p>Cofnij</p>
                       </div>
                   <ShowAddButtons
                     awaitOffer={awaitOffer}
                     setRefresh={setRefresh}
                     refresh={refresh}
                     setTitle={setTitle}
                     setExperience={setExperience}
                     setSelectedRole={setSelectedRole}
                     setSelectedSpeciality={setSelectedSpeciality}
                     setCountry={setCountry}
                     setDescription={setDescription}
                     setMinSalary={setMinSalary}
                     setMaxSalary={setMaxSalary}
                     setAwaitOffer={setAwaitOffer}
                    />
                 </div>
             </div>
           </div>
         { ShowPopUpModal ? <PopUpModal setRefresh={setRefresh} refresh={refresh}/> : '' }
   </div>
    )
   };


   export default ShowPayment;
