const FakeOffer = {
 addDate: Date.now(),
 title: 'Zatrudnię Spawacza',
 companyName: 'Nazwa Firmy',
 minSalary: '30',
 maxSalary: '40',
 currency: 'PLN',
 country: 'Polska',
 role: 'Spawacz',
 fake: true,
 UserLogo: false
}

export default FakeOffer;
