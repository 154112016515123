import React from 'react';
import './MapCloseButton.css';
import { IoIosClose } from 'react-icons/io';
import { motion } from "framer-motion";
import { useAtom } from 'jotai';
import { useMobileMapView } from '../../../data/GlobalStates';

const MapCloseButton = () => {

    const [ MobileMapView, setMobileMapView] = useAtom(useMobileMapView);

    const closeMobileMap = () => {
        setMobileMapView(!MobileMapView)
    };

    const variants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
    }

  return (
    <motion.button whileTap={{ scale: 0.8 }} className='MobileMapCloseButton' variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.5 }} onClick={() => closeMobileMap()}>
        <p className='MapCloseBtnText'>Zamknij Mapę</p>
        <IoIosClose className='MapCloseBtnIcon'/>
    </motion.button>
  )
}

export default MapCloseButton;