import React from 'react';
import './ShowEditFields.css';
import SalaryTypes from '../../../data/SalaryTypes';
import countriesList from '../../../data/countriesList';
import PopUpModal from '../../PopUpModal/PopUpModal';
import Roles from '../../../data/Roles';
import Currencies from '../../../data/Currencies';
import Specialities from '../../../data/Specialities';
import { useHistory } from 'react-router-dom';
import ShowAddButtons from '../AddButtons/AddButtons';
import cities from 'cities.json';
import { useAtom } from 'jotai';
import { useLoggedUser, useStepper, useCityFilter, useChoosedOption, useShowPopUpModal, useModalVersion, useModalInfo, useApplyWithSJProfile, useExternalApplyUrl } from '../../../data/GlobalStates';


const ShowEditFields = ({setCountryFilter, setCityLat, setCityLng, cityLat, cityLng, countryFilterCode, setCountryFilterCode, awaitOffer, Country, SelectedRole, setSelectedRole, Title, refresh, setSelectedSpeciality, setExperience, setMinSalary, setMaxSalary, setTitle, Description, setDescription, setCountry, setCurrency, SelectedSpeciality, setSalaryType, Experience, MinSalary, MaxSalaryWarn, MaxSalary, Currency, SalaryType, stepBack, setAwaitOffer, setRefresh }) => {

  const history = useHistory();

  //staby globalne:
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ Stepper, setStepper ] = useAtom(useStepper);
  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
  const [ ChoosedOption, setChoosedOption] = useAtom(useChoosedOption);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
  const [ ApplyWithSJProfile, setApplyWithSJProfile] = useAtom(useApplyWithSJProfile);
  const [ ExternalApplyUrl, setExternalApplyUrl] = useAtom(useExternalApplyUrl);


  const saveSelectedRole = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, role: e.target.value, speciality: '' })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setSelectedRole(e.target.value)
      setSelectedSpeciality('')
    }
  };

  const saveSelectedSpeciality = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, speciality: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setSelectedSpeciality(e.target.value)
    }
  };

  const saveSelectedExperience = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, experience: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setExperience(e.target.value)
    }
  };

  const updateMinSalary = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      if (isNaN(e.target.value)) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Proszę wpisać liczbę' })
      } else if (!isNaN(e.target.value)) {
        setAwaitOffer({ ...awaitOffer, minSalary: e.target.value })
      }
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      if (isNaN(e.target.value)) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Proszę wpisać liczbę' })
      } else if (!isNaN(e.target.value)) {
        setMinSalary(e.target.value)
      }
    }

  };

  const updateMaxSalary = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      if (isNaN(e.target.value)) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Proszę wpisać liczbę' })
      } else if (!isNaN(e.target.value)) {
        setAwaitOffer({ ...awaitOffer, maxSalary: e.target.value })
      }
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      if (isNaN(e.target.value)) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Proszę wpisać liczbę' })
      } else if (!isNaN(e.target.value)) {
        setMaxSalary(e.target.value)
      }
    }
  };

  const showTitleInfo = () => {
    if (Title.length && Title.length < 66) {
      return (
        <div id='TitleLengthInfo'>
          <p>pozostało {65 - Title.length} znaków</p>
        </div>
      )
    }
  };

  const saveTitle = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      if (e.target.value.length < 66) {
        setAwaitOffer({ ...awaitOffer, title: e.target.value })
      } else if (e.target.value.length > 65) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Tytuł może mieć max 65 znaków' })
        window.scrollTo(0, 0)
        setAwaitOffer({ ...awaitOffer, title: e.target.value.slice(0, 65) })
      }
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      if (e.target.value.length < 66) {
        setTitle(e.target.value)
      } else if (e.target.value.length > 65) {
        setShowPopUpModal(true)
        setModalVersion(13)
        setModalInfo({ title: 'Tytuł może mieć max 65 znaków' })
        window.scrollTo(0, 0)
        setTitle(e.target.value.slice(0, 65))
      }
    }
  };

  const saveApplyUrl = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, externalApplyLink: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setExternalApplyUrl(e.target.value)
    }
  };


  const saveDescription = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      if (e.target.value.length < 2001) {
        setAwaitOffer({ ...awaitOffer, description: e.target.value })
      }
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      if (e.target.value.length < 2001) {
        setDescription(e.target.value)
      }
    }
  };

  const showDescriptionInfo = () => {
    if (Description.length && Description.length < 2000) {
      return (
        <div id='TitleLengthInfo'>
          <p>pozostało {2000 - Description.length} znaków</p>
        </div>
      )
    }
  };


  const saveSelectedCountry = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, country: e.target.value })
      setCountryFilterCode(countriesList.filter(country => country.name_pl === e.target.value)[0].code)
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setCountry(e.target.value)
      setCountryFilterCode(countriesList.filter(country => country.name_pl === e.target.value)[0].code)
    }
  };

  const saveSelectedCity = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({
         ...awaitOffer,
        city: e.target.value,
        cityLatit: cities.filter(city => city.name === e.target.value)[0].lat,
        cityLong: cities.filter(city => city.name === e.target.value)[0].lng,
      })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setCityFilter(e.target.value)
      setCityLat(cities.filter(city => city.name === e.target.value)[0].lat)
      setCityLng(cities.filter(city => city.name === e.target.value)[0].lng)
    }
  };

  const saveSJProfileAplyInfo = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, applyWithSJProfile: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setApplyWithSJProfile(e.target.value)
    }
  };

  const saveSelectedCurrency = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, currency: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setCurrency(e.target.value)
    }
  };

  const saveSelectedSalaryType = (e) => {
    if (history.location.pathname === '/EdycjaOgloszenia') {
      setAwaitOffer({ ...awaitOffer, salaryType: e.target.value })
    } else if (history.location.pathname === '/DodajOgłoszenie') {
      setSalaryType(e.target.value)
    }
  };


  const showSpecialityOptions = () => {
    if (SelectedRole === 'Monter') {
      return (
        <>
          <h3 className='InputHeaders'>Specjalność:</h3>
          <select name="speciality" id='specialitySelect' className='OfferDetailsInputs' onChange={saveSelectedSpeciality} value={SelectedSpeciality}>
            {Specialities.Monter.map(speciality => <option key={speciality} value={speciality}>{speciality}</option>)}
          </select>
        </>
      )
    } else if (SelectedRole === 'Spawacz') {
      return (
        <>
          <h3 className='InputHeaders'>Specjalność:</h3>
          <select name="speciality" id='specialitySelect' className='OfferDetailsInputs' onChange={saveSelectedSpeciality} value={SelectedSpeciality}>
            {Specialities.Spawacz.map(speciality => <option key={speciality} value={speciality}>{speciality}</option>)}
          </select>
        </>
      )
    } else if (SelectedRole === 'CNC') {
      return (
        <>
          <h3 className='InputHeaders'>Specjalność:</h3>
          <select name="speciality" id='specialitySelect' className='OfferDetailsInputs' onChange={saveSelectedSpeciality} value={SelectedSpeciality}>
            {Specialities.OperatorCNC.map(speciality => <option key={speciality} value={speciality}>{speciality}</option>)}
          </select>
        </>
      )
    } else if (SelectedRole === 'Inżynier') {
      return (
        <>
          <h3 className='InputHeaders'>Specjalność:</h3>
          <select name="speciality" id='specialitySelect' className='OfferDetailsInputs' onChange={saveSelectedSpeciality} value={SelectedSpeciality}>
            {Specialities.Inżynier.map(speciality => <option key={speciality} value={speciality}>{speciality}</option>)}
          </select>
        </>
      )
    }
  };

  const saveOffer = () => {
    if (SelectedRole === 'Wybierz' || SelectedRole === '' || SelectedRole === undefined) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- wybierz zawód' })
    } else if ((SelectedSpeciality === 'Wybierz' || SelectedSpeciality === '' || SelectedSpeciality === undefined) && (SelectedRole === 'Monter' || SelectedRole === 'Spawacz' || SelectedRole === 'CNC')) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- wybierz specjalność' })
    } else if ((Experience === 'Wybierz' || Experience === '' || Experience === undefined) && (SelectedRole === 'Monter' || SelectedRole === 'Spawacz' || SelectedRole === 'CNC' || SelectedRole === 'Ślusarz' || SelectedRole === 'Szlifierz' || SelectedRole === 'Kowal' || SelectedRole === 'Malarz' || SelectedRole === 'Brygadzista') && (SelectedSpeciality === 'Rurociągi' || SelectedSpeciality === 'Konstrukcje' || SelectedSpeciality === 'Wentylacje' || SelectedSpeciality === 'Ermeto'
      || SelectedSpeciality === '111' || SelectedSpeciality === '114' || SelectedSpeciality === '121' || SelectedSpeciality === '131' || SelectedSpeciality === '141' || SelectedSpeciality === '135' || SelectedSpeciality === '136' || SelectedSpeciality === '137' || SelectedSpeciality === '311'
      || SelectedSpeciality === 'Prasa' || SelectedSpeciality === 'Laser' || SelectedSpeciality === 'Frezarka' || SelectedSpeciality === 'Tokarka')) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- wybierz poziom doświadczenia' })
    } else if (!Title && (Experience === 'Początkujący' || Experience === 'Śr.-zaawansowany' || Experience === 'Zaawansowany' || Experience === 'Expert') && (SelectedRole === 'Monter' || SelectedRole === 'Spawacz' || SelectedRole === 'CNC' || SelectedRole === 'Ślusarz' || SelectedRole === 'Szlifierz' || SelectedRole === 'Kowal' || SelectedRole === 'Malarz' || SelectedRole === 'Brygadzista') && (SelectedSpeciality === 'Rurociągi' || SelectedSpeciality === 'Konstrukcje' || SelectedSpeciality === 'Wentylacje' || SelectedSpeciality === 'Ermeto'
      || SelectedSpeciality === '111' || SelectedSpeciality === '114' || SelectedSpeciality === '121' || SelectedSpeciality === '131' || SelectedSpeciality === '141' || SelectedSpeciality === '135' || SelectedSpeciality === '136' || SelectedSpeciality === '137' || SelectedSpeciality === '311'
      || SelectedSpeciality === 'Prasa' || SelectedSpeciality === 'Laser' || SelectedSpeciality === 'Frezarka' || SelectedSpeciality === 'Tokarka')) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- Uzupełnij Tytuł ogłoszenia' })
    } else if (!Description && Title && (Experience === 'Początkujący' || Experience === 'Średnio-zaawansowany' || Experience === 'Zaawansowany' || Experience === 'Expert') && (SelectedRole === 'Monter' || SelectedRole === 'Spawacz' || SelectedRole === 'CNC' || SelectedRole === 'Ślusarz' || SelectedRole === 'Szlifierz' || SelectedRole === 'Kowal' || SelectedRole === 'Malarz' || SelectedRole === 'Brygadzista') && (SelectedSpeciality === 'Rurociągi' || SelectedSpeciality === 'Konstrukcje' || SelectedSpeciality === 'Wentylacje' || SelectedSpeciality === 'Ermeto'
      || SelectedSpeciality === '111' || SelectedSpeciality === '114' || SelectedSpeciality === '121' || SelectedSpeciality === '131' || SelectedSpeciality === '141' || SelectedSpeciality === '135' || SelectedSpeciality === '136' || SelectedSpeciality === '137' || SelectedSpeciality === '311'
      || SelectedSpeciality === 'Prasa' || SelectedSpeciality === 'Laser' || SelectedSpeciality === 'Frezarka' || SelectedSpeciality === 'Tokarka')) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- Uzupełnij Treść Ogłoszenia' })
    } else if ((Country === 'Wybierz' || Country === '' || Country === undefined) && Description && Title && (Experience === 'Początkujący' || Experience === 'Średnio-zaawansowany' || Experience === 'Zaawansowany' || Experience === 'Expert') && (SelectedRole === 'Monter' || SelectedRole === 'Spawacz' || SelectedRole === 'CNC' || SelectedRole === 'Ślusarz' || SelectedRole === 'Szlifierz' || SelectedRole === 'Kowal' || SelectedRole === 'Malarz' || SelectedRole === 'Brygadzista') && (SelectedSpeciality === 'Rurociągi' || SelectedSpeciality === 'Konstrukcje' || SelectedSpeciality === 'Wentylacje' || SelectedSpeciality === 'Ermeto'
      || SelectedSpeciality === '111' || SelectedSpeciality === '114' || SelectedSpeciality === '121' || SelectedSpeciality === '131' || SelectedSpeciality === '141' || SelectedSpeciality === '135' || SelectedSpeciality === '136' || SelectedSpeciality === '137' || SelectedSpeciality === '311'
      || SelectedSpeciality === 'Prasa' || SelectedSpeciality === 'Laser' || SelectedSpeciality === 'Frezarka' || SelectedSpeciality === 'Tokarka')) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Uzupełnij Dane:', message: '- Wybierz Kraj którego dotyczy oferta' })
    } else if (parseInt(MaxSalary) < parseInt(MinSalary)) {
      setShowPopUpModal(true)
      setModalVersion(2)
      window.scrollTo(0, 0)
      setModalInfo({ title: 'Błąd', message: '- Stawka maksymalna powinna być większa od minimalnej' })
    } else {
      const data = {
        addDate: Date.now(),
        applyWithSJProfile: ApplyWithSJProfile,
        visibility: true,
        id: LoggedUser.name.replace(/[.' '/,#$[\]]/g, '') + (new Date(Date.now()).toLocaleString('pl').slice(0, 19).replace(/[.' '/,:$[\]]/g, '')),
        companyName: LoggedUser.name,
        contact: LoggedUser.PhoneNumber,
        externalApplyLink: ExternalApplyUrl,
        role: SelectedRole,
        speciality: SelectedSpeciality,
        experience: Experience,
        country: Country,
        city: CityFilter,
        paid: LoggedUser.PremiumOffers > 0 && ChoosedOption === 'premium' ? true : '', // docelowo gdy user posiada pakiet dla basic lub premium to true else false 
        cityLatit: cityLat || null,
        cityLong: cityLng || null,
        title: Title,
        description: Description,
        minSalary: MinSalary,
        maxSalary: MaxSalary,
        salaryType: SalaryType,
        currency: Currency,
        option: ChoosedOption,
        email: LoggedUser.email,
        listViews: 0,
        detailsViews: 0
      }
      setAwaitOffer(data)
      setStepper(2)
      window.scrollTo(0, 0)
    }
  };

  return (
    <div id='EditFieldsMainDiv'>
      <div id='AddOfferEditFields'>
        {history.location.pathname === '/EdycjaOgloszenia' ? <h2 className='EditOfferHeader'>Edytuj dane oferty</h2> : ''}
        <h3 className='InputHeaders'>Zawód:</h3>
        <select name="Role" id='roleSelect' className='OfferDetailsInputs' value={SelectedRole} onChange={saveSelectedRole}>
          {Roles.map(role => <option key={role} value={role}>{role}</option>)}
        </select>
        {showSpecialityOptions()}
        <h3 className='InputHeaders'>Poziom doświadczenia:</h3>
        <select name="Experience" id='ExperienceSelect' className='OfferDetailsInputs' onChange={saveSelectedExperience} value={Experience}>
          <option value='Wybierz'>Wybierz</option>
          <option value='Początkujący'>Początkujący</option>
          <option value='Zaawansowany'>Zaawansowany</option>
          <option value='Expert'>Expert</option>
        </select>
        <div id='AddOfferSalaryDIV'>
          <div id='SalariesDiv'>
            <h3 id='SalaryHeader' className='InputHeaders'>Stawka od: </h3>
            <input className='SalaryInputs' placeholder='0' onChange={updateMinSalary} value={MinSalary || ''}></input>
            <h3 className='InputHeaders'>do: </h3>
            <input className={!MaxSalaryWarn ? 'SalaryInputs' : 'SalaryInputsWarn'} placeholder='0' onChange={updateMaxSalary} value={MaxSalary || ''}></input>
          </div>
          <div id='currenciesDiv'>
            <h3 className='InputHeaders'>Waluta: </h3>
            <select name="Currency" id='CurrencySelect' className='OfferDetailsInputs' onChange={saveSelectedCurrency} value={Currency || ''}>
              {Currencies.map(currency => <option key={currency} value={currency}>{currency}</option>)}
            </select>
          </div>
        </div>
        <div id='SalaryTypeContainer'>
          <h3 className='InputHeaders'>Rodzaj Stawki: </h3>
          <select name="SalaryType" id='SalaryTypeSelect' className='OfferDetailsInputs' onChange={saveSelectedSalaryType} value={SalaryType}>
            {SalaryTypes.map(salaryType => <option key={salaryType} value={salaryType}>{salaryType}</option>)}
          </select>
        </div>
        {parseInt(MaxSalary) < parseInt(MinSalary) ? <p id='salaryHint'>Maksymalna stawka powinna wyższa niż minimalna</p> : ''}
        <h3 className='InputHeaders'>Tytuł Ogłoszenia: </h3>
        <input id='TitleInput' className='OfferDetailsInputs' placeholder='Tytuł oferty' onChange={saveTitle} value={Title || ''}></input>
        {Title && Title.length ? showTitleInfo() : ''}
        <h3 className='InputHeaders'>Opis Stanowiska: </h3>
        <textarea id='DescriptionInput' className='OfferDetailsInputs' type='text' placeholder='Opis oferty ...' onChange={saveDescription} value={Description || ''}></textarea>
        {Description ? showDescriptionInfo() : ''}
        <h3 className='InputHeaders'>Kraj:</h3>
        <select name="Countries" id='countrySelect' className='OfferDetailsInputs' onChange={saveSelectedCountry} value={Country}>
          {countriesList.map(country => <option key={country.name_pl} value={country.name_pl}>{country.name_pl}</option>)}
        </select>
        <h3 className='InputHeaders'>Miasto:</h3>
        <select name="Countries" id='countrySelect' className='OfferDetailsInputs' onChange={saveSelectedCity} value={CityFilter}>
           { countryFilterCode ?
             cities.filter(city => city.country === countryFilterCode).sort((a, b) => a.name.localeCompare(b.name)).map(city => <option key={city.name + city.lat + city.lng} value={city.name}>{city.name}</option>)
            : <option> proszę wybrać państwo</option>
           }
        </select>
        <h3 className='InputHeaders' id='SJProfileAplyConfirmation'>Czy chcesz umożliwić aplikowanie na ofertę uproszczonym profilem pracownika z portalu SteelJobs.pl ?</h3>
        <select name="Countries" id='countrySelect' className='OfferDetailsInputs' onChange={saveSJProfileAplyInfo} value={ApplyWithSJProfile}>
              <option key={'Wybierz'} value={''}>Wybierz</option>
              <option key={'Tak'} value={'Tak'}>Tak</option> 
              <option key={'Nie'} value={'Nie'}>Nie</option> 
        </select>
        <h3 className='InputHeaders' id='SJProfileAplyConfirmation'>Posiadasz własny system aplikacji on-line wklej link poniżej:</h3>
          <input id='TitleInput' className='OfferDetailsInputs' placeholder='link do aplikacji' onChange={saveApplyUrl} value={ExternalApplyUrl || ''}></input>
        <div className='PhoneNumAddedToOffer'>
          <p className='EditFieldsPhoneValues'>Telefon Kontaktowy: </p>
          <p className='EditFieldsPhoneValues'>{LoggedUser.PhoneNumber}</p>
          <span className='PhoneToolTip'>Możliwość edycji w zakładce Twój Profil</span>
        </div>
        <div id='NaviBtns'>
          {history.location.pathname !== '/EdycjaOgloszenia' ?
            <div className='NaviBtnsEditFields' onClick={() => stepBack(0, null)}>
              <p>Cofnij</p>
            </div> : ''
          }
          {
            history.location.pathname !== '/EdycjaOgloszenia' ?
              <div className='NaviBtnsEditFields' onClick={saveOffer}>
                <p>Dalej</p>
              </div>
              :
              <ShowAddButtons
                awaitOffer={awaitOffer}
                setRefresh={setRefresh}
                refresh={refresh}
                setModalInfo={setModalInfo}
                setTitle={setTitle}
                setExperience={setExperience}
                setSelectedRole={setSelectedRole}
                setSelectedSpeciality={setSelectedSpeciality}
                setCountry={setCountry}
                setDescription={setDescription}
                setMinSalary={setMinSalary}
                setMaxSalary={setMaxSalary}
                setAwaitOffer={setAwaitOffer}
                setCountryFilterCode={setCountryFilterCode}
                setCountryFilter={setCountryFilter}
              />
          }
        </div>
      </div>
    </div>
  )
};


export default ShowEditFields;