import React from 'react';
import './DeleteOfferPermanently.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const DeleteOfferPermanently = ({db, PopUpModalRef, set, setRefresh }) => {

    const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const deleteOfferPermanently = async () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        try {
          await set(PopUpModalRef(db, `offers/${DetailedOffer.id}`), null)
          .then(() => {
             set(PopUpModalRef(db, `offersDeletedPermanently/${DetailedOffer.id}`), {addDate: DetailedOffer.addDate , id: DetailedOffer.id, title: DetailedOffer.title, name: DetailedOffer.companyName})
             setRefresh(Math.floor(Math.random() * 121))
             setModalVersion(12)
             setModalInfo({title:'Ogłoszenie usunięte'})
             
          })
       } catch (error) {
           setModalVersion(12)
           setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
           set(PopUpModalRef(db, `errors/${year}/${month}/${day}/deleteOfferPermanently`), error.message)
       }
      };

    return (
        <div id='EditUserFields'>
             <h1 className='ModalTitle'>{ModalInfo.title}</h1>
               <h3 className='DeleteOfferModalMessage'>{DetailedOffer.title}</h3>
                 <h4 className='DeleteOfferModalMessageID'>ID Oferty: {DetailedOffer.id}</h4>
              <div id='DeleteConfirmDiv'>
                <button className='ConfirmBtns' onClick={deleteOfferPermanently}>Tak</button>
                <button className='ConfirmBtns' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Nie</button>
              </div>
        </div>
    )
}

export default DeleteOfferPermanently;
