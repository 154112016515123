import React from 'react';
import './CookiesInfo.css';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";


const CookiesInfo = ({displayCookiesInfo, setDisplayCookiesInfo}) => {

    const hideCookiesInfo = () => {
       setDisplayCookiesInfo(false)
    };

    const variants = {
      visible: { opacity: 1 },
      hidden: { opacity: 0 },
    }

    const showCookiesInfo = () => {
        return (
              <motion.div variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.7 }} id='MainCookiesDiv'>
                  <p>Portal korzysta z plików Cookies</p>
                    <Link to='/Cookies' id='CookiesPageLink'>Polityka cookies</Link>
                  <button id='hideCookiesBtn' onClick={hideCookiesInfo}>Akceptuję</button>
              </motion.div>
          )
    };

    return (
        <div id='CookiesDiv'>
          {displayCookiesInfo ? showCookiesInfo() : ''}
        </div>
    )
}

export default CookiesInfo;
