import React from 'react';
import countriesList from '../../../data/countriesList';
import './displayFilteredCountry.css';
import { GoLocation } from 'react-icons/go';
import { FiAlertTriangle } from 'react-icons/fi';
import dataBase from '../../../config';
import { ref as FilteredCountryREF, set, get, child, increment} from "firebase/database";

const displayFilteredCountry = ({setFilteredCountryName, countryFilterCode, name, setCountryFilter, setCountryFilterCode }) => {


  const dbRef = FilteredCountryREF(dataBase);// Odczyt 


    const saveSearchedCountryStats = async (name) => {
      try {
        await get(child(dbRef, `popularCountries`))
        .then((countries) => {
          if (Object.keys(countries.val()).filter(country => country === name).length === 0) {
            set(FilteredCountryREF(dataBase, `popularCountries/${name}/searches`), 1)
          } else if (Object.keys(countries.val()).filter(country => country === name).length === 1) {
            get(child(dbRef, `popularCountries/${name}/searches`))
            .then((value) => {
              set(FilteredCountryREF(dataBase, `popularCountries/${name}/searches`), increment(1))
            })
          }
        })
      } catch (error) {
        console.log(error)
      }
    };

    const saveCountryFilter = () => {
        saveSearchedCountryStats(name)
        setCountryFilter(name)
        setCountryFilterCode(countriesList.filter(country => country.name_pl === name)[0].code)
      };

      const displayCuttedName = (name) => {
        if (name.length < 14) {
         return name
        } else return name.slice(0,14) + '...'
     };

     const cleanCountryInput = () => {
      setCountryFilter('')
      setFilteredCountryName('')
     };

     const displayFilteredResults = () => {
       if (name !== 'Nie znaleziono kraju' && !countryFilterCode) {
        return (
          <div className='filteredCountryTipButton' onClick={() => saveCountryFilter()}>
          <p>{displayCuttedName(name)}</p>
          <GoLocation className='filteredCountryTipIcon' />
        </div>
        )
       } else if (name === 'Nie znaleziono kraju' && !countryFilterCode) {
        return (
          <div className='filteredCountryNotFoundTipButton' onClick={() => cleanCountryInput()}>
          <p>{name}</p>
          <FiAlertTriangle className='filteredCountryTipIcon'/>
        </div>
        )
       }
     };


  return (
    <div>
     {displayFilteredResults()}
    </div>
  )
}

export default displayFilteredCountry