import React from 'react';
import './displayFilteredCity.css';
import { FiAlertTriangle } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go'; 
import { ref as FilteredCountryREF, set, get, child, increment} from "firebase/database";
import dataBase from '../../../config';
import { useAtom } from 'jotai';
import { useCityFilter } from '../../../data/GlobalStates';

const DisplayFilteredCity = ({name, setFilteredCityName}) => {

  const dbRef = FilteredCountryREF(dataBase);// Odczyt 
  
  //stany globalne:
  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);

  const cleanCityInput = () => {
    setFilteredCityName('')
    setCityFilter('')
  };

  const saveSearchedCityStats = async (name) => {
    try {
      await get(child(dbRef, `popularCities`))
      .then((cities) => {
        if (Object.keys(cities.val()).filter(city => city === name).length === 0) {
          set(FilteredCountryREF(dataBase, `popularCities/${name}/searches`), 1)
        } else if (Object.keys(cities.val()).filter(city => city === name).length === 1) {
          get(child(dbRef, `popularCities/${name}/searches`))
          .then((value) => {
            set(FilteredCountryREF(dataBase, `popularCities/${name}/searches`), increment(1))
          })
        }
      })
    } catch (error) {
      console.log(error)
    }
  };


  const activateCityFilter = () => {
    setCityFilter(name)
    saveSearchedCityStats(name)
  };

  const displayCuttedName = (name) => {
     if (name.length < 14) {
      return name
     } else return name.slice(0,10) + '...'
  };

  if (name === 'Proszę wybrać Państwo' && name !== 'Nie znaleziono miasta') {
    return (
      <div className='displayFilteredCityWarningContainer' onClick={() => cleanCityInput()}>
          <p>{name}</p>
          <FiAlertTriangle className='filteredCountryTipIcon'/>
      </div>
    )
  } else if (name !== 'Proszę wybrać Państwo' && name !== 'Nie znaleziono miasta') {
    return (
      <div className='displayFilteredCityContainer' onClick={() => activateCityFilter()}>
          <p>{displayCuttedName(name)}</p>
          <GoLocation className='filteredCountryTipIcon'/>
      </div>
    )
  } else if (name === 'Nie znaleziono miasta') {
    return (
      <div className='displayFilteredCityWarningContainer' onClick={() => cleanCityInput()}>
          <p>{name}</p>
          <FiAlertTriangle className='filteredCountryTipIcon'/>
      </div>
    )
  }
};

export default DisplayFilteredCity;