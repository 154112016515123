import React from 'react';
import './CompaniesSearchBar.css';
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiBlacksmith } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import SearchBarNavButtons from '../Header/SearchBarNavButtons/SearchBarNavButtons';
import { useAtom } from 'jotai';
import { useSearchedCompany, useAllCompanies } from '../../data/GlobalStates';


const CompaniesSearchBar = () => {

      const [ SearchedCompany, setSearchedCompany] = useAtom(useSearchedCompany);
      const [ AllCompanies, setAllCompanies] = useAtom(useAllCompanies);

      const setCompaniesInputValue = (e) => {
          if (e.target.value.length) {
            setSearchedCompany(AllCompanies.filter( company => company.name.toLowerCase().includes(e.target.value.toLowerCase())))
          } else if (!e.target.value.length) {
            setSearchedCompany(AllCompanies)
          }
      };

    return (
        <div id='SearchBarMainDiv'>
             <div id='SearchInputsAndLogosContainer'>
               <div className='FirmBaseSearchBarAndNavifation'>
                 <input id='CompaniesSearchInput' placeholder='Wyszukaj Firmy' onChange={setCompaniesInputValue}></input>
                 <SearchBarNavButtons />
               </div>
               <div className='SearchInputIconsContainer'>
                            <BiWrench className='searchInputIcons'/>
                            <GiCannonShot className='searchInputIcons'/>
                            <GiBlacksmith className='searchInputIcons'/>
                            <RiTeamLine className='searchInputIcons'/>
              </div>
             </div>
        </div>
    )
}

export default CompaniesSearchBar;
