import React, {useState} from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';
import { ref, set } from "firebase/database";
import dataBase from '../../config';
import PopUpModal from '../PopUpModal/PopUpModal';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../data/GlobalStates';

const Contact = () => {

    const db = dataBase;
    //stany lokalne:
    const [ MessageContact, setMessageContact ] = useState('');
    const [ MessageBody, setMessageBody ] = useState('');
    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const saveTitle = (e) => {
        if (e.target.value.length < 45) {
            setMessageContact(e.target.value)
        }
      };

    const saveDescription = (e) => {
        if (e.target.value.length < 400) {
            setMessageBody(e.target.value)
        }
    };

    const SendMessage = async () => {

        const ActDate = Date.now()

        const NewMessage = {
          contact:MessageContact,
          message:MessageBody,
          addDate: ActDate
        }

        if (MessageContact.length > 3 && MessageBody.length > 20) {
          try {
            await set(ref(db, `ContactMessages/${NewMessage.addDate}`), NewMessage)
            .then(() => {
              setShowPopUpModal(true)
              setModalVersion(2)
              setModalInfo({title:'Wiadomość wysłana', message:'Dziękujemy za przesłanie wiadomości odpowiemy w możliwie najkrótszym terminie'})
              setMessageContact('')
              setMessageBody('')
            })
          } catch (error) {
            alert(error)
          }
        } else {
            setShowPopUpModal(true)
            setModalVersion(2)
            setModalInfo({title:'Wiadomość zbyt krótka', message:'Prosimy o bardziej szczegółowy opis sprawy'})
        }
    };

  return (
    <div id='ContactMainContainer'>
         <Link to='/' id='ContactLogo'>SteelJobs.pl</Link>
           <div id='ContactContactValuesContainer'>
              <h1 className='ContactHeader'>Mail:</h1>
              <h1 className='ContactValue'>info@steeljobs.pl</h1>
              <h1 className='ContactHeader'>Telefon:</h1>
              <h1 className='ContactValue'>+48 508 568 824</h1>
              <h1 className='ContactHeader'>Lub wypełnij formularz:</h1>
          </div>
        <div id='FormMainContainer'>
              <h3 className='InputHeaders'>Dane Kontaktowe: </h3>
                <input className='FeedBackContactInput' placeholder='Mail lub Telefon' onChange={saveTitle} value={MessageContact}></input>
                  <h3 className='InputHeaders'>Wiadomość:</h3>
                    <textarea className='FeedBackContactMessageInput' type='text' placeholder='Treść Wiadomości' onChange={saveDescription} value={MessageBody}></textarea>
        </div>
       <button className='ContactBtns' onClick={SendMessage}>Wyślij</button>
           { ShowPopUpModal ? <PopUpModal/> : '' }
    </div>
  )
};

export default Contact;
