import React, {useState} from 'react';
import './AddCompanyLogo.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { motion } from "framer-motion";
import { ref as sRef, getDownloadURL, uploadBytesResumable  } from "firebase/storage";
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';


const AddCompanyLogo = ({db, set, PopUpModalRef, setFileSizeAlert, displayUploadProgressValue, fileSizeAlert, setProgresspercent, progresspercent, setImgUrl, storage}) => {

    //stany lokalne:
    const [choosenFile, setChoosenFile] = useState('');
    const [choosenFileBody, setChoosenFileBody] = useState('');

    //stany globalne:
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const updateFileInformation = (e) => {
      e.preventDefault()
      setChoosenFile(e.target.files[0].name)
      const objectUrl = URL.createObjectURL(e.target.files[0])
      setChoosenFileBody(objectUrl)
    }

    const handleLogoSubmit = (e) => {

        e.preventDefault()
        const file = e.target[0]?.files[0]
        const storageRef = sRef(storage, `CompaniesLogos/${LoggedUser.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file);
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDay();

        if (!file) {
            return
        } else if (file.type.split('/')[0] !== 'image') { // sprawdzenie czy plik jest obrazkiem
            setFileSizeAlert('Wybrany plik nie jest obrazem, wybierz inny')
            setTimeout(() => setFileSizeAlert(false), 3000)
        } else if (file.size > 3e6) { //rozmiar loga zbyt duży pow 3mb
            setFileSizeAlert('Plik jest za duży max wielkość: 3 mb')
            setTimeout(() => setFileSizeAlert(false), 3000)
        } else if (file.type.split('/')[0] === 'image' && file.size < 3e6) { // rozmiar loga OK
            setFileSizeAlert(false)
        try {
              uploadTask.on("state_changed", (snapshot) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              setProgresspercent(progress);
              set(PopUpModalRef(db, `users/${LoggedUser.keyName}/UserLogo`), true)
            },() => {
                
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setImgUrl(downloadURL)
                
              })
            }
          )
        } catch (error) {
           alert('Nie udało się zapisać loga spróbuj z innym plikiem')
           console.log(error) 
           set(PopUpModalRef(db, `errors/${year}/${month}/${day}/handleLogoSubmit`), error.message) 
        }
        }
      };

      const displayChoosenFileInfo = () => {
        if (!choosenFile) {
          return (
            <div className='chooseFileInfo'>
               <p className='chooseFileInfoText'>Przeciągnij plik</p>
               <p className='chooseFileInfoText'>lub</p>
               <p className='chooseFileInfoText'>Kliknij TUTAJ</p>
               <AiOutlineCloudUpload className='LogoUploaderChooseFileIcon'/>
            </div>
          )
        } else if (choosenFile) {
          return (
            <div className='CompanyLogoPreviewContainer'>
               <img className='CompanyLogoPreview' src={choosenFileBody}/>
               <p>{choosenFile}</p>
            </div>
          )
        }
      };

      const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }

    return (
        <div id='AddCompanyLogoMainContainer'>
          <h1 className='ModalTitle'>{ModalInfo.title}</h1>
            <h3 className='UserLogoHints'>Maksymalny rozmiar: 3 mb</h3>
               {progresspercent === 100 ? <p className='LogoSaveConfirm'>Zapisano pomyślnie</p> : ''}
                 {fileSizeAlert ? <motion.p variants={variants} initial="hidden" animate="visible"  transition={{ duration: 1 }} className='FileSizeWarning'>{fileSizeAlert}</motion.p> : ''}
               {displayUploadProgressValue === 100 || displayUploadProgressValue === 0 ? '' : <p className='LogoSaveConfirm'>{`Ładuję ${displayUploadProgressValue}%`}</p>}
            <form className='LogoUploaderForm' action="/upload" onSubmit={handleLogoSubmit}>
                <input id='file-upload' className='FileChooseButton' type='file' accept='image/*' onChange={(e) => updateFileInformation(e)}></input>
                  <div className='fileUploadInputPlaceHolder'>
                    {displayChoosenFileInfo()}
                  </div>
                  { choosenFile ? <button className='ConfirmBtns' type='submit'>Zapisz</button> : ''}
            </form>
         <button className='ConfirmBtns' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Zamknij</button>
       </div>
    )
}

export default AddCompanyLogo;
