import React from 'react';
import './FilteredCountryDisplayCard.css';
import { CgClose } from 'react-icons/cg';
import { useAtom } from 'jotai';
import { useCountryFilter, useCityFilter } from '../../../../data/GlobalStates';


const FilteredCountryDisplayCard = () => {
 
  //stany globalne:
  const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
  const [ CityFilter, setCityFilter] = useAtom(useCityFilter);

  const RemoveCountryItemFromFilter = () => {
    setCountryFilter('')
    setCityFilter('')
  };

  return (
    <div className='ChoosedCountryNameContainer' onClick={() => RemoveCountryItemFromFilter()}>
        <p className='ChoosedCountryName'>{CountryFilter}</p>
        <CgClose className='ChoosedCountryCloseBtn'/>
    </div>
  )
}

export default FilteredCountryDisplayCard;