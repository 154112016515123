import React from 'react';
import './CompanyDescriptionEdit.css';
import selectUserDataToEdit from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';


const CompanyDescriptionEdit = ({db, set, PopUpModalRef, Description, setDescription}) => {
    
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const saveDescription = async () => {
      
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        if (Description.length && LoggedUser) {
         try {
           await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/Description`), Description)
            .then(() => {
               setModalVersion(12)
                setModalInfo({title:'Zmiany zapisane'})
            })
         } catch (error) {
            setModalVersion(12)
              setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
                set(PopUpModalRef(db, `errors/${year}/${month}/${day}/saveDescription`), error.message) 
         }
        } else if (!LoggedUser) {
          setModalVersion(12)
           setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
        }
       };

       const setDescriptionValue = (e) => {
        if (e.target.value.length <= 700) {
          setDescription(e.target.value)
        }
      };
      

    return (
        <div id='EditUserFields'>
           <h1 className='ModalTitle'>{ModalInfo.title}</h1>
            <textarea id='CompanyDescriptionInput' placeholder={LoggedUser.Description} value={Description} onChange={setDescriptionValue}></textarea>
             {Description ? <p id='Hint'>{`pozostało ${700 - Description.length} znaków`}</p> : ''}
            <div className='CompanyDescriptionButtonsContainer'>
              <button className='ConfirmBtns' onClick={saveDescription}>OK</button>
              <button className='ConfirmBtns' onClick={() => selectUserDataToEdit(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
            </div>
      </div>
   
    )
}

export default CompanyDescriptionEdit;
