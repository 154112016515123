import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import './SingleOffer.css';
import { GiFactory } from 'react-icons/gi';
import { MdUpdate } from 'react-icons/md';
import { motion } from "framer-motion";
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { MdLocationOn } from 'react-icons/md';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';
import dataBase from '../../config';
import { ref, set, get, child, increment } from "firebase/database";
import { useAtom } from 'jotai';
import { useOffersToDisplay, useDetailedOffer, useOpenedOfferIndex, useCompaniesLogosUrls } from '../../data/GlobalStates';
import { ref as SingleOfferStorageRef, getDownloadURL } from 'firebase/storage';
import { storage } from '../../config';


const SingleOffer = ({scrolledOffer, offer, company}) => {

  //stany lokalne:
  const [ countVisits, setCountVisits ] = useState(true);
  const [ OfferUserLogo, setOfferUserLogo ] = useState('');

  //stany globalne:
  const [ OffersToDisplay, setOffersToDisplay ] = useAtom(useOffersToDisplay);
  const [ DetailedOffer, setDetailedOffer] = useAtom(useDetailedOffer);
  const [ OpenedOfferIndex, setOpenedOfferIndex] = useAtom(useOpenedOfferIndex);
  const [ CompaniesLogosUrls, setCompaniesLogosUrls] = useAtom(useCompaniesLogosUrls);

  const db = dataBase; // DO ZAPISU
  const dbRef = ref(dataBase);// DO ODCZYTU

  const history = useHistory();

  useEffect(() => {

   const checkOfferLogo = async () => {
    try {
      await get(child(dbRef, `users`)).then((companies) => {
       if (Object.values(companies.val()).filter(company => company.AccountType !== 'Private' && company.name === offer.companyName)[0].UserLogo === true) {
        getDownloadURL(SingleOfferStorageRef(storage, `CompaniesLogos/${offer.companyName}`))
        .then((url) => {
          if (url) {
            setOfferUserLogo(url)
          }
        })
       } 
      })
    } catch (error) {
      console.log(error)
    }
   }
    checkOfferLogo()
  },[OfferUserLogo])


  useEffect(()=>{

    let isMounted = true;

    if ((window.location.href.includes('userPanel') || window.location.href.includes('AddOffer')) && !isMounted) {
      return
    } else {
      visitCounter(offer)
    }
    return () => {
      isMounted = false;
    };
  },[countVisits])


  const visitCounter = async (offer) => {
    try {
      await get(child(dbRef, `offers/${offer.id}`))
       .then((offerInDb) => {
        if ( offerInDb.val() && !offerInDb.val().listViews) {
          set(ref(db, `offers/${offer.id}/listViews`), 1)
        } else if (offerInDb.val() && offerInDb.val().listViews) {
          set(ref(db, `offers/${offer.id}/listViews`), increment(1))
        }
      })
    } catch (error) {
      const errMessage = {
        occurDate: Date.now(),
        actionType: 'ListViewsIncr',
        fbErrMsg:error.toLocaleString(),
      }
      set(ref(db, `errors/${new Date(errMessage.occurDate).toLocaleString('pl').slice(0,10).replace(/[.,#$[\]]/g,'')}/${errMessage.occurDate}`), errMessage)
    }
  };

    const showCompanyLogo = (offer) => {
        if (offer.role === 'Monter') {
          return(<BiWrench className='RoleLogo'/>)
        } else if (offer.role === 'Spawacz') {
          return (<GiCannonShot className='RoleLogo'/>)
        } else if (offer.role === 'CNC') {
          return(<CgArrowsShrinkH className='RoleLogo'/>)
        } else if (offer.role === 'Ślusarz') {
          return (<GiAncientScrew className='RoleLogo'/>)
        } else if (offer.role === 'Szlifierz') {
          return(<GiStoneWheel className='RoleLogo'/>)
        } else if (offer.role === 'Kowal') {
          return(<GiBlacksmith className='RoleLogo'/>)
        } else if (offer.role === 'Malarz') {
          return(<GiLargePaintBrush className='RoleLogo'/>)
        } else if (offer.role === 'Brygadzista') {
          return(<RiTeamLine className='RoleLogo'/>)
        } else if (offer.role === 'Inżynier') {
          return(<CgArrowsShrinkH className='RoleLogo'/>)
        }
      };

        const switchOfferToDisplay = (offer) => {
         if (history.location.pathname.includes('BazaFirm')) {
              setDetailedOffer({...offer, CompanyLogo: OfferUserLogo})
                history.push({
                   pathname: `/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`,
                   from: 'BazaFirm',
                   state: company.name
                   })
          } else if (history.location.pathname === '/' || history.location.pathname.includes('Filtry') && offer.option === 'basic') {
               setOpenedOfferIndex(OffersToDisplay.map(el => el.id).indexOf(offer.id)) // refactor do zmiany sposób scrollowania do otwartej oferty powiązane z f. handleScroll z komp. offersDisplay
                setDetailedOffer({...offer, CompanyLogo: OfferUserLogo})
                 history.push(`/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`)
          } else if (history.location.pathname === '/' || history.location.pathname.includes('Filtry') && offer.option === 'premium') {
              setOpenedOfferIndex(0)
               setDetailedOffer({...offer, CompanyLogo: OfferUserLogo})
                 history.push(`/Oferty/${offer.id}/${offer.title.replace(/[' ']/g,'-')}`)
          }
        };

          const ActualDate = Date.now()// aktualna data w ms

          const DaysBeetwen  = ( date1, offer) => {
            const day =1000*60*60*24;
            const difference_ms = date1 - offer.addDate;
            if (Math.round(difference_ms/day) === 0) {
              return 'Dzisiaj'
            } else if (Math.round(difference_ms/day) === 1) {
              return 'Wczoraj'
            }
            return Math.abs(Math.round(difference_ms/day)) + ' dni temu'
          };

  const chooseClassName = (offer) => {
    if (!window.location.href.includes('userPanel') && offer.option !== 'premium') {
      return 'OfferDisplay' 
    } else if (!window.location.href.includes('userPanel') && offer.option === 'premium') {
      return 'OfferDisplayPromoted'
    } else if (window.location.href.includes('userPanel') && offer.option !== 'premium') {
      return 'OfferDisplayUser'
    } else if (window.location.href.includes('userPanel') && offer.option === 'premium') {
      return 'OfferDisplayPromotedUser'
    }
  };

  const cuttedTitle = (title) => {
    if (title.length >= 32) {
      return title.slice(0,32) + '...'
    } else if (title.length < 32) {
      return title
    }
  };

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

    return (
     <div className='SingleOfferMainDiv'>  
        <motion.div whileTap={{ scale: 0.98 }} className={chooseClassName(offer)} key ={offer.addDate} onClick={() => (switchOfferToDisplay(offer))} ref={scrolledOffer}>
          <div className='LogoDiv'>
            { !OfferUserLogo ? showCompanyLogo(offer) :

             <motion.img variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.5 }} src={OfferUserLogo} alt='logo' className='UserLogoSingleOffer'/>

            }
            </div>
             <div className='OfferDetails'>
               <p className='SingleOfferTitle'>{cuttedTitle(offer.title)}</p>
                  <div className='Details2'>
                   {
                      offer.minSalary && !offer.maxSalary ? <p className='PreviewSalary'>od {offer.minSalary} {offer.currency}</p> : ''
                   }
                   {
                      offer.minSalary && offer.maxSalary ? <p className='PreviewSalary'>od {offer.minSalary} do {offer.maxSalary} {offer.currency}</p> : ''
                   }
                   {
                      !offer.minSalary && offer.maxSalary ? <p className='PreviewSalary'>do {offer.maxSalary} {offer.currency}</p> : <p className='Salary'></p>
                   }
                   <div className='SingleOfferLocationContainer'>
                     <p className='Country'>{offer.country}</p>
                     <MdLocationOn className='SingleOfferLocationLogo' />
                   </div>
            </div>
                 <div className='CompanyDiv'>
                    <div className='SingleOfferCompanyNameAndLogo'>
                        <GiFactory className='Factory'/>
                        <p className='SingleOfferCompanyName'>{offer.companyName}</p>
                    </div>
                    <div className='addDate'>
                          <MdUpdate className='ClockLogo'/>
                          <p>{DaysBeetwen(ActualDate, offer)}</p>
                    </div>
                 </div>
            </div>
</motion.div>
</div>
    )
}

export default SingleOffer;
