import React from 'react';
import './SMSorCall.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { IoIosClose } from 'react-icons/io';
import { FiPhone } from 'react-icons/fi';
import { BiMailSend } from 'react-icons/bi';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const SMSorCall = () => {

    const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    return (
     <div id='ConfirmationModalMainContainer'>
        <div className='chooseOrCloseContainer'>
          <IoIosClose className='ClosePhoneOrSMSContainer' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}/>
        </div>
        <h2 className='PhoneOrSMSContactNumber'>{DetailedOffer.contact}</h2>
        <div className='PhoneOrSMSButtonsContainer'>
              <a className='PhoneAndSMSbtn' href={`tel://${DetailedOffer.contact}`}>
               <FiPhone />
               <p>Zadzwoń</p>
              </a>
              <a className='PhoneAndSMSbtn' href={`sms://${DetailedOffer.contact}?body=W odpowiedzi na ofertę na portalu SteelJobs.pl`}>
                <BiMailSend />
                <p>SMS</p>
              </a>
        </div>
     </div>
    )
}

export default SMSorCall;
