import React from 'react';
import './NotLoggedInfo.css';
import { useHistory } from 'react-router-dom';

const NotLoggedInfo = () => {

    const history = useHistory();

    return (
        <div id='NotLoggedMainDiv'>
            <div id='RegisterInfo'>
               <h1 id='RegisterTitle'>Jesteś wylogowany</h1>
               <button id='RegisterBtn'  onClick={() => {history.push('/login')}}>Logowanie</button>
            </div>
        </div>
    )
}

export default NotLoggedInfo;
