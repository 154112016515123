import './MarkerSingleOffer.css';
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { CgArrowsShrinkH } from 'react-icons/cg';
import { GiLargePaintBrush } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';


const MarkerSingleOffer = ({offer, openOfferDetails}) => {

  const displayCuttedTitle = (title) => {
    if (title.length >= 32) {
      return title.slice(0,32) + '...'
    } else if (title.length < 32) {
      return title
    }
  };

  const displaySalary = (min, max, cur) => {
    if (min && max) { return min + '-' + max + ' ' + cur}
    else if (!min || !max) return null
  };

  const showCompanyLogo = (offer) => {
    if (offer.role === 'Monter') {
      return(<BiWrench className='MapIcon'/>)
    } else if (offer.role === 'Spawacz') {
      return (<GiCannonShot className='MapIcon'/>)
    } else if (offer.role === 'CNC') {
      return(<CgArrowsShrinkH className='MapIcon'/>)
    } else if (offer.role === 'Ślusarz') {
      return (<GiAncientScrew className='MapIcon'/>)
    } else if (offer.role === 'Szlifierz') {
      return(<GiStoneWheel className='MapIcon'/>)
    } else if (offer.role === 'Kowal') {
      return(<GiBlacksmith className='MapIcon'/>)
    } else if (offer.role === 'Malarz') {
      return(<GiLargePaintBrush className='MapIcon'/>)
    } else if (offer.role === 'Brygadzista') {
      return(<RiTeamLine className='MapIcon'/>)
    } else if (offer.role === 'Inżynier') {
      return(<CgArrowsShrinkH className='MapIcon'/>)
    }
  };



  return (
    <div className='MarkerSingleOfferMainContainer' onClick={() => openOfferDetails(offer)}>
      {showCompanyLogo(offer)}
        <h1 className='MarkerSingleOfferHeader'>{displayCuttedTitle(offer.title)}</h1>
        <p className='MarkerSingleOfferSalary'>{displaySalary(offer.minSalary, offer.maxSalary, offer.currency)}</p>
        <p>{offer.companyName}</p>
        <p className='MarkerSingleOfferCity'>{offer.city}</p>
    </div>
  )
}

export default MarkerSingleOffer;