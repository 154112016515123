import React from 'react';
import './UserPanel.css';
import { getAuth } from "firebase/auth";
import MailVerifyInfo from './MailVerifyInfo/MailVerifyInfo';
import CompanyView from './CompanyView/CompanyView';
import WorkerView from './WorkerView/WorkerView';
import { useAtom } from 'jotai';
import { useLoggedUser} from '../../data/GlobalStates';


const UserPanel = ({setCountryFilterCode, UserLogo, setUserLogo, awaitOffer, setAwaitOffer, refresh, setRefresh}) => {

  //stany globalne:
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);

  const authedUser = getAuth() //authedUser.currentUser

  const showUserDetails = () => {

    if (LoggedUser.AccountType !== 'Private') {
      return <CompanyView 
                refresh={refresh}
                setUserLogo={setUserLogo}
                UserLogo={UserLogo}
                awaitOffer={awaitOffer}
                setAwaitOffer={setAwaitOffer}
                setCountryFilterCode={setCountryFilterCode}
                setRefresh={setRefresh} 
                />
    } else if (LoggedUser.AccountType === 'Private') {
      return <WorkerView 
                refresh={refresh}
                setRefresh={setRefresh}
                setUserLogo={setUserLogo}
                UserLogo={UserLogo}
                awaitOffer={awaitOffer}
                setAwaitOffer={setAwaitOffer}
                setCountryFilterCode={setCountryFilterCode}
      />
    }
};


    return (// GŁÓWNY RETURN
       <div id='LogedUserAuthDiv'>
           {authedUser.currentUser && authedUser.currentUser.emailVerified ? showUserDetails() : <MailVerifyInfo authedUser={authedUser}/>}
       </div>
    )
};

export default UserPanel;
