import React from 'react';
import './ChooseOffersLocalization.css';
import CloseModal from '../../CommonFunctions/closeModal';
import DisplayChoosedCity from '../../DisplayChoosedCity/DisplayChoosedCity';
import DisplayChoosedCountry from '../../DisplayChoosedCountry/DisplayChoosedCountry';
import DisplayFilteredCountry from '../../DisplayFilteredCountry/DisplayFilteredCountry';
import DisplayFilteredCity from '../../DisplayFilteredCity/DisplayFilteredCity';
import countriesList from '../../../../data/countriesList';
import PopularCountryButton from '../../PopularCountryButton/PopularCountryButton';
import PopularCityButton from '../../PopularCityButton/PopularCityButton';
import cities from 'cities.json';
import { useAtom } from 'jotai';
import { useCountryFilter, useCityFilter, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const ChooseOffersLocalization = ({setFilteredCountryName, setCountryFilterCode, filteredCountryName, setFilteredCityName, countryFilterCode, filteredCityName, popularCountries, popularCities}) => {

    const [ CountryFilter, setCountryFilter] = useAtom(useCountryFilter);
    const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const setCountryName = (e) => {
        setFilteredCountryName(e.target.value)
        setCountryFilterCode('')
     };

     const handleCountryKeyPress = (e) => {
        if (e.key === 'Enter' && countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase()))).length === 1) {
          setCountryFilter(countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase())))[0].name_pl)
          setCountryFilterCode(countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase())))[0].code)
         } else if (e.key === 'Enter' && countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase()))).length > 1) {
          setCountryFilter('')
          setCountryFilterCode('')
         }
    };

    const setCityName = (e) => {
        setFilteredCityName(e.target.value)
      };

      const handleCityKeyPress = (e) => {
        if (e.key === 'Enter' && countryFilterCode && cities.filter(city => (city.name.toLocaleLowerCase().includes(filteredCityName.toLocaleLowerCase()))).length === 1) {
          setCityFilter(cities.filter(city => (city.name.toLocaleLowerCase().includes(filteredCityName.toLocaleLowerCase())))[0].name)
        } else return
      };

      const displayFilteredCountries = () => {
        if (filteredCountryName.length > 2 && countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase()))).length > 0) {
          return (
            <div id='filteredCountriesButtonsContainer'>
              {
               countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase()))).map(i =>
               <DisplayFilteredCountry
                 key={i.name_pl}
                 countryFilterCode={countryFilterCode}
                 name={i.name_pl}
                 setCountryFilter={setCountryFilter}
                 setCountryFilterCode={setCountryFilterCode}
                 setFilteredCountryName={setFilteredCountryName}
               />)
              }
            </div>
          )
        } else if (filteredCountryName.length > 2 && countriesList.filter(country => (country.name_pl.toLocaleLowerCase().includes(filteredCountryName.toLocaleLowerCase()))).length === 0) {
          return (
            <div id='filteredCountriesButtonsContainer'>
             <DisplayFilteredCountry
               countryFilterCode={countryFilterCode}
               name={'Nie znaleziono kraju'}
               setCountryFilter={setCountryFilter}
               setCountryFilterCode={setCountryFilterCode}
               setFilteredCountryName={setFilteredCountryName}
             />
          </div>
          )
        }
      };


      const displayFilteredCities = () => {
        if (filteredCityName.length > 3 && countryFilterCode && !CityFilter && cities.filter(city => (city.country === countryFilterCode && city.name.toLocaleLowerCase().includes(filteredCityName.toLocaleLowerCase()))).length === 0) {
          return (
            <div id='filteredCountriesButtonsContainer'>
              <DisplayFilteredCity
                name={'Nie znaleziono miasta'}
                setFilteredCityName={setFilteredCityName}
                />
            </div>
          )
        } else if ( filteredCityName.length > 2 && !countryFilterCode && !CityFilter) {
          return (
            <div id='filteredCountriesButtonsContainer'>
              <DisplayFilteredCity
                name={'Proszę wybrać Państwo'}
                setFilteredCityName={setFilteredCityName}
                />
            </div>
          )
        } else if (filteredCityName.length > 2 && countryFilterCode && !CityFilter) {
          return (
            <div id='filteredCountriesButtonsContainer'>
              {
               cities.filter(city => (city.country === countryFilterCode && city.name.toLocaleLowerCase().includes(filteredCityName.toLocaleLowerCase()))).map(i =>
               <DisplayFilteredCity
                 name={i.name}
                 key={i.name + i.lat + i.lng}
                 setFilteredCityName={setFilteredCityName}
               />)
              }
            </div>
          )
        }
      };



      const displayPopularCountries = () => {
        return popularCountries.sort((a, b) => (b.searches - a.searches)).slice(0,5).map(country =>
           <PopularCountryButton
             country={country}
             key={country.name}
             setCountryFilter={setCountryFilter}
             />
         )
       };

       const displayPopularCities = () => {
       return popularCities.sort((a, b) => (b.searches - a.searches)).slice(0,5).map(city =>
           <PopularCityButton
              city={city}
              key={city.name}
              setCountryFilter={setCountryFilter}
           />
        )
       };
      

    return (
        <div id='EditUserFields'>
        <div id='CountriesList'>
         {!CountryFilter ? <h1 id='CountryChooseTitle'>Wybierz Państwo:</h1> : <h1 id='CountryChooseTitle'>Wybrane Państwo:</h1>}
          {CountryFilter.length > 2 ?
           <DisplayChoosedCountry
             setCountryFilter={setCountryFilter}
             setCountryFilterCode={setCountryFilterCode}
           />
           :
           <form onSubmit={e => e.preventDefault()}>
             <input type='text' className='SearchLocationsInputs' placeholder='...' onChange={setCountryName} onKeyDown={e => handleCountryKeyPress(e)} value={filteredCountryName}></input>
           </form>
          }
          {displayFilteredCountries()}
          {!CityFilter ? <h1 id='CountryChooseTitle'>Wybierz Miasto:</h1> : <h1 id='CountryChooseTitle'>Wybrane Miasto:</h1>}
          { CityFilter ? <DisplayChoosedCity/> :
                  <form onSubmit={e => e.preventDefault()}>
                    <input type='text' className='SearchLocationsInputs' placeholder='...' onChange={setCityName} value={filteredCityName} onKeyDown={handleCityKeyPress}></input>
                  </form>
          }
          {displayFilteredCities()}
         <div id='popularCountriesContainer'>
          <h1 id='CountryChooseTitle'>Popularne Kraje:</h1>
           <div className='PopularDestinationsButtonsContainer'>
             {displayPopularCountries()}
           </div>
             <h1 id='CountryChooseTitle'>Popularne Miasta:</h1>
           <div className='PopularDestinationsButtonsContainer'>
             {displayPopularCities()}
           </div>
         </div>
        </div>
     <div id='ConfirmBtn' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>OK</div>
  </div>
    )
}

export default ChooseOffersLocalization;


