import React from 'react';
import './LoadingAnimation.css';
import { BsWrench } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { motion } from "framer-motion";

const LoadingAnimation = ({classVariant}) => {

  const history = useHistory();

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const chooseDivProperClassName = () => {
    if (classVariant === 'choosingOption') {
      return 'ChoosingOption'
    } else if (classVariant === 'loginPage') {
      return 'AnimationMainDivSmall'
    } else return 'AnimationMainDiv'
  };

  const chooseWrenchProperClassName = () => {
    if (classVariant === 'choosingOption') {
      return 'ChoosingOptionWrench'
    } else if (classVariant === 'loginPage') {
      return 'AnimatedWrenchMini'
    } else return 'AnimatedWrench'
  };

  return (
        <motion.div variants={variants} initial="hidden" animate="visible"  transition={{ duration: 0.6 }} id={chooseDivProperClassName()}>
           <BsWrench id={chooseWrenchProperClassName()}/>
        </motion.div>

    )
}

export default LoadingAnimation;
