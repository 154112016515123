import React from 'react';
import '../SingleOffer/SingleOffer.css'
import { useHistory } from 'react-router-dom';
import OfferTypes from '../../data/OfferTypes';
import { BiWrench } from 'react-icons/bi';
import { GiAncientScrew } from 'react-icons/gi';
import { GiStoneWheel } from 'react-icons/gi';
import { GiBlacksmith } from 'react-icons/gi';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo } from '../../data/GlobalStates';


const RegisterInfo = () => {

  const history = useHistory();
  //stany globalne:
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

  const backToMain = () => {
    history.push('/')
  };



    return (
        <div id='AddOfferInfoMainDiv'>
        <h1 id='Logo' onClick={backToMain}>SteelJobs.pl</h1>
          <h2 className='AddOfferInfoText'>Szukasz pracy w branży metalurgicznej ?</h2>
             <h2 className='AddOfferInfoText'>Zarejestruj się na portalu i daj się poznać ponad 250 pracodawcom</h2>
             <h2 className='AddOfferInfoText'>Rejestracja jest bezpłatna</h2>
         <div id='RegisterInfo'>
            <button id='RegisterBtn'  onClick={() => {history.push('/login')}}>Rejestracja</button>
          </div>

         <div id='AddOfferInfoContactDiv'>
               <h1 className='AddOfferInfoText'>Masz pytania ?</h1>
               <h2 className='AddOfferInfoText'>Skontaktuj się z nami:</h2>
               <div id='ContactDoubleDiv'>
                   <div className='InfoBox'>
                    <p>Tel: 508 568 824</p>
                   </div>
                   <div className='InfoBox'>
                    <p>mail: info@steeljobs.pl</p>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default RegisterInfo;
