import React, {useState, useEffect} from 'react';
import { getAuth } from "firebase/auth";
import { useAtom } from "jotai";
import { getDownloadURL } from "firebase/storage";
import { ref as sRef } from 'firebase/storage';
import dataBase from '../../../config';
import { Link } from 'react-router-dom';
import { storage } from '../../../config';
import { FcPicture } from 'react-icons/fc';
import { GiConfirmed } from 'react-icons/gi';
import { BsFillMenuButtonWideFill } from 'react-icons/bs';
import { ImBlocked } from 'react-icons/im';
import { getDatabase, ref as UserPanelRef, child, get, onValue} from "firebase/database";
import { useLoggedUser, useDownloadedOffers, useShowPopUpModal, useModalVersion, useModalInfo, useExpiredOffers } from "../../../data/GlobalStates";
import UserOfferDisplayCard from '../UserOfferDisplayCard/UserOfferDisplayCard';

const CompanyView = ({refresh, setUserLogo, UserLogo, awaitOffer, setAwaitOffer, setCountryFilterCode, setRefresh}) => {

  const ActualDate = Date.now();
  const authedUser = getAuth() //authedUser.currentUser
  const dbRef = UserPanelRef(dataBase);// UCHW DO ODCZYTU
  //stany globalne:
  const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
  const [ DownloadedOffers, setDownloadedOffers ] = useAtom(useDownloadedOffers);
  const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
  const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
  const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
  const [ ExpiredOffers, setExpiredOffers] = useAtom(useExpiredOffers);
  //stany lokalne:
  const [UserOffers, setUserOffers] = useState('')
  const [AwaitingOffers, setAwaitingOffers] = useState('')
  const [UserOffersDisplayType, setUserOffersDisplayType] = useState(1)

  useEffect(() => {

    let isMounted = true;

    if (authedUser.currentUser && DownloadedOffers.length && isMounted) {
      syncActiveOffers()
      syncExpiredOffers()

      const db = getDatabase() // do przeniesienia
      const response = UserPanelRef(db, 'offers')
      return onValue(response, (snap) => {
        if (snap.exists()) {
          const data = snap.val() // obiekt zagnieżdzonych obiektów
          setAwaitingOffers(Object.values(data).filter(offer => offer.companyName === LoggedUser.name && offer.visibility === false && offer.paid === false).sort((a, b) => (b.addDate - a.addDate)))
        }
      })
    }
    return () => {
      isMounted = false;
    };
  },[ authedUser.currentUser, DownloadedOffers.length, LoggedUser, refresh])

  useEffect(() => { // pobranie Logo

    let isMounted = true;

      if (LoggedUser.UserLogo === true && isMounted) {
        setTimeout(() => {
          getDownloadURL(sRef(storage, `CompaniesLogos/${LoggedUser.name}`))
          .then((url) => {
              setUserLogo(url)
          }).catch(error => {
            if (error.code === 'storage/object-not-found') {
             setUserLogo(null)
              return
            }
          })
        },2000)
    }

    if (!LoggedUser.UserLogo && isMounted) {
      setUserLogo(null)
    }
    return () => {
      isMounted = false;
    };
  }, [LoggedUser.UserLogo, UserLogo])

  const checkPaidOption = (offer) => {
    if (offer.option === 'basic') { 
      return true } 
      else if ((offer.option === 'basic+' || offer.option === 'premium') && offer.paid === true) {
      return true } 
      else { return false }
};

const syncExpiredOffers = async () => {
  await get(child(dbRef, `offers`)).then((awaitingOffers) => {
    setExpiredOffers(Object.values(awaitingOffers.val()).filter(offer => offer.companyName === LoggedUser.name && offer.visibility === false && checkPaidOption(offer)))})
};

const syncActiveOffers = async () => {
  await get(child(dbRef, `offers`)).then((awaitingOffers) => {
    setUserOffers(Object.values(awaitingOffers.val()).filter(offer => offer.companyName === LoggedUser.name && offer.visibility === true && checkPaidOption(offer)))})
};


  const OpenChooseDataToEditModal = () => {
    setShowPopUpModal(true)
    setModalVersion(26)
    setModalInfo({title: 'Wybierz dane do edycji:'})
  };

  const OpenLogoUploader = () => {
    setShowPopUpModal(true)
    setModalVersion(17)
  };

  const openNotificationsChangeModal = () => {
    setShowPopUpModal(true)
    setModalVersion(24)
    setModalInfo(
     LoggedUser.Notifications === true ?
     {title: 'Chcesz wyłączyć powiadomienia ?' ,message: 'Portal SteelJobs.pl nie będzie wysyłać informacji o aktualnych promocjach oraz wskazówek dotyczących korzystania z serwisu.'}
     :
     {title: 'Chcesz włączyć powiadomienia ?' ,message: 'Portal SteelJobs.pl będzie wysyłać informację o aktualnych promocjach oraz wskazówkach dotyczących korzystania z serwisu.'}
     )
};

  const showUserOffers = () => {
    if (UserOffersDisplayType === 1) {
      return (
        UserOffers.length ? UserOffers.map(offer => (
          <UserOfferDisplayCard
            key={offer.id}
            offer={offer}
            setModalVersion={setModalVersion}
            awaitOffer={awaitOffer}
            setAwaitOffer={setAwaitOffer}
            UserOffersDisplayType={UserOffersDisplayType}
            setCountryFilterCode={setCountryFilterCode}
            setUserOffersDisplayType={setUserOffersDisplayType}
          />
          )) :
             <div className='UserPanelNoOfferInfoAndAddButton'>
                <h1 className='EndedOffersInfoHeader'>Nie dodano ofert</h1>
                    <Link to='/DodajOgłoszenie' className='UserPanelAddButtons'>
                      Dodaj Ofertę
                    </Link>
             </div>
      )
    } else if (UserOffersDisplayType === 2) {
      return (
        <div id='AwaitingOffersMainDiv'>
          {AwaitingOffers.length ?
           AwaitingOffers.map(offer =>
           <UserOfferDisplayCard
              key={offer.id}
              offer={offer}
              UserOffersDisplayType={UserOffersDisplayType}
              setUserOffersDisplayType={setUserOffersDisplayType}
            />) : <p className='EndedOffersInfoHeader'>brak ofert oczekujących na akceptację</p>}
        </div>
      )
    } else if (UserOffersDisplayType === 4) { // Zakończone oferty
      return (
        <div className='EndedUserOffersMainContainer'>
           { ExpiredOffers.length ? // wyświetlenie zakończonych ofert pracy
         <div>
             {ExpiredOffers.map(offer => (
               <UserOfferDisplayCard
                  key={offer.id}
                  offer={offer}
                  awaitOffer={awaitOffer}
                  setAwaitOffer={setAwaitOffer}
                  UserOffersDisplayType={UserOffersDisplayType}
                  setUserOffersDisplayType={setUserOffersDisplayType}
                />
                ))}
         </div>
           : ''}
        <div>
          { //Wyświetlenie info i przycisku dodawania w przypadku braku zakończonych ofert
           !ExpiredOffers.length ?
             <div className='UserPanelNoOfferInfoAndAddButton'>
                <h1 className='EndedOffersInfoHeader'>Zakończone oferty będą widoczne i dostępne do aktywacji przez 30 dni</h1>
                  <Link to='/WybierzRodzaj' className='UserPanelAddButtons'>Dodaj Ogłoszenie</Link>
             </div>
             :
             ''
          }
        </div>
        </div>
      )
    }
  };

  const switchUserOfferDisplayType = (type) => {
    setUserOffersDisplayType(type)
    setRefresh(Math.floor(Math.random() * 30))
  };

  const openWorkerOffersModal = () => {
    setShowPopUpModal(true)
    setModalVersion(37)
  };

  const ShowDescription = () => {
    return(
         <p id='descriptionField' className='AdressFields'>{ LoggedUser.Description ? LoggedUser.Description.slice(0, 10) + '...' : <span className='AdressFieldsPlaceHolder'>uzupełnij opis</span>}</p>
    )
  };





  return (
    <div id='LoggedUserMainDiv'>
    <div id='UserProfileInfoDiv'>
           <div id='CompanyNameInfo'>
              <h2 id='displayedCompanyName'>{LoggedUser.name}</h2>
           </div>
               <div id='EmailInfo'>
                  <p id='emailTitle'>E-mail:</p>
                  <p id='emailValue'>{LoggedUser.email}</p>
               </div>
               <Link to='/DodajOgłoszenie' id='EmailInfo'>
                  <p id='emailTitle'>Oferty Podstawowe:</p>
                  <p id='premiumOffersValue'>darmowe</p>
               </Link>
               <Link to='/DodajOgłoszenie' id='EmailInfo'>
                  <p id='emailTitle'>Oferty Premium: </p>
                  <p id='premiumOffersValue'>{LoggedUser.PremiumOffers}</p>
               </Link>
               <div id='NotificationsButtonMainContainer'>
                 <p className='NotificationsSwitch' onClick={() => openNotificationsChangeModal()}>Powiadomienia</p>
                 {LoggedUser.Notifications === true ? <GiConfirmed className='UserNotificationsAcceptLogo'/> : <ImBlocked className='UserNotificationsDeniedLogo'/>}
              </div>
                 {UserLogo ? <img src={UserLogo} className='UserLogoUserPanel'></img> : <FcPicture className='UserLogoUserPanel' onClick={OpenLogoUploader}/>}
                      <div className='EditBtns' onClick={OpenChooseDataToEditModal}>
                        <p>Edytuj Dane</p>
                        <BsFillMenuButtonWideFill className='EditUserDataIcon'/>
                      </div>
    </div>
    <div id='UserDataDiv'>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
        <h3 className='Titles'>Nr. kontaktowy: </h3>
        <p id='phoneValue' className='AdressFields'>{LoggedUser.PhoneNumber}</p>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>WWW: </h3>
         <p className='AdressFields'>{LoggedUser.Website ? LoggedUser.Website : <span className='AdressFieldsPlaceHolder'>adres twojej strony www</span>}</p>
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
        <h3 className='Titles'>Opis:</h3>
        { LoggedUser ? ShowDescription() : ''}
      </div>
      <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
         <h3 className='Titles'>Adres: {LoggedUser.Adress}</h3>
         <div id='AdressValues'>
                       <p className='AdressFields'>{LoggedUser.City ? LoggedUser.City : <span className='AdressFieldsPlaceHolder'>miasto</span>}</p>
                       <p className='AdressFields'>{LoggedUser.Street ? LoggedUser.Street : <span className='AdressFieldsPlaceHolder'>ulica</span>} {LoggedUser.BuildingNr ? LoggedUser.BuildingNr : <span className='AdressFieldsPlaceHolder'>nr. bud.</span>} / {LoggedUser.LocalNr ? LoggedUser.LocalNr : <span className='AdressFieldsPlaceHolder'>nr. lokalu</span>}</p>
                       <p className='AdressFields'>{LoggedUser.ZipCode ? LoggedUser.ZipCode : <span className='AdressFieldsPlaceHolder'>kod pocztowy</span>}</p>
         </div>
      </div>
          <div className='TitleAndValueContainer' onClick={OpenChooseDataToEditModal}>
            <h3 className='Titles'>NIP:</h3>
            <p className='AdressFields'>{LoggedUser.NIP ? LoggedUser.NIP : <span className='AdressFieldsPlaceHolder'>uzupełnij dane</span>}</p>
          </div>
      </div>
        <div id='UserOffersTypeHeader'>
          <h1 className='UserOffersHeader' id='WorkersOffersButton' onClick={() => openWorkerOffersModal()}>Oferty pracowników</h1>
        </div>
      <div id='UserOffersDiv'>
        <h1 className='Titles'>Twoje Oferty:</h1>
        <div id='UserOffersTypeHeader'>
          <h1 className={UserOffersDisplayType === 1 ? 'UserOffersHeaderActive' : 'UserOffersHeader'} onClick={() => switchUserOfferDisplayType(1)}>Aktywne: {UserOffers.length}</h1>
           <h1 className={UserOffersDisplayType === 2 ? 'UserOffersHeaderActive' : 'UserOffersHeader'} onClick={() => switchUserOfferDisplayType(2)}>Oczekujące: {AwaitingOffers.length}</h1>
          <h1 className={UserOffersDisplayType === 4 ? 'UserOffersHeaderActive' : 'UserOffersHeader'} onClick={() => switchUserOfferDisplayType(4)}>Zakończone: {ExpiredOffers.length}</h1>
        </div>
        <div id='MyOffersDisplay'>
          {
            showUserOffers()
          }
        </div>
    </div>
  </div>
  )
}

export default CompanyView;