import React from 'react';
import './CompanyAddressEdit.css';
import selectUserDataToEdit from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const CompanyAddressEdit = ({db, set, PopUpModalRef, authedUser, newTimeStamp, setLocalNr, City, setCity, setStreet, setBuildingNr, setZipCode, Street, BuildingNr, LocalNr, ZipCode}) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);

    const saveCity = (e) => {
        if (e.target.value) {
          setCity(e.target.value)
        } else return
       };

    const saveStreet = (e) => {
        if (e.target.value) {
          setStreet(e.target.value)
        } else return
      };

    const saveBuildingNr = (e) => {
        if (e.target.value) {
          setBuildingNr(e.target.value)
        } else return
      };
    

    const saveLocalNr = (e) => {
        if (e.target.value) {
          setLocalNr(e.target.value)
        } else return
      };

    const saveZipCode = (e) => {
        if (e.target.value) {
          setZipCode(e.target.value)
        } else return
      };


      const saveAdress = () => {

        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDay();
        
        if (LoggedUser) {
          try {
            set(PopUpModalRef(db, `users/${LoggedUser.keyName}/City`), City ? City : (LoggedUser.City || null))
             set(PopUpModalRef(db, `users/${LoggedUser.keyName}/Street`), Street ? Street : (LoggedUser.Street || null ))
              set(PopUpModalRef(db, `users/${LoggedUser.keyName}/BuildingNr`), BuildingNr ? BuildingNr : (LoggedUser.BuildingNr || null))
               set(PopUpModalRef(db, `users/${LoggedUser.keyName}/LocalNr`), LocalNr ? LocalNr : (LoggedUser.LocalNr || null))
                set(PopUpModalRef(db, `users/${LoggedUser.keyName}/ZipCode`), ZipCode ? ZipCode : (LoggedUser.ZipCode || null))
                 .then(() => {
                   setModalVersion(12)
                    setModalInfo({title:'Zmiany zapisane'})
                 })
          } catch (error) {
            setModalVersion(12)
             setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
              set(PopUpModalRef(db, `errors/${year}/${month}/${day}/saveAdress`), error.message)
          }
        } else if (!LoggedUser) {
          setModalVersion(12)
            setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
        }
      };
      

    return (
             <div id='EditUserFields'>
                <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                 <input className='EditInputs' placeholder={LoggedUser.City ? LoggedUser.City : 'Miasto'} onChange={saveCity}></input>
                  <input className='EditInputs' placeholder={LoggedUser.Street ? LoggedUser.Street : 'Ulica'} onChange={saveStreet}></input>
                   <input className='EditInputs' placeholder={LoggedUser.BuildingNr ? LoggedUser.BuildingNr : 'Nr Budynku'} onChange={saveBuildingNr}></input>
                    <input className='EditInputs' placeholder={LoggedUser.LocalNr ? LoggedUser.LocalNr : 'Nr Lokalu'} onChange={saveLocalNr}></input>
                     <input className='EditInputs' placeholder={LoggedUser.ZipCode ? LoggedUser.ZipCode : 'Kod Pocztowy'} onChange={saveZipCode}></input>
                    <div className='CompanyAddressEditBtnsContainer'>
                      <button className='ConfirmBtns' onClick={saveAdress}>OK</button>
                      <button className='ConfirmBtns' onClick={() => selectUserDataToEdit(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
                    </div>
            </div>
    )
}

export default CompanyAddressEdit;
