
import NotLoggedInfo from '../../NotLoggedInfo/NotLoggedInfo';

const MailVerifyInfo = ({authedUser}) => {

    const refreshUser = () => {
        window.location.reload()
      };

    return (
        <div id='VerifyInfoDiv'>
            {!authedUser.currentUser ? <NotLoggedInfo/> :
              <div>
                <h1 className='VerifyInfoText'>Proszę potwierdzić adres e-mail:</h1>
                 <h1 className='VerifyInfoText'>{authedUser.currentUser.email}</h1> 
                  <h1 className='VerifyInfoText'>(Klikając przesłany na wskazany adres link)</h1>
                   <h1 className='VerifyInfoText2'>Jeśli już to zrobiłeś kliknij poniższy przycisk:</h1>
                <div id='ConfirmVeryfication' onClick={refreshUser}>
                  <p>Potwierdź weryfikację</p>
                </div>
              </div>
            }
        </div>
    )
}

export default MailVerifyInfo;
