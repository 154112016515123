import React from 'react';
import './WorkerAplicationConfirmation.css';
import CloseModal from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useDetailedOffer, useShowPopUpModal, useModalVersion, useModalInfo, useLoggedUser, useDetailed } from '../../../../data/GlobalStates';
import { ref as ApplyRef, increment } from "firebase/database";

const WorkerAplicationConfirmation = ({dbRef, authedUser, db, set, get, child, setRefresh}) => {

    const newTimeStamp = Date.now();
//stany globalne
    const [ DetailedOffer, setDetailedOffer ] = useAtom(useDetailedOffer);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser);
    const [ detailed, setDetailed ] = useAtom(useDetailed);


    const saveRegisterApplyStats = async (data) => {
     
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        try {
          await get(child(dbRef, `stats/RegisterUserApplyClick/${year}/${month}/clicks`))
          .then((statValue) => {
            if ( statValue.val() === null) {
              set(ApplyRef(db, `stats/RegisterUserApplyClick/${year}/${month}/clicks`), 1)
            } else if (statValue.val()) {
              set(ApplyRef(db, `stats/RegisterUserApplyClick/${year}/${month}/clicks`), increment(1))
            }
          })
         
        } catch (error) {
          console.log(error)
          set(ApplyRef(db, `errors/${year}/${month}/${day}/saveRegisterApplyStats`), error.message)
        }
      };


    const ApplyToOffer = async () => { // Modal 36 

        let WorkerData = {
          Status: 'dostarczona',
          KeyName: authedUser.currentUser.email.replace(/[.,#$[\]]/g,''),
          AplDate: newTimeStamp,
          Country: LoggedUser.Country,
          City: LoggedUser.City,
          Role: LoggedUser.Role,
          Experience: LoggedUser.Experience,
          PhoneNumber: LoggedUser.PhoneNumber,
          email: LoggedUser.email,
          name: LoggedUser.name,
          offerMail: detailed.email,
          offerTitle: detailed.title,
          offerId: detailed.id,
          Ative: true
       } 
      
        if (authedUser && LoggedUser && detailed) {
      
         try {
          await get(child(dbRef, `offers`))
          .then((offers) => {
            let offersArray = Object.values(offers.val());
            let currentOffer = offersArray.filter(i => i.id === detailed.id);
            let userAplications = [];
      
            if (currentOffer[0].applications) {
              userAplications.push(Object.values(currentOffer[0].applications).filter(ap => ap.email === authedUser.currentUser.email))
            }
      
            if (!currentOffer) {
              setRefresh(Math.floor(Math.random() * 98))
               setShowPopUpModal(true)
                setModalVersion(12)
                 setModalInfo({title:'Nie znaleziono oferty... sprawdź pozostałe na stronie głównej'})
            } else if (currentOffer && userAplications.length === 0) {
              set(ApplyRef(db, `offers/${detailed.id}/applications/${LoggedUser.keyName}`), WorkerData)
              .then(() => {
                setRefresh(Math.floor(Math.random() * 98))
                 setShowPopUpModal(true)
                  setModalVersion(12)
                   setModalInfo({title:'Aplikacja przesłana'})
                    saveRegisterApplyStats()
              })
            } else if (currentOffer && userAplications.length > 0) {
              setRefresh(Math.floor(Math.random() * 98))
               setShowPopUpModal(true)
                setModalVersion(12)
                 setModalInfo({title:'Aplikowałeś już na tą ofertę'})
            }
          })
      
         } catch (error) {
            console.log(error)
            setShowPopUpModal(true)
            setModalVersion(12)
            setModalInfo({title:'Ups nie udało się sprawdzić aktualności oferty, spróbuj odświeżyć stronę'})
         }
        }
      };

    return (
     <div id='WorkerAplicationConfirmationMainContainer'>
       <h4>{ModalInfo.title}</h4>
        <p className='AplicationCofirmationParagrafs'>Szybka aplikacja profilem pracownika</p>
         <p className='AplicationCofirmationParagrafs'>Przed aplikacją upewnij się że twój profil jest aktualny</p>
             <div className='AplicationCofirmationJobDetails'>
               <h4>{detailed.title}</h4>
               <p className='AplicationCofirmationParagrafs'>{detailed.city}</p>
             </div>
             <div>
               <button className='AplyButtons' onClick={() => ApplyToOffer()}>Aplikuj</button> 
               <button className='AplyButtons' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
             </div>
     </div>
    )
}

export default WorkerAplicationConfirmation;
