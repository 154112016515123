import React from 'react';
import './SearchBarNavButtons.css';
//import SalaryDisplayButton from './SalaryDisplayButton/SalaryDisplayButton';
import { Link, useHistory } from 'react-router-dom';
import { FaToolbox } from 'react-icons/fa';
import { FiSliders } from 'react-icons/fi';
import { BsPeople } from 'react-icons/bs';
import { useAtom } from 'jotai';
import { useShowPopUpModal, useModalVersion, useModalInfo, useExperienceFilter, useSalaryFilter, useCurrencyFilter } from '../../../data/GlobalStates';

const SearchBarNavButtons = () => {

    const history = useHistory();

    //stany globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ ExperienceFilter, setExperienceFilter ] = useAtom(useExperienceFilter);
    const [ CurrencyFilter, setCurrencyFilter ] = useAtom(useCurrencyFilter);
    const [ SalaryFilter, setSalaryFilter] = useAtom(useSalaryFilter);
    
    const openFiltersModal = () => {
      setShowPopUpModal(true)
      setModalVersion(33)
      setModalInfo({title: 'Wybierz dane do edycji:'})
    };

    return (
        <div id='TopBar4MainDiv'>
          <div id='MainButtonsContainer'>
              {/* <Link to={'/'} className={history.location.pathname === '/'  ? 'LinkAndIconBoxActive' : 'LinkAndIconBoxInActive2'}> */}
              <Link to={'/'} className={history.location.pathname === '/BazaFirm'  ? 'LinkAndIconBoxInActive2' : 'LinkAndIconBoxActive'}>
                 <p className='LinkTextValue'>Oferty Pracy</p>
                 <FaToolbox className='TopBar4LinkIcon'/> 
              </Link>
              {history.location.pathname === '/BazaFirm' ? 
               '' :
               <div onClick={openFiltersModal} className={SalaryFilter || CurrencyFilter || ExperienceFilter ? 'LinkAndIconBoxActive' : 'LinkAndIconBoxInActive'}>
                 <p className='LinkTextValue'>Filtry</p>
                 <FiSliders className='TopBar4LinkIcon'/>
               </div>
              }
              <Link to={'/BazaFirm'} id='LinkAndIconBoxFirmsBase' className={history.location.pathname === '/BazaFirm' ? 'LinkAndIconBoxActive' : 'LinkAndIconBoxInActive'}>
                  <p className='LinkTextValue'>Baza Firm</p>
                  <BsPeople className='TopBar4LinkIcon'/>
              </Link>
          </div>
        </div>
    )
}

export default SearchBarNavButtons;
