import { useEffect, useState } from 'react';
import './DisplayOfferAplications.css';
import { FiPhone } from 'react-icons/fi';
import { BiMailSend } from 'react-icons/bi';
import { getAuth } from "firebase/auth";
import { IoMdArrowDropdown } from 'react-icons/io';

const AplicationDetails = ({application, set, PopUpModalRef ,db}) => {

  let AplicationDate = new Date(application.AplDate || application.SearchingForJobDate);
  const authedUser = getAuth(); 
  const [ applicationView, setApplicationView] = useState(false)


  const changeApplicationStatus = async () => {

    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDay();

      if (authedUser.currentUser && application.Status == 'dostarczona') {
        try {
          await set(PopUpModalRef(db, `offers/${application.offerId}/applications/${application.KeyName}`), {...application, Status: 'otwarta'})
        } catch (error) {
          console.log(error)
          set(PopUpModalRef(db, `errors/${year}/${month}/${day}/changeApplicationStatus`), error.message)
        }
      }
  };


const switchApplicationView = () => {
  if (applicationView === false) {
    setApplicationView(application.KeyName)
    changeApplicationStatus()
  } else if (applicationView === application.KeyName) {
    setApplicationView(false)
  }
};

const ApplicationViews = () => {
  if (applicationView === application.KeyName) {
    return (
      <div id='AplicationMainContainer' onClick={() => switchApplicationView()}>
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>{application.name}</p>
        <p className='AplicationDetailsText'>{application.Role}</p>
      </div>
      {
        application.WorkerSpeciality ?
        <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Specializacja:</p>
        <p className='AplicationDetailsText'>{application.WorkerSpeciality}</p>
      </div>
      : ''
      }
      {
        application.ExperienceInYears ?
        <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Doświadczenie w latach:</p>
        <p className='AplicationDetailsText'>{application.ExperienceInYears}</p>
      </div>
      : ''
      }
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Kraj:</p>
        <p className='AplicationDetailsText'>{application.Country}</p>
      </div>
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Miasto:</p>
        <p className='AplicationDetailsText'>{application.City}</p>
      </div>
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Doświadczenie:</p>
        <p className='AplicationDetailsText'>{application.Experience}</p>
      </div>
      <div className='AplicationsDetailsSubContainer'>
        <FiPhone/>
        <p className='AplicationDetailsText'>{application.PhoneNumber}</p>
      </div>
      <div className='AplicationsDetailsSubContainer'>
        <BiMailSend />
        <p className='AplicationDetailsText'>{application.email}</p>
      </div>
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>Data przesłania: </p>
        <p className='AplicationDetailsText'>{AplicationDate.toLocaleString('PL')}</p>
      </div>
   </div>
    )
  } else if (applicationView !== application.KeyName) {
    return (
      <div id='AplicationMainContainer' onClick={() => switchApplicationView()}>
      <div className='AplicationsDetailsSubContainer'>
        <p className='AplicationDetailsText'>{application.name}</p>
        <p className='AplicationDetailsText'>{application.Role}</p>
      </div>
     <IoMdArrowDropdown id='ApplicationDropDownIcon'/>
   </div>
    )
  }
};
    return (
      <>
        {ApplicationViews()} 
      </>
    )
}

export default AplicationDetails;
