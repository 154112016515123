import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const CookiesPage = () => {
  return (
    <div id='FooterMainDiv'>
        <Link to='/Polityka' className='FooterLink'>Polityka prywatności</Link>
        <Link to='/Cookies' className='FooterLink'>Polityka cookies</Link>
        <Link to='/Regulamin' className='FooterLink'>Regulamin</Link>
        <Link to='/Kontakt' className='FooterLink'>Kontakt</Link>
        <Link to='/Reklama' className='FooterLink'>Reklama</Link>
    </div>
  )
};

export default CookiesPage;
