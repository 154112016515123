import React from 'react';
import './CompanyPhoneNumberEdit.css';
import selectUserDataToEdit from '../../CommonFunctions/closeModal';
import { useAtom } from 'jotai';
import { useLoggedUser, useShowPopUpModal, useModalVersion, useModalInfo } from '../../../../data/GlobalStates';

const CompanyPhoneNumberEdit = ({setCompanyPhoneInputAlert, CompanyPhoneInput, CompanyPhonePrefix, authedUser, PopUpModalRef, set, db, newTimeStamp, setCompanyPhoneInput, setCompanyPhonePrefix, Prefixes, CompanyPhoneInputAlert }) => {

    const [ LoggedUser, setLoggedUser ] = useAtom(useLoggedUser); 
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
   
    const savePhoneNumber = async () => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

        if (CompanyPhoneInput.length > 2 && LoggedUser) {
          try {
            await set(PopUpModalRef(db, `users/${LoggedUser.keyName}/PhoneNumber`), CompanyPhonePrefix + ' ' + CompanyPhoneInput)
            .then(() => {
               setModalVersion(12)
                setModalInfo({title:'Zmiany zapisane'})
            })
          } catch (error) {
            setModalVersion(12)
              setModalInfo({title:'Ups coś poszło nie tak spróbuj ponownie'})
               set(PopUpModalRef(db, `errors/${year}/${month}/${day}/savePhoneNumber`), error.message)
          }
        } else if (!LoggedUser) {
          setModalVersion(12)
            setModalInfo({title:'Sesja wygasła proszę się zalogować ponownie'})
        }
      };

      const savePhoneVal = (e) => { // zapis w zmiennej przy onChange
        if (!isNaN(e.target.value.replace(/\s/g, ''))) {
          setCompanyPhoneInput(e.target.value)
          setCompanyPhoneInputAlert('')
        } else if (isNaN(e.target.value)) {
          setCompanyPhoneInputAlert(e.target.value)
        }
      };

      const savePrefix = (e) => {
        setCompanyPhonePrefix(e.target.value)
      };


    return (
      <div id='EditUserFields'>
                   <h1 className='ModalTitle'>{ModalInfo.title}</h1>
                     <div>
                       <div id='FakePrefixSelect'>{CompanyPhonePrefix ? <p className='prefixValue'>{CompanyPhonePrefix}</p> : <p className='prefixValue'>+48</p>}</div>
                        <select id='RealSelect' onChange={(e) => savePrefix(e)}>
                                {Prefixes.sort((a, b) => (a.value - b.value)).map( prefix => <option className='SelectOption' key={prefix.country} value={prefix.value}>
                                {prefix.value}
                              </option>)}
                        </select>
                       <input onChange={savePhoneVal} className='EditInputs' placeholder={LoggedUser.PhoneNumber || 'wpisz numer telefonu'}></input>
                     </div>
                       { // Value nie jest liczbą
                          CompanyPhoneInputAlert ?
                          <div id='Hint'>
                            <p>Proszę wpisać cyfry</p>
                          </div> : ''
                       }
                       <div className='CompanyPhoneEditBtnsContainer'>
                         <button className='ConfirmBtns' onClick={savePhoneNumber}>OK</button>
                         <button className='ConfirmBtns' onClick={() => selectUserDataToEdit(setShowPopUpModal, setModalVersion, setModalInfo)}>Anuluj</button>
                       </div>
                 </div>
    )
}

export default CompanyPhoneNumberEdit;


