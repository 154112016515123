import React from 'react';
import { useHistory } from 'react-router-dom';
import './PaymentInfo.css';
import { GiCannonShot } from 'react-icons/gi';
import { BiWrench } from 'react-icons/bi';
import { GiBlacksmith } from 'react-icons/gi';
import { RiTeamLine } from 'react-icons/ri';

const PaymentInfo = ({refresh, setRefresh}) => {

    const history = useHistory();

    const backToUserPanel = () => {
      history.push('userPanel')
      setRefresh(refresh + 1)
    };

    return (
        <div id='PaymentInfoMainDiv'>
            <h1 id='PaymentInfoLogo'>SteelJobs.pl</h1>
             <h2 className='PaymentInfoHeaders'>Dziękujemy za dodanie oferty</h2>
              <h2 className='PaymentInfoHeaders'>Aktywacja odbędzie się po potwierdzeniu płatności</h2>
              <h2 className='PaymentInfoHeaders'>Status oferty sprawdzisz w Panelu użytkownika</h2>
               <div id='PaymentInfoBackButton' onClick={backToUserPanel}>
                   <h2 className='PaymentInfoBtnText'>Panel Użytkownika</h2>
               </div>
               <div className='PaymentInfoIconsContainer'>
                    <BiWrench className='paymentInfoIcons'/>
                            <GiCannonShot className='paymentInfoIcons'/>
                            <GiBlacksmith className='paymentInfoIcons'/>
                    <RiTeamLine className='paymentInfoIcons'/>
               </div>
        </div>
    )
}

export default PaymentInfo;
