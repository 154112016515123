import React, {useEffect, useState} from 'react';
import './WorkerOffersModal.css';
import CloseModal from '../../CommonFunctions/closeModal';
import AplicationDetails from '../DisplayOfferAplications/AplicationDetails';
import { useAtom } from 'jotai';
import { getAuth } from "firebase/auth";
import { increment } from 'firebase/database';
import { useShowPopUpModal, useModalVersion, useModalInfo, useLoggedUser, useMainFilter } from '../../../../data/GlobalStates';
import Roles from '../../../../data/Roles';
import RoleFilterButton from './RoleFilterButton';


const WorkerOffersModal = ({get, child, dbRef, set, db, PopUpModalRef}) => { 

    const authedUser = getAuth(); 
    //stany Globalne:
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);
    const [ ModalVersion, setModalVersion] = useAtom(useModalVersion);
    const [ ModalInfo, setModalInfo] = useAtom(useModalInfo);
    const [ LoggedUser, setLoggedUser] = useAtom(useLoggedUser);
    const [ MainFilter, setMainFilter ] = useAtom(useMainFilter);
    //stany Lokalne:
    const [ AvailableWorkers, setAvailableWorkers] = useState('');
    const [ StatsSaved, setStatsSaved] = useState(false);


    const UpdateAvailableWorkers = async () => { // Modal 36 
      
        if (authedUser.currentUser && LoggedUser) {
         
         let workers = null

         try {
          await get(child(dbRef, `users`))
          .then((users) => {
            workers = Object.values(users.val())
            if (!MainFilter) {
              setAvailableWorkers(workers.filter(worker => worker.AccountType === 'Private' && worker.SearchingForJob === true))
            } else if (MainFilter) {
              setAvailableWorkers(workers.filter(worker => worker.AccountType === 'Private' && worker.SearchingForJob === true && worker.Role === MainFilter))
            }
          })
         } catch (error) {
            console.log(error)
         }
        }
      };

      const saveWorkerModalStats = async () => { 
        const date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        try {
          await get(child(dbRef, `stats/workerOffersModal/${year}/${month}/clicks`))
          .then((statValue) => {
            if ( statValue.val() === null) {
              set(PopUpModalRef(db, `stats/workerOffersModal/${year}/${month}/clicks`), 1)
            } else if (statValue.val()) {
              set(PopUpModalRef(db, `stats/workerOffersModal/${year}/${month}/clicks`), increment(1))
            }
          })
         
        } catch (error) {
          console.log(error)
        }
      };

      useEffect(() => { 
       UpdateAvailableWorkers()
       if (!StatsSaved) {
        saveWorkerModalStats()
        setStatsSaved(true)
       }
      },[MainFilter])


    return (
     <div id='ConfirmationModalMainContainer'>
           <h3 className='ModalMessage'>Profile pracowników szukających aktualnie pracy:</h3>
           <div className='RolesFilterButtonsMainContainer'>
             {Roles.map( role => <RoleFilterButton key={role} role={role}/>)}
           </div>
           <div className='AvailableWorkerContainer'>
             {AvailableWorkers.length > 0 ? 
              AvailableWorkers.map(worker => ( <AplicationDetails key={worker.email} application={worker}/>))
              :
              <div className='NoWorkersInfoContainer'>
                <h3 className='NoWorkersInfo'>brak ofert pracowników</h3> 
                <h3 className='NoWorkersInfo'>w wybranej kategorii</h3> 
              </div>
             }
           </div>
         <div id='ConfirmBtn' onClick={() => CloseModal(setShowPopUpModal, setModalVersion, setModalInfo)}>
           <h3 id='ConfirmBtnText'>Zamknij</h3> 
         </div>
     </div>
    )
};

export default WorkerOffersModal;
