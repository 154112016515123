import React from 'react';
import dataBase from '../../../config';
import './PopularCityButton.css';
import { ref as PopUpModalRef, set, get, child} from "firebase/database";
import { useAtom } from 'jotai';
import { useCityFilter, useShowPopUpModal } from '../../../data/GlobalStates';

const PopularCityButton = ({city}) => {

    const dbRef = PopUpModalRef(dataBase);// Odczyt
    const [ CityFilter, setCityFilter] = useAtom(useCityFilter);
    const [ ShowPopUpModal, setShowPopUpModal] = useAtom(useShowPopUpModal);

    const saveSearchedCityStats = async (name) => {

      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDay();

      try {
        await get(child(dbRef, `popularCities`))
        .then((cities) => {
          if (Object.keys(cities.val()).filter(city => city === name).length === 0) {
            set(PopUpModalRef(dataBase, `popularCities/${name}/searches`), 1)
          } else if (Object.keys(cities.val()).filter(city => city === name).length === 1) {
            get(child(dbRef, `popularCities/${name}/searches`))
            .then((value) => {
              set(PopUpModalRef(dataBase, `popularCities/${name}/searches`), value.val() + 1)
            })
          }
        })
      } catch (error) {
        console.log(error)
        set(PopUpModalRef(dataBase, `errors/${year}/${month}/${day}/saveSearchedCityStats`), error.message)
      }
    };

      const handlePopularButtonClick = (city) => {
        setCityFilter(city.name)
        saveSearchedCityStats(city.name)
        setShowPopUpModal(false)
      };

      const displayCuttedCityName = () => {
        if (city.name.length <= 10) {
          return city.name
        } else if (city.name.length > 10) {
          return city.name.slice(0,10) + '...'
        }
      };


  return (
    <button className='PopularCityButton' onClick={() => handlePopularButtonClick(city)}>{displayCuttedCityName()}</button>
  )
}

export default PopularCityButton;